import React from 'react'
import {observer} from 'mobx-react'

import {
	useStore
} from '../../../hooks/useStore'

import get from 'lodash/get'


const NavViewProjectLink = observer((props) => {
	const {uiStore: {styles}, contentfulStore: {pageData}} = useStore()
	const url = get(pageData, "fields.url", "");
	const isPreview = get(pageData, "fields.isPreview", "");

	if (!url || isPreview) return null
		
	return (
		<a href={url} target="_blank">

			<div className="view-project-link flex--center">

				<span className="text-xs plex-mono">{url.replace(/(^\w+:|^)\/\//, '')}</span>
				<svg width="14px" height="14px" viewBox="0 0 14 14">
				    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				        <g id="space-us-copy-2" transform="translate(-445.000000, -52.000000)" stroke="#808080" strokeWidth="1.7">
				            <g id="Group-Copy-2" transform="translate(315.000000, 43.000000)">
				                <g id="Group-5" transform="translate(131.000000, 10.000000)">
				                    <polyline id="Stroke-1" points="0 0 12 0 12 12"></polyline>
				                    <line x1="12" y1="0" x2="0" y2="12" id="Stroke-3"></line>
				                </g>
				            </g>
				        </g>
				    </g>
				</svg>
				

				<style jsx>{`
			
					.view-project-link {
						height: 26px;
						min-width: 26px;
						background: ${styles.colors.darkGray};
						// border-radius: 50%;
						position: relative;
						margin-right: 1px;
						transition: background .2s ease;
						border-radius: 13px;
						margin-left: 20px;
						padding: 0 8px;
					}

					.view-project-link:hover {
						background: #ddd;
					}


					span {
						color: #666;
						display: inline-block;
						margin-right: 8px;
						position: relative;
						white-space: nowrap;
						// top: -1px;

					}
					svg {
						width: 12px;
						fill: white;
						stroke: white;
					}
				`}</style>
			</div>
		</a>
	)
})

export default NavViewProjectLink