import React, {useEffect} from 'react'
import {observer} from 'mobx-react'

import {
	Nav,
	Footer
} from './components/nav'

import {
	Head
} from './components/head'

import {
	PageTransitionHOC
} from './components/general/transitions'

import {
	useStore
} from './hooks/useStore'

import {
	HomeView, 
	WorkList,
	WorkView,
	AboutView,	
	FourOhFour
} from './pages'

import {
	Carousel
} from './components/_pages/workview/carousel'


const Loading = observer((props) => {
	const {routerStore: {initialLoad, transitionMessage, showLoader, fadeOutLoader}} = useStore();
	if (!showLoader || initialLoad) return null

	return (
		<div className={fadeOutLoader ? "rm sheet" : "sheet"}>
			<div className="message">
				<h5 className="plex-mono text-xs">
					{transitionMessage}
				</h5>
			</div>

			<style jsx>{`

			.sheet {
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				contain: strict;

				z-index: 99;
				pointer-events: none;
				animation: fade-in-up 0.35s cubic-bezier(0.65, 0, 0.35, 1);
				opacity: 0;
				animation-fill-mode: forwards;
				animation-delay: .15s;
				background: white;
			}

			.message {


				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.rm {
				animation: fade-out-up 900ms cubic-bezier(0.65, 0, 0.35, 1);
				opacity: 1;
				animation-fill-mode: forwards;
				animation-delay: .2s;

			}

			h5 {

				animation: blink 1000ms infinite;

			}


			@keyframes blink {
				from { opacity: 1.0; }
				50% { opacity: 0.28; }
				to { opacity: 1.0; }
			}  

			@keyframes fade-in-up {
				0% {
					opacity: 0;
				}


				100% {
					opacity: 1;
				}
			}


			@keyframes fade-out-up {
				0% {
					opacity: 1;
					transform: translate3d(0, 0px, 0);
				}


				100% {
					opacity: 0;
					transform: translate3d(0, -20px, 0);
				}
			}
			`}</style>
		</div>
	)
});

const DynamicStyles = observer((props) => {
	const {uiStore: {styles, interactiveColor, uiColor, uiBandColor, uiSecondaryColor, home__introSequenceS3, home__introSequenceS4, home__introSequenceS5}} = useStore();

	return (
		<style jsx global>{`
			.bg--t1 {
				background: ${uiColor};
				transition: background .6s ease;
				// transition-delay: .2s;
			}

			.bg--t2 {
				background: ${uiSecondaryColor || "rgb(249,249,249);"} !important;
				transition: background .3s ease;
			}

			.bg--t3 {
				transition: background .2s ease-in;
				background: ${uiSecondaryColor || styles.colors.lightGray};
			}

			.hl {
				background: #F9F5A2;
			}

			.highlight .band {
				background: ${uiBandColor || "#F9F5A2"};
			}


			.seq--s1 {
				opacity: ${home__introSequenceS3 ? 1 : 0};
				transition: opacity .2s ease;
			}

			.seq--s2 {
				opacity: ${home__introSequenceS5 ? 1 : 0};
			}


			.user-data {
				${!home__introSequenceS5 ? "background: transparent;" : ""}
				${!home__introSequenceS5 ? "box-shadow: none !important;" : ""}
				transform: ${home__introSequenceS4 ? "translate3d(0px, 0, 0)" : "translate3d(80px, 0, 0)"};
			}

			.user-data .top-label, .user-data .instructions {
				opacity: ${home__introSequenceS5 ? 1 : 0};
			}

			.button {
				background: ${interactiveColor};
				color: white;
				height: 40px;
				border-radius: 20px;
				width: 200px;
				text-align: center;
				cursor: pointer;
				margin: 0 auto;
			}
	

			@media (pointer: coarse) {
				.seq--s1, .seq--s2  {
					opacity: 1;
				}

				
			}
		`}</style>
	)
});

const GlobalStyles = React.memo(() => {

	return (
		<style jsx global>{`

		html, body {
			margin: 0;
		}

		* {
			box-sizing: border-box;
			text-rendering: optimizeSpeed;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		}

		h1,h2,h3,h4,h5,h6,p,li,ul,span,div,a, pre {
			margin: 0;
			padding: 0;
			font-weight: 400;
			color: #303030;
			line-height: 1.4;
		}

		::-webkit-scrollbar {
		  display: none;
		}

		::selection { background: rgba(230,230,230, .8); }


		a {
			text-decoration: none;
			transition: color .5s cubic-bezier(0.33, 1, 0.68, 1);
		}

		a:hover {
			color: rgba(100,100,100, .7);
		}

		mark {
			background: black;
			user-select: none;
		}




		img {
			max-width: 100%;
		}



		p {
			line-height: 1.7;
		}

		p span {
			line-height: inherit;
		}



		.text-xs {
			font-size: 11px;
		}
	
		.text-s {
			font-size: 14px;
		}

		.text-m {
			font-size: 18px;
		}

		.text-l {
			 font-size: 25px;
		}

		.text-xl {
			font-size: 54px;
		}

		.text-xxl {
			font-size: 60px;
		}

		.relative {
			position: relative;
		}

		.flex {
			display: flex;
		}

		.flex--center-y {
			display: flex;
			align-items: center;
		}

		.flex--center {
			display: flex;
			align-items: center;
			justify-content: center;
		}


		.flex--space-between {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.flex--column {
			display: flex;
			flex-direction: column;
		}

		.highlight {
			display: inline-block;
			position: relative;
			overflow: hidden;
			contain: content;
			vertical-align: top;
			padding: 0 4px;
			margin: 0 -4px;
			// z-index: -1;
		}

		.highlight--xl {
			padding: 0 10px;
			margin: 0 -10px;

		}



		.highlight .band {
			position: absolute;
			top: 5%;
			bottom: 5%;
			left: 0px;
			width: 100%;
			// height: 100%;
			z-index: -1;
			transform: translateX(-100%);
			transition: transform 0.7s cubic-bezier(0.87, 0, 0.13, 1), background .4s ease-in;
			transition-delay: .05s;

		}


		.highlight.active .band {
			transform: translateX(0px);
			backface-visibility: hidden;
		}

		.page {
			// padding-bottom: 80px;
			background: #fff;
		}

		.tr-el {

		}

		// @keyframes enter {
		// 	from {
		// 		transform: translateX(100%);
		// 	}
		// 	to {
		// 		transform: translateX(0);
		// 	}
		// }

		@media (pointer: coarse) {
			.text-xs {
				font-size: 11px;
			}
			
			.text-s {
				font-size: 14px;
			}

			.text-m {
				font-size: 18px;
			}

			.text-l {
				 font-size: 24px;
			}

			.text-xl {
				font-size: 28px;
			}

			.text-xxl {
				font-size: 40px;
			}
		}

		.board {
		  display: -ms-grid;
		  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		  -ms-grid-rows: auto;
		  display: grid;
		  grid-template-columns: repeat(8, 1fr);
		  grid-template-rows: auto;
		  height: 500px;
		  width: 500px;
		}

		.board .piece-w-p:after {
			content: "♟";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #eee;
		}
		.board .piece-w-k:after {
			content: "♚";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #eee;
		}
		.board .piece-w-q:after {
			content: "♛";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #eee;
		}
		.board .piece-w-r:after {
			content: "♜";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #eee;
		}
		.board .piece-w-b:after {
			content: "♝";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #eee;
		}
		.board .piece-w-n:after {
			content: "♞";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #eee;
		}

		.board .piece-b-p:after {
			
			content: "♟";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #222;
		}

		.board .piece-b-k:after {
		  content: "♚";
		  display: block;
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%);
			color: #222;
		}

		.board .piece-b-q:after {
			content: "♛";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #222;
		}
		.board .piece-b-r:after {
			content: "♜";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #222;
		}
		.board .piece-b-b:after {
			content: "♝";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #222;
		}

		.board .piece-b-n:after {
			content: "♞";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #222;
		}

		#movement {
			position: relative;
			z-index: 10;
			animation: fade-in .15 ease;
			pointer-events: none;
		}

		body .field {
		  background-color: #000;
		  background-color: #E2CEB8;
		  position: relative;
		  font-size: 3vw;
		  cursor: pointer;
		  transition: background .15s ease;
		  contain: strict;

		}



		.field.moveFrom {
			background: #F4625D !important;
		}

		.field.lastMove {
			background: red !important;
		}

		.field.moveTo:before {
			content: "";
			display: block;
			position: absolute;
			z-index: 10;
			height: 12px;
			width: 12px;
			background: #F4625D;
			border-radius: 50%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			animation: fade-in .15s ease;
			animation-delay: .15s;
			animation-fill-mode: forwards;
			opacity: 0;

		}

		body .field:nth-of-type(-2n+8), body .field:nth-of-type(8) ~ *:nth-of-type(-2n+15), body .field:nth-of-type(16) ~ *:nth-of-type(-2n+24), body .field:nth-of-type(24) ~ *:nth-of-type(-2n+31), body .field:nth-of-type(32) ~ *:nth-of-type(-2n+40), body .field:nth-of-type(40) ~ *:nth-of-type(-2n+47), body .field:nth-of-type(48) ~ *:nth-of-type(-2n+56), body .field:nth-of-type(56) ~ *:nth-of-type(-2n+63) {
		  background-color: #fff;
		  background-color: #6A728E;
		}





		@keyframes fade-in {
			0% {
				opacity: 0;
			}


			100% {
				opacity: 1;
			}
		}

	`}</style>
	)
})

const App = observer((props) => {
	const {isMobile, routerStore: {routeName}, uiStore: {styles, interactiveColor}} = useStore();
	useEffect(() => {
		const el = document.getElementById("n-t");
		el.remove()
	}, []);

	let page;
	switch(routeName) {
		case "home":
			page = <HomeView key="hv"/>
			break;
		case "work":
			page = <WorkList key="wl"/>
			break;
		case "work.view":
			page = isMobile ? <Carousel/> : <WorkView key="wv"/>;
			break;
		case "about":
			page = <AboutView key="av"/>
			break;
		default:
			page = <FourOhFour/>
			break;
	}


	return (
		<div>
			<DynamicStyles/>
			<GlobalStyles/>
			<Head/>
			<Nav/>
			<Loading/>

			<PageTransitionHOC>
				{page}
			</PageTransitionHOC>

			<Footer/>


		</div>
	)
})

export default App