import { action, computed, observable, extendObservable } from 'mobx';
// import get from 'lodash/get'

import {
	writeToLocalStorage,
	readFromLocalStorage,
	postData
} from '../utils'

import {
	names
} from '../utils/names.js'

import {
	characterImageUrls
} from '../utils/character-image-urls.js'

var tinycolor = require("tinycolor2");


function addImageProcess(src){
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.onload = () => resolve()
    img.onerror = (e) => {
    	console.log(e);
    	reject()
    }
    img.src = src
  })
}


function randomTime (min, max)  {
	return Math.random() * (max - min) + min;
}

class FirebaseStore {


	constructor(rootStore) {

		this.rootStore = rootStore;
		this.firebase = null
		this.db = null;
		this.init = false;
		this.userPresence = null;


		extendObservable(this, {
			pollSubmitted: readFromLocalStorage("school__pollSubmittedv2"),
			activePollOption: readFromLocalStorage("school__activePollOption"),
			// userData: {
			// 	name: "",
			// 	avatar: {

			// 	}
			// }
			userData: readFromLocalStorage("school__userDatav2")
		});

		// this.createUserData();
	}





	@observable ready = false;
	@observable val__lastClicked = null;
	// @observable val__lastDrawingReset = null;
	@observable val__plantState = null;
	// @observable val__lastWatered = null;
	@observable val__poll = {};
	@observable val__pollChoices = [];
	@observable val__pollTitle = null;

	// @observable val__chessboard = null;
	@observable val__chessboardV2 = null;
	chessboard__possibleMoves = {};

	@observable chessboard__moveTracker = {};
	@observable chessboard__isCheckmate = false;
	@observable chessboard__isFinished = false;
	@observable chessboard__updatedBy = null;
	@observable chessboard__turn = null;
	@observable chessboard__lastMove = null;

	// @observable val__chessMovePossibilities = null;
	// @observable val__audio = null;

	@observable val__adminPassword = "";

	@observable val__numUsers = null;
	@observable val__liveUsers = [];






	async startIntroSequenceS1() {
		const randomUsers = [];
		for (var i = 0; i < 6; i ++) {
			const userData = {};
			userData.avatar = {
				b: Math.floor(Math.random() * 2) + 1,
				e: Math.floor(Math.random() * 3) + 1,
				ha: Math.floor(Math.random() * 4) + 1,
				he: Math.floor(Math.random() * 4) + 1,
				color: Math.floor(Math.random() * 8) 
			}
			randomUsers.push(userData);
		}

		const imagePromises = randomUsers.map((userData) => {
			const {b, e, ha, he} = userData.avatar;
			const imageSrc = characterImageUrls[`b${b}_e${e}_ha${ha}_he${he}`]
			if (!imageSrc) {
				console.error(`no image src: b${b}_e${e}_ha${ha}_he${he}`)
			}
			return addImageProcess(imageSrc + "?w=400&fm=webp&q=95")
		})

		await Promise.all(imagePromises);

		setTimeout(this.shuffleUserImages.bind(this, randomUsers, 0), 800)
	}

	@action.bound shuffleUserImages(allUserData, idx) {
		if (idx > allUserData.length - 1) {
			this.startIntroSequenceS2()
			return
		}

		const userData = allUserData[idx];
		this.userData = userData;

		setTimeout(this.shuffleUserImages.bind(this, allUserData, idx + 1), 150);
	}

	startIntroSequenceS2() {
		const name = names[Math.floor(Math.random() * names.length)];
		this.typeUserName(name, 0);
	}

	@action.bound typeUserName(name, idx) {
		if (idx > name.length) {
			this.startIntroSequenceS3();
			this.saveUserData();
			return
		}

		const substr = name.substr(0, idx + 1);

		this.userData.name = substr;
		setTimeout(this.typeUserName.bind(this, name, idx + 1), randomTime(80, 110))
	}


	startIntroSequenceS3() {
		this.listenToUserPresence();
		this.rootStore.uiStore.activateHomeIntroStep3(true);
	}


	createUserData() {
		if (this.userData) return
		const userData = {};
		userData.avatar = {
			b: Math.floor(Math.random() * 2) + 1,
			e: Math.floor(Math.random() * 3) + 1,
			ha: Math.floor(Math.random() * 4) + 1,
			he: Math.floor(Math.random() * 4) + 1,
			color: Math.floor(Math.random() * 8)
		}

		// const resp = await postData("https://us-central1-school-portfolio-v2.cloudfunctions.net/generateName", {})
		userData.name = names[Math.floor(Math.random() * names.length)];

		this.userData = userData;
		this.saveUserData()
	}

	@computed get numExtraUsers() {
		let extraNum = this.val__numUsers;
		if (extraNum > 5 ) {
			extraNum -= 5;
		} else {
			extraNum = 0;
		}

		return extraNum
	}


	@action.bound saveUserData() {
		if (this.userTimeout) {
			clearTimeout(this.userTimeout)
		}

		let that = this;

		this.userTimeout = setTimeout(() => {
			writeToLocalStorage("school__userDatav2", that.userData);
			that.userTimeout = null;
		}, 2000)
	}

	@action.bound updateUserName(e) {
		e.preventDefault()
		const name = e.target.value;
		if (!name.length || name.length > 20) return

		this.userData.name = e.target.value.trim();
		this.saveUserData()
	}


	@action.bound updateUserAvatar(key, val) {
		this.userData.avatar[key] = val;
		this.saveUserData()
	}

	@action.bound cycleUserAvatarColor() {
		let color = this.userData.avatar.color + 1;
		
		if (color > 7) {
			color = 0
		}
		this.updateUserAvatar("color", color)
	}

	startInit() {
		this.init = true;
	}

	@action.bound setDatabase(firebase, db) {
		this.firebase = firebase;
		this.db = db;
		this.ready = true;
	}

	initializeListeners() {
		if (this.rootStore.isMobile) {
			this.createUserData();
			this.listenToUserPresence();
			this.listenToDrawingPixel(this.rootStore.isMobile);
			return
		}

		if (!this.userData) {
			this.startIntroSequenceS1();
		} else {
			this.startIntroSequenceS3();
		}


		this.listenToLastClicked();
		this.listenToLastWatered();
		this.listenToPoll()
		this.listenToDrawingPixel();
		this.listenToChess();
	}

	@action.bound unbindListeners() {
		this.unbindChess();
		this.unbindDrawingPixel(this.rootStore.isMobile);
		this.unbindLastClicked();
		this.unbindLastWatered();
		this.unbindPoll();
	}


	@computed get lastClickedTime() {
		const {currentTime} = this.rootStore.uiStore;
		if (!currentTime || !this.val__lastClicked) return ""

		var startDate = new Date(this.val__lastClicked.val);
		var endDate   = currentTime;
		const distance = endDate.getTime() - startDate.getTime();

		var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		var seconds = Math.floor((distance % (1000 * 60)) / 1000);

		if (seconds < 0 ) {
			return "00:00:00"
		} else {
			return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
		}
	}



	// USER PRESENCE

	@action.bound listenToUserPresence() {
		if (this.userPresence) return

		this.userPresence = new UserPresence(this.db);
		this.userPresence.join(this.userData);


		// Attach a callback function to track updates
		// That function will be called (with the user count and array of users) every time user list updated
		this.userPresence.onUpdated(this.updateUserCount);
	}

	@action.bound updateUserCount(count, users) {
		const prevCount = this.val__numUsers;
		this.val__numUsers = count;
		const keys = Object.keys(users);
		// console.log(test);
		
		// if (prevCount > 5 && count > 5) return

		// var key, i = 0;
		// for (key in users) {
		// 	i++
		// 	if (i > 5) {
		// 		break
		// 	}


		// console.log(liveUsers)
		// 	liveUsers[i - 1] = users[key];
		// }


		const liveUsers = [];
		let length = keys.length;
		for (var i = 0; i < length; i++) {
			if (i > 4) {
				break
			}

			const key = keys[length - 1 - i];
			liveUsers[i] = users[key];
		}
			
		this.val__liveUsers = liveUsers
	}






	// LAST CLICKED BUTTON




	@action.bound setLastClicked() {
		this.db.ref('lastClickedButton').set({
			lastClicked: this.firebase.database.ServerValue.TIMESTAMP,
			updatedBy: this.userData
		})
		.catch(e => {
			console.error(e)
		})
	}

	listenToLastClicked() {
		const lastClickedRef = this.db.ref('lastClickedButton');
		let that = this;

		lastClickedRef.on('value', function(snapshot) {
			const val = snapshot.val();
			if (!val) return

			that.updateLastClicked(val.lastClicked, val.updatedBy);
		})
	}

	unbindLastClicked() {
		const lastClickedRef = this.db.ref('lastClickedButton');

		lastClickedRef.off();
	}

	@action.bound updateLastClicked(val, updatedBy) {
		this.val__lastClicked = {val, updatedBy};
	}


	// STUDIO PLANT

	@action.bound setLastWatered() {
		const userData = this.userData;

		const plantRef = this.db.ref('studioPlant')

		plantRef.child("water_val").transaction(function(val) {
		  if (!val) {
		  	val = 0;
		  }
		  if (val < 5) {
		    val = val + 1;
		  } 

		  return val
		}).catch(e => {
			console.error(e)
		})

		plantRef.child("updatedBy").set(userData).catch(e => {
			console.error(e)
		})
	}

	listenToLastWatered() {
		const plantRef = this.db.ref('studioPlant');
		let that = this;

		plantRef.on('value', function(snapshot) {
			const val = snapshot.val();
			if (!val) return

			that.updatePlantState(val.water_val, val.updatedBy);
		})
	}

	unbindLastWatered() {
		const plantRef = this.db.ref('studioPlant');

		plantRef.off();
	}

	@action.bound updatePlantState(val, updatedBy) {
		this.val__plantState = {
			val: 6 - val,
			updatedBy
		}
	}



	@action.bound submitPollVote() {
		const voteIdx = this.activePollOption;

		if (voteIdx === null) {
			return
		}

		var ref = this.db.ref(`poll/clicks/${voteIdx}`);
		ref.transaction(function(currentClicks) {
		  // If node/clicks has never been set, currentRank will be `null`.
		  return (currentClicks || 0) + 1;
		}).then(this.setPollSubmitted).catch(e => {
			console.error(e)
		})
	}

	@action.bound setPollSubmitted() {
		writeToLocalStorage("school__pollSubmittedv2", true);
		writeToLocalStorage("school__activePollOption", this.activePollOption);


		this.pollSubmitted = true;
	}

	@action.bound updatePollOption(val) {
		this.activePollOption = val;
	}



	listenToPoll() {
		const pollRef = this.db.ref('poll/clicks');
		let that = this;

		pollRef.on('value', function(snapshot) {
			const val = snapshot.val();
			if (!val) return

			that.updatePoll(val);
		});

		this.db.ref('/poll/options').once('value').then(function(snapshot) {
			const val = snapshot.val();
			if (!val) return

			that.updatePollChoices(val)
		}).catch(e => {
			console.error(e)
		})


		this.db.ref('/poll/question').once('value').then(function(snapshot) {
			const val = snapshot.val();
			if (!val) return

			that.updatePollTitle(val)

		}).catch(e => {
			console.error(e)
		})
	}

	unbindPoll() {
		const pollRef = this.db.ref('poll/clicks');

		pollRef.off();
	}

	@action.bound updatePoll(val) {
		this.val__poll = val
	}

	@action.bound updatePollChoices(val) {
		this.val__pollChoices = val;
	}


	@action.bound updatePollTitle(val) {
		this.val__pollTitle = val;
	}


	registerCanvas(canvasComponent) {
		this.canvasComponent = canvasComponent;
	}

	@action.bound listenToDrawingPixel(isMobile) {
		let that = this;

		const ref = isMobile ? "pixelDrawingMobile" : "pixelDrawing";
		const interval = isMobile ? 15 : 10;
		const numRows = 40;
		// const numCols = 40;

		new Array(numRows).fill(0).forEach((rowNum, idx) => {
			const x = idx * interval;
			this.db.ref(`${ref}/${x}`).on('child_added', function(snapshot){
				const y = snapshot.key;
				const color = snapshot.val(); 
				that.canvasComponent.fill(x,parseInt(y), color)
			})

			this.db.ref(`${ref}/${x}`).on('child_changed', function(snapshot){
				const y = snapshot.key;
				const color = snapshot.val(); 

				that.canvasComponent.fill(x,parseInt(y), color)
			})
		})
	}

	unbindDrawingPixel(isMobile) {
		const ref = isMobile ? "pixelDrawingMobile" : "pixelDrawing";
		const interval = isMobile ? 15 : 10;
		const numRows = 40;
		// const numCols = 40;

		new Array(numRows).fill(0).forEach((rowNum, idx) => {
			const x = idx * interval;
			this.db.ref(`${ref}/${x}`).off()
			this.db.ref(`${ref}/${x}`).off()
		})
	}




	addDrawingPoint(x,y,color, opacity, isMobile) {
		const ref = isMobile ? `pixelDrawingMobile/${x}/${y}` : `pixelDrawing/${x}/${y}`;
		const mixVal = opacity * 100;

		this.db.ref(ref).transaction(function(val) {
			if (!val) {
				val = tinycolor.mix("rgb(249,249,249,1)", color, mixVal).toString();
			} else {
				val = tinycolor.mix(val, color, mixVal).toString();
			}
			return val
		}).catch(e => {
			console.error(e)
		})
	};


	// CHESS


	listenToChess() {
		let that = this;
		this.db.ref('/chess').on('value', function(data){
			const val = data.val();
			if (!val) return
			that.setChessBoard(val);
		})
	}

	unbindChess() {
		this.db.ref('/chess').off()
	}


	@action.bound setChessBoard(val) {
		const {board, possibleMoves, turn, isCheckmate, isFinished, lastMove, updatedBy} = val;

		const boardFilled = [];
		for (var i = 0; i < 8; i++) {
			const row = board[i] || [null,null,null,null,null,null,null,null];
			boardFilled[i] = row;
		};

		this.val__chessboardV2 = boardFilled;
		this.chessboard__moveTracker = {};
		this.chessboard__turn = turn;
		this.chessboard__possibleMoves = possibleMoves || {};
		this.chessboard__isFinished = isFinished; 
		this.chessboard__isCheckmate = isCheckmate;
		this.chessboard__lastMove = lastMove;
		this.chessboard__updatedBy = updatedBy
	}

	@computed get chessBoard() {
		if (!this.val__chessboardV2) return null

		return window.toJS(this.val__chessboardV2)
	}


	@action.bound handleFieldClick(field) {
		if (this.chessboard__turn === "b") return

		if (this.chessboard__moveTracker.from && this.chessboard__possibleMoves[this.chessboard__moveTracker.from][field]) {
			this.performMove(this.chessboard__moveTracker.from, field);

		} else if (this.chessboard__possibleMoves[field]) {
			this.chessboard__moveTracker.from = field;

		} else {
			// can't perform move, click off...
			this.chessboard__moveTracker.from = null;
		}
    }


    @action.bound updateAdminPassword(e) {
    	e.preventDefault()
    	const val = e.target.value;

    	this.val__adminPassword = val;
    }


    @action.bound submitAdminPassword() {
    	const password = this.val__adminPassword;
    	if (!password) return;
    	const temp = {
    		"Nicky": null,
    		"Andrew": null,
    		"Yuting": null,
    		"David": null
    	}
    	if (password in temp) {
    		this.userData.name = "Admin";
    		this.userData.avatar = {
    			he: 2,
    			ha: 2,
    			e: 2,
    			b: 2
    		}
    	};
    }

    resetChess() {
    	// postData('http://localhost:5000/school-portfolio-v2/us-central1/resetChessGame', {})
    	postData('//us-central1-school-portfolio-v2.cloudfunctions.net/resetChessGame', {})
    }

    @action.bound performMove (from, to) {
    	// const {move, status, aiMove} = jsChess;

    	// return
    	// postData('http://localhost:5000/school-portfolio-v2/us-central1/performChessMove', {

    	postData('//us-central1-school-portfolio-v2.cloudfunctions.net/performChessMove', {
    		from,
    		to,
    		updatedBy: this.userData
    	})
    	// postData('http://localhost:5000/school-portfolio-v2/us-central1/performChessMove', {from, to, updatedBy: this.userData})
    	//   .then(data => {
    	//     // console.log(data); // JSON data parsed by `data.json()` call
    	//   })
    	  .catch((e) => {
    	  	console.log(e)
    	  })

    	return

    }
}



var UserPresence = (function() {

    var randomName = function () {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
    };

    function UserPresence(databaseReference, roomName) {

        this.db = databaseReference;
        this.roomName = roomName || 'all';

        this.room = this.db.ref("UserPresence/" + encodeURIComponent(this.roomName));
        // this.myName = '';
        this.user = null;

        this.join = function(userData) {
            if(this.user) {
                console.error('Already joined.');
                return false;
            }


            this.userData = userData || {name: randomName(), avatar: {eyes: 1, nose: 1, hair: 1, body: 1}};
            this.user = this.room.push();

            // Add user to presence list when online.
            var self = this;
            var presenceRef = this.db.ref(".info/connected");

            presenceRef.on("value", function(snap) {
                if (snap.val()) {
                    self.user.onDisconnect().remove();
                    self.user.set(self.userData);
                }
            })

            return this.userData;
        };


        this.onUpdated = function (callback) {
            if('function' == typeof callback) {
                this.room.on("value", function(snap) {
                    callback(snap.numChildren(), snap.val());
                })
            } else {
                console.error('You have to pass a callback function to onUpdated(). That function will be called (with user count and hash of users as param) every time the user list changed.');
            }
        };
    }

    return UserPresence;
})();

export default FirebaseStore