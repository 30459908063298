import React from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../../../../hooks/useStore'

import {
	GridItem,
	GridItemTopLabel
} from '../griditem'


function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}

class WidgetColorPicker extends React.Component {

	componentDidMount() {
		// setTimeout(() => {
			this._generateColorWheel(800, "#666");
		// }, 1000)
		// requestAnimationFrame(() => {
		// 	this._generateColorWheel(800, "#666")
		// })
	}

	_generateColorWheel(size, centerColor) {
	    if (size === void 0) { size = 800; }
	    if (centerColor === void 0) { centerColor = "white"; }
	    const rect = this.container.getBoundingClientRect();

	    var canvas = this.canvas;
	    this.width = rect.width;
	    size = rect.width * 2;
	    this.props.uiStore.setUIColorLoc(rect.width / 2, rect.height /2);
	    canvas.width = canvas.height = size;

	    const offscreen = canvas.transferControlToOffscreen ? canvas.transferControlToOffscreen() : canvas;
	    var ctx = offscreen.getContext("2d", {alpha: true});

	    this.ctx = ctx;
	    // this.ctx.fillStyle = "#f9f9f9";
	    // this.ctx.fillRect(0, 0, size, size)

	    //Generate canvas clone to draw increments on
	    var canvasClone = document.createElement("canvas");
	    canvasClone.width = canvasClone.height = size;
	    var canvasCloneCtx = canvasClone.getContext("2d");

	    //modifying with new offscreen ? maybe faster?
	    // var canvasCloneCtx = ctx
	    
	    //Initiate variables
	    var angle = 0;
	    var hexCode = [255, 0, 0];
	    var pivotPointer = 0;
	    var colorOffsetByDegree = 4.322;
	    // var colorOffsetByDegree = 5;
	    //For each degree in circle, perform operation
	    while (angle++ < 360) {
	        //find index immediately before and after our pivot
	        var pivotPointerbefore = (pivotPointer + 3 - 1) % 3;
	        // var pivotPointerAfter = (pivotPointer + 3 + 1) % 3;
	        //Modify colors
	        if (hexCode[pivotPointer] < 255) {
	            //If main points isn't full, add to main pointer
	            hexCode[pivotPointer] = (hexCode[pivotPointer] + colorOffsetByDegree > 255 ? 255 : hexCode[pivotPointer] + colorOffsetByDegree);
	        }
	        else if (hexCode[pivotPointerbefore] > 0) {
	            //If color before main isn't zero, subtract
	            hexCode[pivotPointerbefore] = (hexCode[pivotPointerbefore] > colorOffsetByDegree ? hexCode[pivotPointerbefore] - colorOffsetByDegree : 0);
	        }
	        else if (hexCode[pivotPointer] >= 255) {
	            //If main color is full, move pivot
	            hexCode[pivotPointer] = 255;
	            pivotPointer = (pivotPointer + 1) % 3;
	        }
	        //clear clone
	        canvasCloneCtx.clearRect(0, 0, size, size);
	        //Generate gradient and set as fillstyle
	        var grad = canvasCloneCtx.createRadialGradient(size / 2, size / 2, 0, size / 2, size / 2, size / 2);
	        grad.addColorStop(0, centerColor);
	        grad.addColorStop(1, "rgb(" + hexCode.map(function (h) { return Math.floor(h); }).join(",") + ")");
	        canvasCloneCtx.fillStyle = grad;
	        //draw full circle with new gradient
	        canvasCloneCtx.globalCompositeOperation = "source-over";
	        canvasCloneCtx.beginPath();
	        canvasCloneCtx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
	        canvasCloneCtx.closePath();
	        canvasCloneCtx.fill();
	        //Switch to "Erase mode"
	        canvasCloneCtx.globalCompositeOperation = "destination-out";
	        //Carve out the piece of the circle we need for this angle
	        canvasCloneCtx.beginPath();
	        canvasCloneCtx.arc(size / 2, size / 2, 0, degreesToRadians(angle + 1), degreesToRadians(angle + 1));
	        canvasCloneCtx.arc(size / 2, size / 2, size / 2 + 1, degreesToRadians(angle + 1), degreesToRadians(angle + 1));
	        canvasCloneCtx.arc(size / 2, size / 2, size / 2 + 1, degreesToRadians(angle + 1), degreesToRadians(angle - 1));
	        canvasCloneCtx.arc(size / 2, size / 2, 0, degreesToRadians(angle + 1), degreesToRadians(angle - 1));
	        canvasCloneCtx.closePath();
	        canvasCloneCtx.fill();
	        //Draw carved-put piece on main canvas
	        ctx.drawImage(canvasClone, 0, 0);
	    }
	    //return main canvas
		// this.setState({
		// 	ready: true
		// })
	    return canvas;
	}


	_handleMouseDown(evt) {
		    var ctx = this.ctx;
		    var data = ctx.getImageData(evt.offsetX * 2, evt.offsetY * 2, 1, 1);
		    const color = data.data.slice(0, 3);

		    if (color[0] === 0 && color[1] === 0 && color[2] === 0) return

		    this.props.uiStore.setUIColor(color);
		    this.props.uiStore.setUIColorLoc(evt.offsetX, evt.offsetY)
	}

	setAltColorLoc = (x,y) => {
		var ctx = this.ctx;

		x  = this.width * (x / 100);
		y = this.width * (y / 100)
		// console.log(this.width, this.width * (x / 100), y / 100)
		var data = ctx.getImageData(x * 2, y * 2, 1, 1);
		const color = data.data.slice(0, 3)
		// console.log("this", color)
		if (color[0] === 249 && color[1] === 249 && color[2] === 249) return

		this.props.uiStore.setUIColor(color);
		this.props.uiStore.setUIColorLoc(x, y)

	}

	setWhite = () => {
		const rect = this.canvas.getBoundingClientRect();
		const {width, height} = rect;

		this.props.uiStore.setUIColorLoc(width / 2, height /2);
		this.props.uiStore.resetUIColor();
	}

	render() {


		return (
			<GridItem {...this.props} fullHeight>
				<GridItemTopLabel>(Y)our interface color.</GridItemTopLabel>
				<div className="container" >
					<div className="container__inner" ref={el => this.container = el}>
					<canvas onMouseDown={this._handleMouseDown.bind(this)} ref={canvas => this.canvas = canvas}/>
					<ColorPickingWhite onClick={this.setWhite} />
					<ColorPickingAltLoc x={55} y={57.173} color={"rgb(129, 126, 84)"} onClick={this.setAltColorLoc}/>
					<ColorPickingAltLoc x={89.130} y={58.043} color={"rgb(224, 58, 20)"} onClick={this.setAltColorLoc}/>
					<ColorPickingAltLoc x={65} y={82.826} color={"rgb(196, 212, 28)"} onClick={this.setAltColorLoc}/>
					<ColorPickingAltLoc x={6.304} y={30.652} color={"rgb(4, 156, 248)"} onClick={this.setAltColorLoc}/>

					<ColorPickerLoc/>

					</div>

					<style jsx>{`

						canvas {
							display: block;
							cursor: crosshair;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							border-radius: 50%;
						}

						.container__inner {
							position: absolute;
							top: 30px;
							left: 30px;
							width: calc(100% - 60px);
							height: calc(100% - 60px);
						}

						.container {
							width: 100%;
							height: 0;
							padding-bottom: 100%;
							position: relative;
							box-sizing: initial;
						}

						@media (pointer: coarse) {

							.container {
								padding-bottom: 0;
								height: 100%;
							}
							.container__inner {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%) translateY(-20px);
								width: 200px;
								height: 200px;
							}
						}
					`}</style>
				</div>
			</GridItem>
		)
	}
}
const ColorPickingWhite = (props) => {
	return (
		<div onClick={() => props.onClick()}>
			<style  jsx>{`
				div {
					position: absolute;
					top: 50%;
					left: 50%;
					border: 1px solid white;
					height: 8px;
					width: 8px;
					border-radius: 50%;
					background: white;
					transform: translate(-50%, -50%);
					box-shadow: 0 3px 6px rgba(0,0,0,.16);
					cursor: pointer;
					transition: transform .2s ease;

				}
				div:hover {
					transform: translate(-50%, -50%) scale(1.5);
					transform-origin: center center;
				}
			`}</style>
		</div>
	)

}
const ColorPickingAltLoc = (props) => {
	const x = props.x;
	const y = props.y;
	const color = props.color;

	return (
		<div onClick={() => props.onClick(x,y, props.white)}>
			<style  jsx>{`
				div {
					position: absolute;
					top: ${y}%;
					left: ${x}%;
					border: 1px solid white;
					height: 8px;
					width: 8px;
					border-radius: 50%;
					background: ${color};
					transform: translate(-50%, -50%);
					box-shadow: 0 3px 6px rgba(0,0,0,.16);
					cursor: pointer;
					transition: transform .2s ease;

				}
				div:hover {
					transform: translate(-50%, -50%) scale(1.5);
					transform-origin: center center;
				}
			`}</style>
		</div>
	)
}


const ColorPickerLoc = observer((props) => {
	const {uiStore: {uiColor, uiColorLoc}} = useStore();
	if (!uiColorLoc) return null;

	return (
		<div>
			

			<style jsx>{`
				div {
					position: absolute;
					top: ${uiColorLoc.y}px;
					left: ${uiColorLoc.x}px;
					border: 2px solid white;
					height: 34px;
					width: 34px;
					border-radius: 50%;
					background: ${uiColor};
					transform: translate(-50%, -50%) scale(.5);
					box-shadow: 0 3px 6px rgba(0,0,0,.16);
					pointer-events: none;
					z-index: 10;
					animation: scaleIn .25s ease;
					animation-fill-mode: forwards;
					transiton-delay: .03s;
				}

				@keyframes scaleIn {
					from {
						transform: translate(-50%, -50%) rotateZ(0) scale(.5);
					} 

					to {
						transform: translate(-50%, -50%) rotateZ(0) scale(1);
					}
				}
			`}</style>
		</div>
	)
})

export default WidgetColorPicker