import React from 'react'

const GridItemBottomLabel = (props) => {
	return (
		<div>
			{props.children}
			<style jsx>{`
				padding: 8px;
			`}</style>
		</div>
	)
}


export default GridItemBottomLabel