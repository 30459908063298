import React from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../../../../hooks/useStore'

const WidgetAdminPassword = observer((props) => {

	const {firebaseStore: {submitAdminPassword, val__adminPassword, updateAdminPassword}} = useStore()
	return (
		<div>
			<input onChange={updateAdminPassword} value={val__adminPassword} type="text"/>
			<button onClick={submitAdminPassword}>Submit</button>

			<style jsx>{`
				div {
					padding: 400px;
				}
			`}</style>
		</div>
	)
})


export default WidgetAdminPassword