import React from 'react'
import {observer} from 'mobx-react'
import { useInView } from 'react-intersection-observer'

import {
	useStore
} from '../hooks/useStore'

import {
	WorkViewSectionTags
} from '../components/_pages/workview'

import {
	BlockWrapper
} from '../components/_pages/workview/blocks'

import {
	OtherProjects
} from '../components/_pages/workview/otherprojects'


import get from 'lodash/get'
import { Tag } from '../components/tag'
import FourOhFour from './FourOhFour'

const WorkView = observer((props) => {
	const {contentfulStore: {pageData}, uiStore: {windowHeight}} = useStore();
	if (!pageData) return <FourOhFour/>
	if (pageData.fields.isPreview) return null;
	
	const content = get(pageData, "fields.contentBlocks", []);
	const prefix = get(pageData, "fields.prefix", "");
	const asset = get(pageData, "fields.workViewMedia", "");
	const tagline = get(pageData, "fields.tagline.content[0].content", "");

	return (
		<div className="page">
			<BGAsset asset={asset}/>
			<div className="sheet bg--t1">
				<Tagline tagline={tagline}/>

				{content.map((section, idx) => {
					return <BlockWrapper key={`bw-${section.sys.id}`} data={section} idx={idx < 10 ? "0" + idx : idx} prefix={prefix}/> 
				})}
				<Collaborators/>
				<OtherProjects/>
			</div>
			<WorkViewSectionTags/>
			<style jsx>{`
				.page :global(> video), .page :global(> img) {
					position: fixed;
					top: 0;
					z-index: -1;
					height: ${windowHeight - 115}px;
					width: 100%;
					object-fit: cover;
				}

				.sheet {
					margin-top: ${windowHeight - 115}px;
					line-height: 0;
					background: #f8f8f8;
					backface-visibility: hidden;
				}

				@media (pointer: coarse) {
					video {
						position: static;
						height: auto;

					}

					.sheet {
						margin-top: 0;
					}
				}
			`}</style>

		</div>
	)	
})

const BGAsset = React.memo((props) => {
	const type = get(props.asset, "fields.file.contentType", "");
	if (type === "video/mp4") {
		return <video src={props.asset.fields.file.url} muted autoPlay loop playsInline></video>
	} else {
		return <img src={`${props.asset.fields.file.url}?w=1600&fm=webp&q=90`} alt=""/>
	}
})

const Tagline = React.memo((props) => {
	const [ref, inView, entry] = useInView({
	  threshold: .66,
	  triggerOnce: true
	})

	const {tagline} = props;	
	const text = [];

	tagline.forEach((node, idx) => {
		if (!node.marks[0]) {
			text.push(<span key={`${node.value}-${idx}`}>{node.value}</span>)
		} else {
			text.push(<span  key={`${node.value}-${idx}`} className={`highlight ${inView ? "active" : ""}`}><span key={`${node.value}-${idx}`}>{node.value}</span> <span className="band"></span> <style jsx>{`.active .band {transition-delay: .5s;}`}</style></span>)
		}
	})

	return (
		<div ref={ref}>
			<h6 className="tagline text-l helvetica">{text}</h6>


			<style jsx>{`
				div {
					text-align: center;
					// position: sticky;
					z-index: 1;
					top: 0px;
					// background: white;
				}
				.tagline {
					padding: 40px 0;
				}
				.client {
					// margin-top: 10px;
				}
			`}</style>
		</div>
	)
});

const Collaborators = observer((props) => {
	const {contentfulStore: {pageData}} = useStore();
	const collaborators = pageData.fields.collaborators;
	if (!collaborators) return null

	return (
		<div className="collaborators">
			<h3 className="text-m helvetica label">Collaborators:</h3>
			{collaborators.map((collaborator) => {
				return (
					<div className="flex--center-y collaborator">
						<h6 className="text-s key">{collaborator.fields.role}: </h6>
						<Tag>{collaborator.fields.name}</Tag>
					</div>
				)
			})}

			<style jsx>{`
				.collaborators {
					padding: 0 73px;
					padding-bottom: 100px;
				}

				.label {
					margin-bottom: 40px;
				}

				.collaborator {
					margin: 6px 0;
				}

				.key {
					margin-right: 12px;
					color: #909090;
				}


			`}</style>
		</div>
	)
})




export default WorkView