import React from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../../../../hooks/useStore'

import {
	GridItem,
	GridItemTopLabel,
	GridItemBottomLabel
} from '../griditem'

import {
	assetImageUrls
} from '../../../../utils/asset-image-urls.js'

const WidgetWeather = observer((props) => {
	const {uiStore: {weather}} = useStore();
	if (!weather) return null

	return (
		<GridItem {...props}>
			<GridItemTopLabel>(Y)our weather.</GridItemTopLabel>

			<WeatherImage temp={weather.temp} conditions={weather.conditions}/>

			<GridItemBottomLabel>
				<div className="flex--center">
					<h5 className="text-xs">{weather.temp}°F & {weather.conditions} (NYC)</h5>
				</div>
			</GridItemBottomLabel>

		</GridItem>
	)
})

const WeatherImage = (props) => {
	const {uiStore: {NYTime}} = useStore();

	const hour = NYTime.hReal
	const dayNightStr = hour >= 18 || hour < 6 ? "night" : "day";
	let imageSrc;



	switch(props.conditions) {
		case "Thunderstorm":
			imageSrc = assetImageUrls[`${dayNightStr}-thunderstorm`];

			// imageSrc = `/assets/images/weather/${dayNightStr}-thunderstorm.jpg`

			break;
		case "Drizzle":
			imageSrc = assetImageUrls[`${dayNightStr}-rain`];

			// imageSrc = `/assets/images/weather/${dayNightStr}-rain.jpg`
			break;
		case "Rain":
			imageSrc = assetImageUrls[`${dayNightStr}-rain`];

			// imageSrc = `/assets/images/weather/${dayNightStr}-rain.jpg`
			break;
		case "Snow":
			imageSrc = assetImageUrls[`${dayNightStr}-snow`];

			// imageSrc = `/assets/images/weather/.jpg`
			break;
		case "Clear":
			imageSrc = assetImageUrls[`${dayNightStr}-clear`];

			// imageSrc = `/assets/images/weather/${dayNightStr}-clear.jpg`
			break;
		case "Clouds":
			imageSrc = assetImageUrls[`${dayNightStr}-cloudy`];

			// imageSrc = `/assets/images/weather/${dayNightStr}-cloudy.jpg`
			break;
		case "Fog":
			imageSrc = assetImageUrls[`${dayNightStr}-fog`];

			// imageSrc = `/assets/images/weather/${dayNightStr}-fog.jpg`
			break;

		case "Mist":
			imageSrc = assetImageUrls[`${dayNightStr}-fog`];

			// imageSrc = `/assets/images/weather/${dayNightStr}-fog.jpg`
			break;
		default:
			imageSrc = assetImageUrls[`${dayNightStr}-cloudy`];
	}

	return (
		<div className="weather flex--center">
			<img src={`${imageSrc}?w=400&fm=webp&q=90`} alt=""/>
			<style jsx>{`

				img {
					width: 100%;
					object-fit: cover;
				}
				div {
					height: 125px;
				}

				@media (max-width: 1280px) {
					div {
						height: auto;
					}
				}
			`}</style>

		</div>
	)
}



export default WidgetWeather