import React from 'react'
import {observer} from 'mobx-react'

import {Helmet} from "react-helmet";

import {
	useStore
} from '../../hooks/useStore'

const Head = observer((props) => {
	const {routerStore: {headTitle, headDescription, headImage}} = useStore();

	return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{headTitle}</title>

	        <meta name="description" content={headDescription} />
		    <meta name="image" content={headImage}/>

		    <meta itemprop="name" content={headTitle}/>
		    <meta itemprop="description" content={headDescription}/>
		    <meta itemprop="image" content={headImage}/>
			
	        <meta property="og:title" content={headTitle}/>
	        <meta property="og:site_name" content="School"/>
	        <meta property="og:url" content={window.location.href}/>
	        <meta property="og:description" content={headDescription} />
	        <meta property="og:type" content="website"/>
	        <meta property="og:image:url" content={headImage}/>

	        <meta name="twitter:card" content="summary_large_image"/>
	        <meta name="twitter:title" content="School"/>
	        <meta name="twitter:description" content={headDescription}/>
	        <meta name="twitter:image" content={"https:" + headImage}/>
        </Helmet>
	)
})

export default Head