import React from 'react'
import {observer} from 'mobx-react-lite'

import { useInView } from 'react-intersection-observer'

import { Image } from '../../../general/assets'
import { useStore } from '../../../../hooks/useStore'
import get from 'lodash/get'

const BlockImage = observer((props) => {
	const [ref, inView, entry] = useInView({
	  threshold: .33,
	  triggerOnce: true
	})

	const {contentfulStore: {pageData}} = useStore();
	const isRedacted = get(pageData, "fields.isRedacted");

	const description = props.image.fields.description;



	return (
		<div ref={ref} className={`block__image ${inView ? "in-view" : ""}`}>
			<div className="image">
				<Image inView={inView} width={1800} q={90} image={props.image}/>
				{description ? <span className="description text-xs">{isRedacted ? <mark>{description}</mark> : description}</span> : null}
				<span className="fig-num text-xs">{props.prefix}-{props.idx}</span>
			</div>

			<style jsx>{`
				.block__image {
					width: 100%;
				}

				.image {
					opacity: 0;
					transition: all 1.7s cubic-bezier(0.33, 1, 0.68, 1);
					transition-delay: .15s;
					transform: translate3d(0px, 20px, 0);
					line-height: 0;
				}

				.image :global(img) {
					width: 100%;
					line-height: 0;
					border-radius: ${props.full ? "0" : "30px"};
				}

				.description, .fig-num {
					position: absolute;
					bottom: 0;
					transform: translateY(100%) translateY(6px);
				}

				.description {
					left: 24px;
					max-width: 75%;
				}

				.fig-num {
					right: 24px;
				}

				.in-view .image {
					opacity: 1;
					transform: translate3d(0, 0px, 0);
				}


				@media (pointer: coarse) {
					img {
						width: 100%;
					}
				}
			`}</style>
		</div>
	)
})

export default React.memo(BlockImage)
