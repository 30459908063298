import React from 'react'
import {observer} from 'mobx-react'
import { useInView } from 'react-intersection-observer'

import {
	Link,
} from '../../link'

import get from 'lodash/get'
import {useStore} from '../../../hooks/useStore'
import { getImageURL } from '../../../utils'
import {Video } from '../../general/assets'


const WorkListItem = observer((props) => {
	const {uiStore: {windowHeight, styles}} = useStore();
	const [ref, inView] = useInView({
	  /* Optional options */
	  threshold: .5,
	  triggerOnce: true
	})

	const slug = get(props.data, "fields.slug");

	return (
		<div ref={ref} className={inView ? "in-view work-li flex" : "work-li flex"} >

			<div className="media flex--center">
				<Media inView={inView} {...props}/>
			</div>

			<div className="work__info flex--center-y">
					<div className="work__info__inner">
						<Tagline inView={inView} {...props}/>

						<Link to="work.view" params={{id: slug}}>
							<div className="view-project-link flex--center text-sh"> View Project </div>
						</Link>

					</div>
			</div>


			<style jsx>{`

				.work-li {
					opacity: 0;
					transition: all 1.7s cubic-bezier(0.33, 1, 0.68, 1);
					transition-delay: .15s;
					transform: translate3d(0px, 30px, 0);
				}

				.in-view.work-li {
					opacity: 1;
					transform: translate3d(0, 0px, 0);
				}


				.work-li {
					height: ${windowHeight * .75}px;				  
				}

				.work__info {
					height: 100%;
					padding: 20px;
				}

				.media {
					height: 100%;
					padding: 0 73px;
				}

				.media {
					// flex: 1;
					width: 50%;
				}

				.work__info {
					// flex: 1;
					width: 50%;
					padding: 0 100px 0 100px;
				}

				.work__info__inner {
					width: 100%;
				}

				.work__info > div {
					max-width: 500px;
				}

				.view-project-link {
					height: 50px;
					border-radius: 30px;
					background: ${styles.colors.lightGray};
					text-align: center;
					margin-top: 32px;
					transition: background .2s ease;
				}

				.view-project-link:hover {
					background: #ddd;
				}

				@media (pointer: coarse) {
					.work-li, .work__info, .media {
						display: block;
						height: auto;
						width: 100%;
					}

					.work-li {
						padding: 20px;
					}

					.media {
						padding: 0;
					}

					.work__info {
						padding: 12px 8px;
					}

					.work-li {
						margin-bottom: 80px;
					}

		
				}
				
			`}</style>
		</div>
	)
})

const Media = observer((props) => {
	const src = get(props.data, "fields.listViewMedia.fields.file.url", "");
	const type = get(props.data, "fields.listViewMedia.fields.file.contentType", "");
	const orientation = get(props.data, "fields.listViewMediaOrientation", "");
	const slug = get(props.data, "fields.slug");
	const name = get(props.data, "fields.name");

	return (
		<div className={orientation === "landscape" ? "landscape media" : "media"}>
			<Label>{name}</Label>
			<Link to="work.view" params={{id: slug}}>

				{type === "video/mp4" ? 
					<Video inView={props.inView} src={src}/>
					:
					<img src={getImageURL(src, 1400, "webp", 90)} alt=""/>
				}
			</Link>
			<style jsx>{`

				.media {
					width: 100%;
					max-width: 70%;
					padding-bottom: 87.5%;
					position: relative;
					object-fit: cover;
					overflow: hidden;
					contain: content;
					border-radius: 30px;
					background: rgba(235,235,235, .7);
				}

				.landscape {
					// max-width: 90%;
					padding-bottom: 60%;
					max-width: 100%;
					width: 100%;
				}

				
				.media :global(video), .media :global(img) {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100%;
						height: 101%;
						max-width: initial;
						border-radius: 30px;
						object-fit: cover;
				}

			

				@media (pointer: coarse) {
					.media {
						margin: 0 auto;
						max-width: 100%;
						border-radius: 30px;
						transform: rotateZ(0);
					}
				}
			`}</style>
		</div>
	)
})

const Label = (props) => {
	return (
		<div>
			<h6 className="text-s plex-mono">
				{props.children}
			</h6>
			
			<style jsx>{`
				div {
					position: absolute;
					top: 12px;
					left: 12px;
					padding: 4px 12px;
					background: white;
					z-index: 10;
					border-radius: 20px;
				}
			`}</style>
		</div>
	)
}

const Tagline = (props) => {
	const tagline = get(props.data, "fields.tagline.content[0].content", []);	
	const text = [];

	tagline.forEach((node, idx) => {

		if (!node.marks[0]) {
			text.push(<span key={`${node.value}-${idx}`}>{node.value}</span>)
		} else {
			text.push(<span  key={`${node.value}-${idx}`} className={`highlight ${props.inView ? "active" : ""}`}><span key={`${node.value}-${idx}`}>{node.value}</span> <span className="band"></span> <style jsx>{`.active .band {transition-delay: .5s;}`}</style></span>)
		}
	})

	return (
		<p className="text-l helvetica">
			{text}

			<style jsx>{`
				p {
					margin: 8px 0 20px;
				}

			`}</style>
		</p>
	)
}



export default WorkListItem