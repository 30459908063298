import React from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../../../../hooks/useStore'

import {
	GridItem,
	GridItemTopLabel
} from '../griditem'

import {
	AvatarPic
} from '../../../avatar'

import {
	Button
} from '../../../button'



const WidgetPoll = observer((props) => {
	const {firebaseStore: {submitPollVote, pollSubmitted, activePollOption}} = useStore();

	return (
		<GridItem  {...props}>
			<GridItemTopLabel>(Y)our poll.</GridItemTopLabel>

			<div className="poll">
				<div className="poll-wrap">
					<PollTitle/>

					<div className="flex--center">
						<PollOptions/>
					</div>
				</div>


				<div className={pollSubmitted || activePollOption === null ? "btn-wrap flex--center disabled" : "btn-wrap flex--center"}>
					<Button onClick={pollSubmitted ? null : submitPollVote}>
						Submit
					</Button>
				</div>


				<style jsx>{`
					.poll {
						margin: 0 auto;
					}

					.btn-wrap {
						padding: 12px 0;
						transition: opacity .35s ease-out;
					}

					.poll-options {
						margin: 20px 0;
						display: inline-block;
					}

					.poll-wrap {
						margin: 20px 0;
					}


					h6 {
						color: white;
					}

					.disabled {
						opacity: .2;
						pointer-events: none;
					}
				`}</style>
			</div>
		</GridItem>
	)
});


const PollTitle = observer((props) => {
	const {firebaseStore: {val__pollTitle}} = useStore();

	return (
		<div className="poll-q">
			<h4 className="highlight active text-m">
				{val__pollTitle}
				<div className="band"></div>
			</h4>

			<style jsx>{`
				.poll-q {
					text-align: center;
					margin-bottom: 12px;
				}
			`}</style>
		</div>
	)
})

const PollOptions = observer((props) => {
	const {firebaseStore: {val__poll, val__pollChoices}} = useStore();

	var total = 0;

	Object.keys(val__poll).forEach((key, idx) => {
		// console.log(val__poll)
		if (val__poll[key] || val__poll[key] === 0 ) {
			total += val__poll[key];
		}
	})

	const options = val__pollChoices.map((option, idx) => {
		if (!val__poll[idx] && val__poll[idx] !== 0) return null
		
		return 	<PollOption idx={idx} key={`pl-${idx}`} val={(val__poll[idx] * 100 / total).toFixed(0)}>{option}</PollOption>
	})

	return (
		<div className="poll-options">
			{options}

			<style jsx>{`
				div {
					max-width: 50%;
				}
			`}</style>
		</div>
	)
})
			 
const PollOption = observer((props) => {
	const {firebaseStore: {userData, activePollOption, updatePollOption, pollSubmitted}} = useStore();

	const isSelected = activePollOption === props.idx;

	return (
			<div className="poll-option flex--center-y">
				{pollSubmitted ? isSelected ? <AvatarPic params={userData.avatar} size={16}/> : null : <div className={ isSelected ? "active radio" : "radio"} onClick={() => updatePollOption(props.idx)} ></div> }
				<h6 className={`text-m poll-a ${pollSubmitted ? "submitted" : ""}`}>{props.children}</h6>
				{pollSubmitted ? <h6 className="text-m val">{props.val}%</h6> : null}


				
				<style jsx>{`
					.poll-option {
						justify-content: ${pollSubmitted ? "space-between" : 'initial'};
						position: relative;
						margin: 12px 0;
					}

					.poll-a {
						// max-width: 66%;
						width: 100%;
					}
					.radio {
						position: absolute;
						left: -24px;
						height: 16px;
						width: 16px;
						border-radius: 50%;
						border: 1px solid gray;
						margin-right: 8px;
						display: inline-block;
						cursor: pointer;
						transition: background .15s ease, border .15s ease;
					}

					.poll-option :global(.avatar-section) {
						position: absolute;
						left: -24px;
						opacity: 0;
						animation: fade-in .5s ease;
						animation-delay: ${.5 + (.03 * props.idx)}s;
						animation-fill-mode: forwards;
					}

					.radio.active {
						background: black;
						border: 1px solid black
					}

					.val {
						position: absolute;
						right: -50px;
						margin-left: 8px;
						opacity: 0;
						animation: fade-in .5s ease;
						animation-delay: ${.5 + (.03 * props.idx)}s;
						animation-fill-mode: forwards;
						// position: relative;
					}

					.selected {
						font-weight: bold;
					}


					.submitted {
						// transform: translateX(29px);
						// animation: slide-left .35s ease-out;
						// animation-delay: ${.12 + (.03 * props.idx)}s;
						// animation-fill-mode: forwards;
					}


					@keyframes slide-left {
						from {
							transform: translate3d(29px, 0, 0);
						}
						to {
							transform: translate3d(0px, 0, 0);
						}
					}

					@keyframes fade-in {
						from {
							opacity: 0;
							transform: translate3d(3px, 0, 0);
						}
						to {
							opacity: 1;
							transform: translate3d(0px, 0, 0);

						}
					}
				`}</style>
			</div>

	)
})

export default WidgetPoll