import React from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../../../../hooks/useStore'

import {
	GridItem,
	GridItemTopLabel
} from '../griditem'

const WidgetDrawingPixel = observer((props) => {
	const {isMobile, firebaseStore, uiStore:{windowHeight, styles:{colors:{avatar: avatarColors}}}} = useStore();
	if (isMobile) return <DrawingCanvas isMobile={isMobile} windowHeight={windowHeight} firebaseStore={firebaseStore} colors={avatarColors}/>

	return (
		<GridItem {...props}>
			<GridItemTopLabel>(Y)our drawing.</GridItemTopLabel>
			<div className="drawing-container">
				{/*<LastReset/>*/}
				<DrawingCanvas isMobile={isMobile} windowHeight={windowHeight} firebaseStore={firebaseStore} colors={avatarColors}/>
				<div className="pencil">
					<img src="/assets/images/pencil.svg" alt=""/>
					<PencilTip/>
				</div>


				<style jsx>{`

					.drawing-container {
						position: relative;
						width: 100%;
						line-height: 0;
						padding: 30px;

					}

					.pencil {
						position: absolute;
						right: 20px;
						margin-left: 20px;
						display: inline-block;
					}

					img {
						width: 40px;
					}

					@media (max-width: 1430px) {
						.pencil {
							display: none;
						}

						.drawing-container {
							padding: 24px;
						}
					}
				`}</style>
			</div>
		</GridItem>
	)
});

const PencilTip = observer((props) => {
	const {uiStore: {styles}, firebaseStore: {userData}} = useStore();
	if (!userData) return null;
		// const color = this.rootStore.uiStore.styles.colors.avatar[this.userData.avatar.color] || "#000000";

	return(
		<div>
			
			<style jsx>{`
				div {
					position: absolute;
					bottom: 5px;
					left: 19px;
					width: 11px;
					height: 12px;
					background: ${styles.colors.avatar[userData.avatar.color]};
				}
			`}</style>
		</div>
	)
})

function round(x, interval) { 
    return Math.floor(x / interval) * interval; 
}

class DrawingCanvas extends React.PureComponent {
	constructor(props) {
		super(props);

		this.flag = false;
		this.prevX = 0;
		this.prevY = 0;
		this.currX = 0;
		this.currY = 0;

		this.opacity = 1;

		this.inkMode = true;
		this.colors = props.colors;

		this.interval = props.isMobile ? 15 : 10;
		this.height = props.windowHeight;
	}


	componentDidMount() {
		this.init()
	}



	init() {
		const canvas = this.canvas;

		var dpr = window.devicePixelRatio || 1;
		var rect = canvas.getBoundingClientRect();
		this.offsetLeft = rect.left;
		this.offsetTop = rect.top;

		canvas.width = rect.width * dpr;
		canvas.height = rect.height * dpr;

		const offscreen = canvas.transferControlToOffscreen ? canvas.transferControlToOffscreen() : canvas;
		var ctx = offscreen.getContext('2d', {alpha: false});
		ctx.fillStyle = "#f9f9f9";

		ctx.fillRect(0,0,canvas.width,canvas.height);
		// Scale all drawing operations by the dpr, so you
		// don't have to worry about the difference.
		ctx.scale(dpr, dpr);
		this.ctx = ctx;
		this.props.firebaseStore.registerCanvas(this);

	}

	updateDatabase(x,y ) {
		this.props.firebaseStore.addDrawingPoint(x,y, this.color, this.opacity, this.props.isMobile);
	}

	fill(x,y,color) {
		// console.log("filling", color)
		this.ctx.fillStyle = color;
		this.ctx.clearRect(x,y, this.interval, this.interval);
		this.ctx.fillRect(x,y, this.interval, this.interval);
	}


	clear() {
		this.ctx.clearRect(0,0,this.canvas.width,this.canvas.height);
	}

	handleMouseDown(e) {
		e.preventDefault();
		e.stopPropagation();


		let clientX, clientY;
		if (e.touches && e.touches.length) {
			clientX = e.touches[0].clientX;
			clientY = e.touches[0].clientY;
		} else {
			clientX = e.clientX;
			clientY = e.clientY;
		}


		if (this.inkMode) {
			this.opacity = 1;
			this.color = this.colors[this.props.firebaseStore.userData.avatar.color];
		}

		var rect = this.canvas.getBoundingClientRect();
		this.offsetLeft = rect.left;
		this.offsetTop = rect.top;
		const x = round(clientX  - this.offsetLeft, this.interval);
		const y = round(clientY  - this.offsetTop, this.interval);

		this.updateDatabase(x,y);
		// this.props.firebaseStore.addDrawingPoint(x,y,this.color, this.opacity);


		this.lastCoord = {x,y};
		this.flag = true;
	}



	handleMouseMove(e) {
		let clientX, clientY;
		if (e.touches && e.touches.length) {
			clientX = e.touches[0].clientX;
			clientY = e.touches[0].clientY;
		} else {
			clientX = e.clientX;
			clientY = e.clientY;
		}

		if (this.flag) {

			const x = round(clientX  - this.offsetLeft, this.interval);
			const y = round(clientY  - this.offsetTop, this.interval);
			if (x === this.lastCoord.x && y === this.lastCoord.y) return
			this.lastCoord = {x,y};

			if (this.inkMode) {
				this.opacity -= .005;
				if (this.opacity < 0) {
					this.opacity = 0;
					return
				}
			}

			// this.props.firebaseStore.addDrawingPoint(x,y,this.color, this.opacity);
			this.updateDatabase(x,y);

		}
	}

	

	handleMouseUp() {
		this.flag = false;
	}

	handleMouseOut() {
		this.flag = false;
	}

	render() {
		return (
			<React.Fragment>
				<canvas 
					ref={canvas => this.canvas = canvas}
					onMouseDown={this.handleMouseDown.bind(this)}
					onMouseUp={this.handleMouseUp.bind(this)}
					onMouseOut={this.handleMouseOut.bind(this)}
					onMouseMove={this.handleMouseMove.bind(this)}
					onTouchStart={this.handleMouseDown.bind(this)}
					onTouchMove={this.handleMouseMove.bind(this)}
					onTouchEnd={this.handleMouseOut.bind(this)}
					onTouchCancel={this.handleMouseOut.bind(this)}
				/>


				<style jsx>{`
					canvas {
						top: 0;
						width: 100%;
						transform:translate3d(0,0,0);
						height: 100%;
						height: 500px;
						width: 400px;
						margin: 0 auto;
						cursor: crosshair;
						background: white;
						box-shadow: -10px 10px 0px gray;
					}

					@media (pointer: coarse) {
						canvas {
							box-shadow: none;
							width: 100%;
							height: ${this.height - 170}px;
							border-radius: 12px;
						}
					}
				`}</style>
			</React.Fragment>
		)
	}
}

export default WidgetDrawingPixel