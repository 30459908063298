import { action, computed, observable } from 'mobx';
import get from 'lodash/get'

// import {
// 	assetImages
// } from '../utils/asset-images.js'


const client = require('contentful').createClient({
	space: 'nr300w6wpi1u',
	accessToken: 'yBLsajVHu4njCgLpWKJV8dk_VMcx4GIdpx0K-G880pM'
});


class ContentfulStore {

	constructor(rootStore) {
		this.rootStore = rootStore;
		// this.fetchImages()
	}
	
	data = {
		home: null,
		workList: [],
		workView: {},
		aboutView: null,
		captcha: {
			maps: [],
			mood: []
		}
	}

	@observable dataAvailable = {
		home: false,
		workList: false,
		// workView: false,
		workView: {},
		aboutView: false,
		captcha: false
	}

	// @observable workList = null;
	// @observable workView = null;
	// @observable aboutView = null;





	@computed get pageData() {
		if (!this.rootStore.routerStore.current) return
		const routeName = this.rootStore.routerStore.current.name;

		switch(routeName) {
			case "work.view":
				const id = this.rootStore.routerStore.current.params.id;
				if (this.dataAvailable.workView[id]) {
					return this.data.workView[id]
				} else {
					return null;
				}
			case "home":
				if (this.dataAvailable.home) {
					return this.data.home
				} else {
					return null;
				}

			default :
				return null
		}

		// return null
	}

	@computed get mobileCarouselSlides() {
		// console.log(this.pageData)
		const intro = {
			fields: {
				type: "Intro",
				media: this.pageData.fields.workViewMediaMobile,
				tagline: this.pageData.fields.tagline
			}
		};

		return [intro].concat(get(this.pageData, "fields.contentBlocks", []))
	}


	// fetchImages() {
	// 	const assetObj = {};
	// 	const assetImages = {
	// 		"seo": "7aDFpzMtT8NV1KKYXfuRD8",
	// 		// "rocket-man-2": "3g3MOK82bmK9hIEsjkE9qK",
	// 		// "rocket-man-3": "7JwEl4mWt9R6hvY7tLuWgV"
	// 	}
		
	// 	Object.keys(assetImages).forEach(async key => {
	// 		const id = assetImages[key];
	// 		const entry = await client.getAsset(id);

	// 		assetObj[key] = entry.fields.file.url;
	// 	})

	// 	setTimeout(() => {
	// 		console.log(JSON.stringify(assetObj))
	// 	}, 1000)
	// }

	@computed get tagTypes() {
		if (!this.dataAvailable.workList) return [];
		const tags = {};

		this.data.workList.forEach(item => {
			item.fields.tags.forEach(tag => {
				tags[tag.fields.name] = true;
			})
			// tags[item.fields.t]
		});

		return Object.keys(tags);
	}

	@action.bound async fetchHomeView() {

		const entry = await client.getEntry("2nfUjpMxtrQapUty4wWZQL", {include: 2});
		this.data.home = entry;
		this.dataAvailable.home = true;
	}

	@action.bound async fetchCaptchaImages() {
		// if (this.dataAvailable.captcha) return

		try {
			const content = await Promise.all([this.fetchMoodImages(), this.fetchMapImages()]);

			this.data.captcha.mood = content[0].items;
			this.data.captcha.maps = content[1].items;
			this.dataAvailable.captcha = true;

			this.rootStore.uiStore.setCaptchaImages()
		} catch(e) {
			console.error("captcha fetch error", e)
		}

	}	

	fetchMapImages() {
		const params = {
			"content_type": "mapImage"
		};

		return client.getEntries(params)
			.then(this.setMapImages)
	}

	fetchMoodImages() {
		const params = {
			"content_type": "moodImage",
			"select": "fields"
		};
		
		return client.getEntries(params)
			.then(this.setMoodImages)
	}

	@action.bound async fetchWorkList() {
		if (this.dataAvailable.workList) return
		// this.rootStore.dataStore.fetchWorkList();
		
		const options = {
			content_type: 'work',
			select: "fields.name,fields.isPreview,fields.slug,fields.tagline,fields.listViewMedia,fields.listViewMediaOrientation,fields.tags,fields.isTitleRedacted",
			include: 1,
			order: "-fields.projectOrder"
		};
		

		const resp = await client.getEntries(options);
		
		this.data.workList = resp.items;
		this.dataAvailable.workList = true;
	}

	@action.bound async fetchWorkView(slug) {
		const options = {
			content_type: 'work',
			'fields.slug': slug,
			include: 5
		};

		const entries = await client.getEntries(options);
		// return entries;
		this.data.workView[slug] = entries.items[0];
		this.dataAvailable.workView[slug] = true;
		// this.data.workView = entries.items[0];
		// this.dataAvailable.workView = slug;
	}

	@action.bound async fetchAboutView(slug) {
		if (this.data.aboutView) return

		const id = "2izQavpYnaRP8SfSkkZxPp";


		const data = await client.getEntry(id);
		this.data.aboutView = data;
		this.dataAvailable.aboutView = true;
	}


}

export default ContentfulStore