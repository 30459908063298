import React from 'react'
import {observer} from 'mobx-react-lite'

import { useStore } from '../../../../hooks/useStore';
import get from 'lodash/get'

const BlockHeadline = observer((props) => {
	const {contentfulStore: {pageData}} = useStore()

	const isRedacted = get(pageData, "fields.isRedacted");

	return (
		<div className="block">
			<div className="block__headline">
				<h2 className="windsor text-xxl">
					{isRedacted ? <mark>{props.children}</mark> : props.children }
				</h2>
			</div>

			<style jsx>{`
				.block {
					min-height: 90vh;
					display: flex;
				}

				.block__headline {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 100%;
					padding: 65px 100px;
				}

				h2 {
					text-align: center;
				}


			`}</style>
		</div>

	)
})


export default React.memo(BlockHeadline)
