import React from 'react'
import {observer} from 'mobx-react'
import {
	useStore
} from '../../../hooks/useStore'

const PageTransitionHOC = observer((props) => {
	const {routerStore: {routeTransitioning}} = useStore();

	return (
		<div className={`${routeTransitioning ? "tr" : ""}`}>
			{props.children}

			<style jsx>{`
				div {
					transition: opacity .5s cubic-bezier(0.33, 1, 0.68, 1);
					opacity: 1;
					transition-delay: .85s;
					// filter: blur(0px);

				}
				.tr {
					opacity: 0;
					// filter: blur(7px);
					// transform: translate3d(0, 7px, 0);
					transition-delay: .05s;
				}
			`}</style>
		</div>
	)
})

export default PageTransitionHOC