import React from 'react'
import {observer} from 'mobx-react'

import {
	useStore
} from '../../hooks/useStore'

import {
	LogoSection,
	NavWorkButton,
	NavInfoButton,
	ProjectName,
	ExitLink
} from './subcomponents'

const NavMobile = observer((props) => {
	const {routerStore: {routeTransitioning, viewingWork}} = useStore();

	return (
		<div className="nav__outer">
			<div className="nav__inner flex--center-y">
				<div className="sq flex--center-y border-l bg--t3">
					<div className={routeTransitioning ? "tr ee" : "ee"}>
						<LogoSection/>
					</div>
				</div>

				<div className="sq sq--2 flex--center bg--t3">
					<div className={routeTransitioning ? "tr ee" : "ee"}>
						{viewingWork ? 
							<ProjectName/>
							: 
							<NavWorkButton/>
						}
					</div>
				</div>

				<div className="sq flex--center-y bg--t3 border-r">
					<div className={routeTransitioning ? "tr ee" : "ee"}>
						{viewingWork ?  
							<ExitLink/>
							: 
							<NavInfoButton/>
						}
					</div>
				</div>
			</div>

			<style jsx>{`	
				.nav__outer {
					position: fixed;
					z-index: 100;
					bottom: 20px;
					height: 44px;
					padding: 0 20px;
					width: 100%;
				}

				.nav__inner {
					height: 100%;
					width: 100%;

				}	

				.sq {
					height: 100%;
					margin: 0 1px;
					backdrop-filter: blur(10px);
					transform: translate3d(0,0,0);
				}

				.sq :global(> div ) {
					padding: 12px 8px;
				}

				.sq--2 {
					flex: 1;
				}
				.border-l{
					border-radius: 25px 0px 0px 25px; 
				}

				.border-r {
					border-radius: 0px 25px 25px 0px; 
				}

				.ee {
					transition: opacity .2s ease;
					transition-delay: .5s;
				}

				.tr {
					opacity: 0;
					transition-delay: .2s;
				}
			`}</style>
		</div>
	)
})



export default NavMobile