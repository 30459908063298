export const names = [
	"Tolstoy", "Descartes", "Ludwig", "Van Gogh", "Brontë",
	"Freud", "Thoreau", "Cervantes", "Flaubert", "Gandhi", 
	"Vivaldi", "Dickinson", "Monet", "Browning", "Wolfgang",
	"Emerson", "Rodin", "William", "Proust", "Hugo", "Dickens",
	"Austen", "Michelangelo", "Hippocrates", "Jefferson", "J.S.", 
	"Wordsworth", "Lincoln", "Bizet", "Da Vinci", "Socrates", "Newton",
	"Galileo", "Keats", "Winston", "Darwin", "Nietzsche", "Kierkegaard",
	"Rosa","Ada", "Florence", "Amelia", "Creola", "Harriet", "Maya", "Shirley", "Angela",
	"Octavia", "Bell", "Coretta" , "Romare", "Gordon", "Duke", "Langston", "Toni", "Ida"
]