import React, { useState } from 'react'
import {observer} from 'mobx-react-lite'
import { useInView } from 'react-intersection-observer'
import { useStore } from '../../../../hooks/useStore'

import get from 'lodash/get'

const BlockTextVideo = observer((props) => {
	const [ref, inView] = useInView({
	  threshold: .33,
	  triggerOnce: true
	})
	const {uiStore: {workViewFlexWidth}, contentfulStore: {pageData}} = useStore();
	const [canPlay, setCanPlay] = useState(false);


	const poster = props.poster;
	if (!poster) return null;
	const {width, height} = poster.fields.file.details.image;
    const aspectRatio = height / width;

	const description = props.video.fields.description;
	const isRedacted = get(pageData, "fields.isRedacted");


	return (
		<div ref={ref} className={`block__text-image ${inView ? "in-view" : ""}`}>
			<div className="text">
				<p className="text-xs helvetica">
					{isRedacted ? <mark>{props.text}</mark> : props.text}
				</p>
			</div>

			<div className="image">
				{/* {inView ? <video src={props.video.fields.file.url} autoPlay muted loop playsInline></video> : null} */}
				<div className="i-wrap">
					{inView ? <video src={props.video.fields.file.url} onCanPlay={() => setCanPlay(true)} autoPlay muted loop playsInline></video> : null} 
					{!canPlay ? <img src={poster.fields.file.url} alt=""/> : null}

				</div>
				{description ? <span className="description text-xs">{isRedacted ? <mark>{description}</mark> : description }</span> : null}
				<span className="fig-num text-xs">{props.prefix}-{props.idx}</span>
			</div>


			<style jsx>{`
				.block__text-image {
					display: flex;
					align-items: center;
				}
				
				.text {
					width: 400px;
					text-align: left;
					padding: 0 73px;
					transition: all 1.2s cubic-bezier(0.33, 1, 0.68, 1);
					transition-delay: .45s;
					opacity: 0;
					transform: translate3d(0, 20px, 0);
				}

				.image {
					flex: 1;
					width: ${workViewFlexWidth}px;
					height: 100%;
					opacity: 0;
					transition: all 1.7s cubic-bezier(0.33, 1, 0.68, 1);
					transition-delay: .15s;
					transform: translate3d(0px, 20px, 0);
					text-align: right;
					line-height: 0;
				}
				
				.i-wrap {
					height: 0;
					position: relative;
					padding-bottom: ${aspectRatio *  100}%;
				}

				img {
					position: absolute;
					width: 100%;
					top: 0;
					left: 0;
					border-radius: ${props.full ? "0" : "30px"};
				}


				.description, .fig-num {
					position: absolute;
					bottom: 0;
					transform: translateY(100%) translateY(6px);
				}

				.description {
					left: 24px;
					max-width: 75%;
				}

				.fig-num {
					right: 24px;
				}

				video {
					width: 100%;
					// height: 100%;
					// object-fit: cover;
					border-radius: 30px;
				}

				.in-view .image, .in-view .text {
					opacity: 1;
					transform: translate3d(0, 0px, 0);
				}

		

				@media (pointer: coarse) {
					.block__text-image {
						display: block;
						width: 100%;
					}


					.text {
						padding: 0 20px;
						margin-bottom: 20px;
						transform: none;
						opacity: 1;
					}

					.image {
						opacity: 1;
						transition: none;
						transform: none;
					}

					video {
						width: 100%;
						height: auto;
						object-fit: initial;
					}
				}
			`}</style>
		</div>
	)
})

export default React.memo(BlockTextVideo)
