import React from 'react'
import {
	observer
} from 'mobx-react'

import {
	ViewProjectLink
} from './'

import {
	useStore
} from '../../../hooks/useStore'

import get from 'lodash/get'

const ProjectName = observer((props) => {
	const {isMobile, contentfulStore: {pageData}} = useStore();
	// const {routerStore: {routeTransitioning, current, viewingWork}} = useStore();

	// console.log("updating with new page data")
	const name = get(pageData, "fields.name", "");
	const isRedacted = get(pageData, "fields.isTitleRedacted", false);

	return (
		<div className="flex--center-y">
			<h4 className="text-xs plex-mono">{ isRedacted ? <mark>{name}</mark>  : name }</h4>
			{isMobile ? null : <ViewProjectLink/>}

			<style jsx>{`
				div {
					text-align: center;
				}

				h4 {
					width: 100%;
					padding: 0 20px;
				}
			`}</style>
		</div>
	)
});

export default ProjectName