import React from 'react'
import {observer} from 'mobx-react'

import {
	useStore
} from '../../../../hooks/useStore'

import { Waypoint } from 'react-waypoint';

import {
	BlockHeadline,
	BlockTextImage,
	BlockTextVideo,
	BlockVideo,
	BlockImage
} from './'
const BlockWrapper = observer((props) => {
	const {uiStore: {updateWorkViewActiveSection, waypointOffset}} = useStore();


	let content;
	let paddingL = 0;
	let paddingR = 220;
	const id = `${props.prefix}-${props.idx}`;

	switch(props.data.fields.type) {
		case "Headline":
			paddingL = 40;
			paddingR = 40;

			content = <BlockHeadline full>{props.data.fields.text}</BlockHeadline>
			break;
		case "Text-Image":
			
			content = <BlockTextImage text={props.data.fields.text} image={props.data.fields.media} {...props}/>
			break;
		case "Text-Video":
			// content = null;
			content = <BlockTextVideo text={props.data.fields.text} video={props.data.fields.media} poster={props.data.fields.posterImage} {...props}/>
			break;
		case "Image":
			paddingL = 73;
			// content = null;
			content = <BlockImage image={props.data.fields.media} {...props}/>
			break;
		case "Video":
			paddingL = 73;
			// content = null;
			content = <BlockVideo video={props.data.fields.media} poster={props.data.fields.posterImage} {...props}/>
			break;
		case "Image-Full":
			paddingL = 0;
			paddingR = 0;
			// content = null;
			content = <BlockImage full image={props.data.fields.media}  {...props}/>
			break;
		case "Video-Full":
			paddingL = 0;
			paddingR = 0;
			// content = null;
			content = <BlockVideo full video={props.data.fields.media} poster={props.data.fields.posterImage} {...props}/>
			break;
		default:
			return null
				
	}

	return (
		<Waypoint onEnter={() => updateWorkViewActiveSection(id)} topOffset={waypointOffset} bottomOffset={waypointOffset}> 
			<div id={id} className="block">

				{content}

				<style jsx>{`
					.block {
						min-height: 600px;
						display: flex;
						// padding-bottom: 220px;
						padding: 40px ${paddingR}px 180px ${paddingL}px;
					}


					@media (pointer: coarse) {
						.block {
							min-height: 0;
							padding: 0;
							width: 100%;
							overflow: hidden;
							margin: 150px 0;
						}
					}
				`}</style>
			</div>
		</Waypoint>
	)
})

export default BlockWrapper