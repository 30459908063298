import React from 'react'
import {observer} from 'mobx-react'

import {
	useStore
} from '../hooks/useStore'

import {
	GridItem
} from '../components/_pages/home/griditem'


import {
	AvatarPic
} from '../components/avatar'

import get from 'lodash/get'
import { characterImageUrls } from '../utils/character-image-urls'
import { Button } from '../components/button'

const AboutView = observer((props) => {
	const {isMobile, contentfulStore: {dataAvailable}, uiStore: {styles}} = useStore();

	if (!dataAvailable.aboutView) return null

	return (
		<div className="about">

			<div className="intro">
				<h2 className="text-xl">
					School is a small, full-service creative studio.  We bring thoughtful perspectives to <span className="highlight active windsor">conceptual<span className="band"></span></span>, <span className="highlight active windsor">practical<span className="band"></span></span> and <span className="highlight active windsor">visual problems<span className="band"></span></span>.
				</h2>
			</div>

			<div className="links">
					<a href="https://instagram.com/schoooooooooooool" target="_blank" rel="noreferrer">
                        <Button width={"100%"}>
                            
                            <span className="text-xs plex-mono">@schoooooooooooool</span>

                        </Button>
                    </a>

					<a href="mailto:info@schoooool.com" target="_blank">
                        <Button width={"100%"}>

                            <span className="text-xs plex-mono">info@schoooool.com</span>

                            
                        </Button>
                    </a>
			</div>

			{isMobile ? <MobileLayout/> : <DesktopLayout/>}

			<style jsx>{`
				.about {
					padding-top: 60px;
				}

				:global(.gi__padding) {
					padding: 20px;
				}

				.about :global(h3) {
					margin-bottom: 20px;
				}

				.about :global(p) {
					font-family: helvetica;
				}

				.intro {
					text-align: center;
					padding: 80px 100px;
					max-width: 1220px;
					margin: 0 auto;
				}

				.about :global(.user-name) {
					margin-top: 20px;
					max-width: 250px;
					padding: 8px 20px;
					text-align: center;
					background: #EFEFEF;
					color: ${styles.colors.black};
					border-radius: 25px;
				}

				.about :global(.user-name--s) {
					max-width: 200px;
				}

				.links {
					position: fixed;
					width: 190px;
					top: 20px;
					right: 24px;
					z-index: 10;
				}

				.links :global(a > div) {
					background: #efefef;
					display: block;
					margin-bottom: 12px;
					background: rgba(235,235,235, .7);
					backdrop-filter: blur(9px);
				}

				.links :global(> div:hover) {
					background: #ddd;
				}

				.about :global(.title) {
					margin-top: 3px;
					color: gray;
				}

				
				.highlight {
					z-index: -1;
				}

				@media (pointer: coarse) {
					.about {
						display: block;
						padding-top: 40px;
						padding-bottom: 40px;
					}

					.intro {
						padding: 20px;
					}

					.col {
						margin: 20px 0;
					}

					.links {
						position: static;
						margin: 0 auto;
						margin-top: 40px;
					}

					.links :global(> div) {
						margin: 12px auto;
					}

				}
			`}</style>
		</div>
	)	
})

const DesktopLayout = () => {
	return (
		<div className="grid">
			<div className="col col-1">
				<Directors/>
				<Grads/>
			</div>

			<div className="sub-grid">
				<div className="col col-span-2">
					<About/>
					<Capabilities/>
				</div>

				<div className="col">
					<Collaborators/>
					<Jobs/>
				</div>

				<div className="col">
					<PastClients/>
					<News/>
				</div>
			</div>

			<style jsx>{`
				.grid {
					display: grid;
					grid-template-columns: 410px 1fr;
					grid-auto-rows: minmax(min-content, max-content);
					gap: 24px;
					padding: 10px 24px;
					padding-top: 100px;

				}

				.sub-grid {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-auto-rows: minmax(min-content, max-content);
					gap: 24px;
				}
				

				.col-span-2 {
					grid-column: 1 / span 2;
				}

				.col-span-2 :global(> div:nth-of-type(2)) {
					margin-bottom: 0;
				}
			`}</style>
		</div>
	)
}
const MobileLayout = () => {
	return (
		<div className="grid">


				<About/>
				<Capabilities/>
				<Directors/>
				<PastClients/>
				<Grads/>
				<Collaborators/>
				<News/>
				<Jobs/>

			<style jsx>{`
				.grid {
					padding: 40px 12px 0;
				}
			`}</style>
		</div>
	)
}

const About = observer(() => {
	const {contentfulStore: {data: {aboutView}}} = useStore();
	const about = get(aboutView, "fields.about.content", []);

	return (
		<GridItem>
			<div className="gi__padding description">
					<h3 className="text-l">About</h3>

					<div className="description__contents">
							{about.map(paragraph => {
								const text = [];

								paragraph.content.forEach((node, idx) => {

									if (!node.marks[0]) {
										text.push(<span key={`${node.value}-${idx}`}>{node.value}</span>)
									} else if (node.marks[0].type === "bold") {
										text.push(<span className="text-m">{node.value}</span>)
									} else {
										text.push(<span key={`${node.value}-${idx}`} className="hl">{node.value}</span> )
									}
								})

								return (
									<p className="text-s helvetica">
										{text}
									</p>
								);
							})}
					</div>
			</div>

			<style jsx>{`
				.hl {
					background-color: #F9F5A2;
				}

				.description p {
					margin: 14px 0 0 0;
				}

				.description h5 {
					margin: 20px 0 0 0;
				}

				.description__contents {
					column-count: 2;
					column-gap: 48px;
				}


				.description__contents > p:first-of-type {
					margin-top: 0;
				}

				@media(pointer: coarse) {
					.description__contents {
						column-count: 1;
					}
				}
			`}</style>
		</GridItem>
	)
})

const Directors = observer(() => {
	const {contentfulStore: {data: {aboutView}}} = useStore();
	const directors = get(aboutView, "fields.directors", []);

	return (
		<GridItem>
			<div className="gi__padding">
				<h3 className="text-l">Directors</h3>
				{directors.map((director, idx) => {
					return (
						<div key={`dr-${idx}`} className="flex--center director">
							<div>
								<div className="">
									<AvatarPic src={characterImageUrls[director.imgKey]} params={{he:1, b: 1, e: 1, ha: 1}} size={200}/>
									<h6 key={`dr-${idx}`}className="flex--center user-name helvetica text-l">{director.name}</h6>
								</div>
								<p className="text-s">
									{director.description}
								</p>
							</div>
						</div>
					)
				})}
			</div>

			<style jsx>{`
				.director {
					margin: 30px 0;
				}

				.director :global(.avatar-section) {
					margin: 0 auto;
				}

				.director .user-name {
					margin: 20px auto;
				}
			`}</style>
		</GridItem>
	)
})


// const RichText = (data) => {
// 	// const paragraphs = get(props.data, "fields.tagline.content[0].content", []);	
// 	const
// 	const text = [];

// 	tagline.forEach((node, idx) => {

// 		if (!node.marks[0]) {
// 			text.push(<span key={`${node.value}-${idx}`}>{node.value}</span>)
// 		} else {
// 			text.push(<span  key={`${node.value}-${idx}`} className={`highlight ${props.inView ? "active" : ""}`}><span key={`${node.value}-${idx}`}>{node.value}</span> <span className="band"></span> <style jsx>{`.active .band {transition-delay: .5s;}`}</style></span>)
// 		}
// 	})

// 	return (
// 		<p className="text-l helvetica">
// 			{text}

// 			<style jsx>{`
// 				p {
// 					margin: 8px 0 20px;
// 				}

// 			`}</style>
// 		</p>
// 	)
// }
const Capabilities = observer(() => {
	const {contentfulStore: {data: {aboutView}}} = useStore();
	const capabilities = get(aboutView, "fields.capabilities.content", []);
	const capabilitiesTwo = get(aboutView, "fields.capabilitiesTwo.content", []);

	return (
		<GridItem>
			<div className="gi__padding capabilities">
					<h3 className="text-l">Capabilities</h3>

					<div className="capabilities__contents">
						<div>
							{capabilities.map(paragraph => {
								const text = [];

								paragraph.content.forEach((node, idx) => {
									if (!node.marks[0]) {
										text.push(<span key={`${node.value}-${idx}`}>{node.value}</span>)
									} else if (node.marks[0].type === "bold") {
										text.push(<span className="text-m">{node.value}</span>)
									} else {
										text.push(<span key={`${node.value}-${idx}`} className="hl">{node.value}</span> )
									}
								})

								return (
									<p className="text-s helvetica">
										{text}
									</p>
								);
							})}
						</div>

						<div>
							{capabilitiesTwo.map(paragraph => {
								const text = [];

								paragraph.content.forEach((node, idx) => {
									if (!node.marks[0]) {
										text.push(<span key={`${node.value}-${idx}`}>{node.value}</span>)
									} else if (node.marks[0].type === "bold") {
										text.push(<span className="text-m">{node.value}</span>)
									} else {
										text.push(<span key={`${node.value}-${idx}`} className="hl">{node.value}</span> )
									}
								})

									return (
										<p className="text-s helvetica">
											{text}
										</p>
									);
							})}
						</div>

					</div>

			</div>

			<style jsx>{`
				.hl {
					background-color: #F9F5A2;
				}

				.capabilities p{
					margin: 12px 0 0 0;
				}

				.capabilities h5{
					margin: 20px 0 0 0;
				}


				.capabilities__contents {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-gap: 48px;
				}

				.capabilities__contents > div:first-of-type >  p:first-of-type, .capabilities__contents > div:nth-of-type(2) > p:first-of-type {
					margin-top: 0;
				}

				@media (pointer: coarse) {
					.capabilities__contents {
						grid-template-columns: 1fr;
						grid-gap: 20px;
					}
				}
			`}</style>
		</GridItem>
	)
})

const Grads = observer(() => {
	const {contentfulStore: {data: {aboutView}}} = useStore();
	const grads = get(aboutView, "fields.graduates", []);

	return (
		<GridItem>
			<div className="gi__padding grads">
				<h3 className="text-l">Grads</h3>

					{grads.map((grad, idx) => {
						return (
							<div key={`gr-${idx}`} className="grad-wrapper">
								<div className="flex--center">
									<div className="grad">
										<AvatarPic src={characterImageUrls[grad.imgKey]} params={{he:1, b: 1, e: 1, ha: 1}} size={150}/>
										<h6 className="flex--center user-name user-name--s helvetica text-m">{grad.name}</h6>
										<h6 className="title helvetica text-xs">{grad.title}</h6>
									</div>
								</div>
							</div>
						)									
					})}
			</div>
			
			<style jsx>{`
				.grad {
					margin: 12px 0;
				}

				.grad-wrapper{
					display: inline-block;
					width: 50%;
				}
				.grad{
					text-align: center;
				}

				.grad :global(.avatar-section) {
					margin: 0 auto;
				}

				@media (pointer: coarse) {
					.grad-wrapper{
						display: inline-block;
						width: 100%;
					}
				}
			`}</style>
		</GridItem>
	)
});

const Collaborators = observer(() => {
	const {contentfulStore: {data: {aboutView}}} = useStore();
	const collaborators = get(aboutView, "fields.collaborators", []);

	return (
		<GridItem>
			<div className="gi__padding">
				<h3 className="text-l">Collaborators</h3>
					{collaborators.map((collaborator, idx) => {
						return (
							<div key={`co-${idx}`}  className="collab-wrapper">
								<div className="flex--center">
									<div className="collab">
										<h6 key={`co-${idx}`}className="flex--center user-name user-name--s helvetica text-m">{collaborator.name}</h6>
										<h6 className="title helvetica text-xs">{collaborator.title}</h6>
									</div>
								</div>
							</div>
						)	
					})}
			</div>

			<style jsx>{`

				.collab-wrapper {
					display: inline-block;
					width: 50%;
				}

				.collab {
					text-align: center;
				}

				@media (max-width: 1350px) {
					.collab-wrapper {
						display: inline-block;
						width: 100%;
					}
				}
				@media (pointer: coarse) {
					.collab-wrapper {
						display: inline-block;
						width: 100%;
					}
				}
			`}</style>
		</GridItem>
	)
});

const PastClients = observer(() => {
	const {contentfulStore: {data: {aboutView}}} = useStore();
	const clients = get(aboutView, "fields.clients", []);

	return (
		<GridItem>
			<div className="gi__padding">
					<h3 className="text-l">Past Clients</h3>
					<p className="text-s">{clients}</p>
			</div>
		</GridItem>
	)
});

const News = observer(() => {
	const {contentfulStore: {data: {aboutView}}} = useStore();
	const news = get(aboutView, "fields.news", []);

	return (
		<GridItem>
			<div className="gi__padding">
				<h3 className="text-l">News</h3>
				{news.map((item, idx) => {
					return <NewsLineItem key={`nli-${idx}`} data={item}/>
				})}
			</div>
		</GridItem>
	)
});

const Jobs = observer(() => {
	return (
		<GridItem>
			<div className="gi__padding">
				<h3 className="text-l">Jobs</h3>
				<p className="text-s">We're not currently hiring but keep an eye on our Instagram and check back here for updates.</p>
			</div>
		</GridItem>
	)
});

const NewsLineItem = (props) => {
	const {data} = props;

	return (
		<a href={data.fields.url} target="_blank">
			<div className="news-line-item flex--center-y">
				<h6 className="helvetica text-s">{data.fields.title} ↗</h6>

				<style jsx>{`
					.news-line-item {
						margin-bottom: 20px;
						cursor: pointer;
						background: #EFEFEF;
						padding: 8px 12px;
						border-radius: 20px;
						text-align: center;
						transition: background .2s ease;
					}

					.news-line-item:hover {
						background: #ddd;
					}

					h6 {
						text-align: center;
						width: 100%;
					}
				`}</style>	
			</div>
		</a>
	)
}

export default AboutView