import React from 'react'

import {
	Logo 
} from '../../general/logo'

const NavLogoSection = (props) => {
	return (
		<div>
			<Logo/>
		</div>
	)
}


export default React.memo(NavLogoSection)