import rootStore from '../stores/RootStore.js'
import { sendAnalyticsPageView } from '../utils/index.js';
// Tell MobX which page we're on
export function mobxRouterPlugin(router) {
  return {
    onTransitionError: (toState) => {
      // TODO handle that.
      
      if (toState === null) {
         toState = {name: 'Page Not Found'};
      } 

      // console.log("yes?")

      rootStore.routerStore.setCurrent(toState);
      
    },
    onTransitionSuccess: (toState) => {
      // console.log(rootStore);

      const {routerStore} = rootStore;

      
      // if (toState.params && toState.params.category) {
      //   rootStore.siteDataStore.fetchCategoryProducts(toState.params.category)
      // }


      routerStore.setCurrent(toState);
      sendAnalyticsPageView(toState);

    }
  };
}