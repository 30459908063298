import React from 'react'

const GridItemTopLabel = (props) => {
	return (
		<div>
			<h6 className="top-label text-xs plex-mono">{props.children}</h6>
			
			<style jsx>{`
				div {
					padding: 8px;
				}
				h6 {
					text-align: center;
				}
			`}</style>
		</div>
	)
}

export default GridItemTopLabel