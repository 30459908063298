import React from 'react'
import {
	useStore
} from '../../hooks/useStore.js'

const Link = (props) => {
	const {to, params, options, external, isPreview} = props;
	
	const {routerStore} = useStore();
	// console.log(routerStore, routerStore.router)
	// return null
	if (isPreview) return props.children;

	const href = props.external ? to : routerStore.router.buildPath(to, params);
	if (href === null) {
		console.error("<Link> Couldn't make URL for", to, params);
	}
	const navigate = (e) => {
		e.preventDefault()
		routerStore.navigate(e, to, params, options)
	}

	if (external) return <a href={href} target="_blank" rel="noreferrer" className={props.className}>{props.children}</a>
	return (
		<a 
			href={href} 
			className={props.className} 
			onClick={navigate}>
			{props.children}
		</a>
	)
}

export default Link
