import { action, computed, observable } from 'mobx';

import {
	convertElapsedTime, shuffleArr
} from '../utils'

import debounce from 'lodash/debounce'

var tinycolor = require("tinycolor2");

// window.tinycolor = tinycolor;

class UIStore {
	constructor(rootStore) {
		this.rootStore = rootStore;

		setInterval(this.setCurrentTime, 500);

		window.addEventListener("resize", debounce(this.handleResize, 300))
	}
	
	@observable windowHeight = window.innerHeight;
	@observable windowWidth = window.innerWidth;
	
	// @observable songTime = null;
	// @observable songPct = null;

	@observable currentTime = new Date();
	@observable weather = null;
	@observable uiColor = "rgb(255,255,255)";
	@observable uiSecondaryColor = null;
	@observable uiBandColor = null;

	@observable uiColorLoc = {x:200, y:200};
	@observable interactiveColor = "#FF7A5E";


	@observable home__introSequenceS3 = false;
	@observable home__introSequenceS4 = false;
	@observable home__introSequenceS5 = false;

	@observable home__captchaImages = []
	@observable home__captchaReady = false;
	@observable home__captchaError = false;
	home__captchaBackgroundAudioComponent = null;
	audioPlaying = false;
	@observable home__audioMuted = false;

	@observable workList__activeTags = {};
	@observable workList__mouseMoved = false;

	@observable workView__activeSection = null;

	@observable workView__activeSlide = 0;
	@observable workView__activeSlide_delay = 0;
	@observable workView__activeSlideOffset = 0;
	@observable workView__carouselTransition = false;
	@observable workView__carouselTransitionDir = -1;


	@observable nav__openWorkDetails = false;

	styles = {
		colors: {
			black: "#303030",
			// darkGray: "#888",
			// lightGray: "#f2f2f2",
			// lightGray: "rgba(220,220,220, .5)",
			lightGray: "rgba(235,235,235, .7)",
			darkGray: "rgba(170,170,170, .7)",
			darkTextGray: "rgba(100,100,100, .7)",
			yellow: "#F9F5A2",

			avatar: [
				"#FFD17F",
				"#C7F6FF",
				"#E4B6B6",
				"#CAF74C",
				"#E0BFF4",
				"#A07777",
				"#FFFC9A",
				// "#DBDBDB"
				"#f2f2f2"
			]
		}

	}

	@computed get NYTime() {
		const {currentTime} = this;

		var usaTime = currentTime.toLocaleString("en-US", {timeZone: "America/New_York"});
		usaTime = new Date(usaTime);

		const hours = usaTime.getHours();
		const postFix = hours >= 12 ? "p" : "a";
		const h = hours > 12 ? hours - 12 : hours;
		const m = usaTime.getMinutes();
		const hrString =  h >= 10 ? `${h}` : `0${h}`;
		const minString = m < 10 ? `0${m}` : `${m}`;


		return {hours: hrString, minutes: minString, postFix, hReal: hours}
	}


	@action.bound registerBackgroundAudio(audio) {
		this.home__captchaBackgroundAudioComponent = audio;
	}

	@action.bound verifyCaptcha() {
		// let 
		for (var i = 0; i < this.home__captchaImages.length; i++) {
			const captchaImage = this.home__captchaImages[i];

			if ((captchaImage.active && captchaImage.type !== "map") ||
				(captchaImage.type === "map" && !captchaImage.active)) {
				 
				this.resetWrongCaptchaImages() 
				this.showCaptchaError();
				// alert("error")
				return false
			}
		}
		

		// alert("verified")
		

		if (!this.audioPlaying) {
			this.audioPlaying = true;

			this.home__captchaBackgroundAudioComponent.play();

			// setTimeout(() => {
			// 	this.playCaptionAndAudio();
			// }, 5100)
		}

		// if (this.projectIdx+1 < this.siteData.projects.length) {
		// 	this.setCaptchaImages()
		// } else {
			this.resetAllCaptchaImages() 
		// }

	}

	@action resetWrongCaptchaImages() {
		for (var i = 0; i < this.home__captchaImages.length; i++) {
			if (this.home__captchaImages[i].type !== "map") {
				this.home__captchaImages[i].active = false;
			}
		}
	}

	@action resetAllCaptchaImages() {
		for (var i = 0; i < this.home__captchaImages.length; i++) {
			this.home__captchaImages[i].active = false;
		}
	}

	@action.bound showCaptchaError() {
		this.home__captchaError = true;

		setTimeout(this.removeCaptchaError, 1100)
	}

	@action.bound removeCaptchaError() {
		this.home__captchaError = false;
	}

	@action.bound toggleCaptchaImage(idx) {
		this.home__captchaImages[idx].active = !this.home__captchaImages[idx].active;
		// console.log("toggled", this.home__captchaImages[idx].active)
	}

	@action.bound setCaptchaImages() {
		// if (this.atEndOfCaptcha) {
		// 	this.setEndCaptchaImages()
		// 	return;
		// }

		const moodImages = this.rootStore.contentfulStore.data.captcha.mood;
		const mapImages = this.rootStore.contentfulStore.data.captcha.maps;
		const num = 6;
		const moodImageIndexes = shuffleArr(moodImages.map((item, i) => i));
		const mapImageIndexes = shuffleArr(mapImages.map((item, i) => i));
		
		var existsAtLeastOneMap = false;
		let moodIndexNum = 0;
		let mapIndexNum = 0;

		for (var i = 0 ; i < num; i++) {
			const obj = {};
			obj.active = false;

			if (Math.random() < .25) {
				existsAtLeastOneMap = true;
				obj.type = "map";
				const image = mapImages[mapImageIndexes[mapIndexNum]];
				// const image = this.mapImages[Math.floor(this.mapImages.length * Math.random())]
				obj.image = image;
				mapIndexNum++;
			} else {
				obj.type = "mood";
				const image = moodImages[moodImageIndexes[moodIndexNum]]
				obj.image = image;
				moodIndexNum++;
			}

			this.home__captchaImages[i] = obj;
		};

		

		if (!existsAtLeastOneMap) {
			const obj = {};
			obj.active = false;
			obj.type = "map";
			const image = mapImages[Math.floor(mapImages.length * Math.random())]
			obj.image = image

			this.home__captchaImages[Math.floor(Math.random() * num)] = obj;
		};

		this.home__captchaReady = true
	}


	@action.bound toggleMute() {

		this.home__audioMuted = !this.home__audioMuted;
		// this.clickedMuted = !this.clickedMuted;

		if (this.home__captchaBackgroundAudioComponent) {
			if (this.home__audioMuted) {
				this.home__captchaBackgroundAudioComponent.mute()
				// this.home__captchaBackgroundAudioComponent.muted = true
			} else {
				this.home__captchaBackgroundAudioComponent.unmute()

				// this.home__captchaBackgroundAudioComponent.muted = false;
			}
		}

	}


	@computed get waypointOffset() {

		return (this.windowHeight / 2) - 1;

	}

	// @computed get imageRatioMultiplier() {
		
	// 	const total = this.windowWidth - 220;
	// 	const width = total - 400;
	// 	return width / total;

	// }
	
	@computed get workViewFlexWidth() {
		return this.windowWidth - 220 - 400;
	}

	@computed get slideOffsets() {
		const pageData = this.rootStore.contentfulStore.pageData;
		if (!pageData) return []
		// console.log(pageData);
		let total = 0;
		let slides = {0:0};
		const blocks = pageData.fields.contentBlocks;
		const offset = 2;
		blocks.forEach((b, idx) => {



			// if (idx > offset && idx < blocks.length - (offset - 1)) {
			if (idx > offset && idx < blocks.length - (offset + 2)) {
				// let block = blocks[idx - (offset + 1)];
				// const {type} = block.fields;

				// if (idx === offset + 1) {
				// 	// total -= 14;
				// 	// total -= 4;
				// } else {
				// 	// total -= 4
				// }

				total -= 30;

				// if (type === "Headline" || type === "Text-Image" || type === "Text-Video") {
				// 	// total -= 10
				// } else {
				// 	// total -= 24
				// 	// total -= 20;
				// }
			}

			slides[idx + 1] = total;
		})

		return slides;
	}

    @action.bound handleResize() {
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
    }

	@action.bound setCurrentTime() {
		this.currentTime = new Date();
	}

	@action.bound setUIColor(color) {
		this.uiColor = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
		const secondary = tinycolor(this.uiColor).lighten(11)

		const band = tinycolor(this.uiColor).lighten(30).tetrad()[3];
		// const band = tinycolor(this.uiColor).lighten(30).complement();
		this.uiSecondaryColor = secondary;
		this.uiBandColor = band;

		// console.log(tinycolor(this.uiColor));
	}

	@action.bound resetUIColor() {
		this.uiColor = "rgb(255,255,255)";
		this.uiSecondaryColor = this.styles.lightGray;
		this.uiBandColor = this.styles.yellow;
	}

	@action.bound setUIColorLoc(x, y) {
		this.uiColorLoc = {x,y};
	}

	@action.bound updateWorkViewActiveSection(section) {
		this.workView__activeSection = section;
	}

	@action.bound updateActiveSlideOffset(pct) {
		this.workView__activeSlideOffset = pct;
	}

	@computed get workViewSlideAtBeginning() {
		return this.workView__activeSlide === 0
	}

	@computed get workViewSlideAtEnd() {
		return this.workView__activeSlide === this.rootStore.contentfulStore.mobileCarouselSlides.length - 1
	}
	@action.bound decrementWorkViewSlide(fromSwipe = false) {
		if (this.workViewSlideAtBeginning) return
		if (fromSwipe && this.workView__activeSlideOffset > 0) return

		this.workView__carouselTransition = true;
		this.workView__carouselTransitionDir = -1;


		this.workView__activeSlide--;

		setTimeout(() => {
			this.workView__activeSlide_delay = this.workView__activeSlide
			this.workView__carouselTransition = false;
		}, 230);

		// const type = this.rootStore.contentfulStore.pageData.fields.contentBlocks[this.workView__activeSlide].fields.type

		
		// this.workView__activeSlideOffset = 0;

		// if (type  === "Image" || type === "Image-Full" || type === "Video") {
		// 	// this.workView__activeSlideOffset = 0.5;
		// 	this.workView__activeSlideOffset = 0;
		// } else {
		// 	this.workView__activeSlideOffset = 0;
		// }
	}



	@action.bound incrementWorkViewSlide(fromSwipe = false) {
		if (this.workViewSlideAtEnd) return

		if (fromSwipe && this.workView__activeSlideOffset !== 0 &&  this.workView__activeSlideOffset < 1) return

		// console.log("offset", this.workView__activeSlideOffset);
		
		this.workView__carouselTransition = true;
		this.workView__carouselTransitionDir = 1;


		this.workView__activeSlide++;

		setTimeout(() => {
			this.workView__activeSlide_delay = this.workView__activeSlide
			this.workView__carouselTransition = false;

		}, 230);

		// const type = this.rootStore.contentfulStore.pageData.fields.contentBlocks[this.workView__activeSlide].fields.type

		// this.workView__activeSlideOffset = 0;

		// if (type  === "Image" || type === "Image-Full" || type === "Video") {
		// 	this.workView__activeSlideOffset = 0;
		// } else {
		// 	this.workView__activeSlideOffset = 0;
		// }
	}

	@action.bound jumpToWorkViewSlide(idx) {
		if (
			idx > this.rootStore.contentfulStore.pageData.fields.contentBlocks.length - 1 ||
		 	idx < 0 || 
		 	idx === this.workView__activeSlide
		 ) return

		this.workView__carouselTransition = true;
		this.workView__carouselTransitionDir = idx > this.workView__activeSlide ?  1 : -1;


		this.workView__activeSlide = idx;

		setTimeout(() => {
			this.workView__activeSlide_delay = this.workView__activeSlide
			this.workView__carouselTransition = false;

		}, 230);

		const type = this.rootStore.contentfulStore.pageData.fields.contentBlocks[this.workView__activeSlide].fields.type

		this.workView__activeSlideOffset = 0;
		
	}
	
	@action.bound toggleWorkDetails() {
		this.nav__openWorkDetails = !this.nav__openWorkDetails;
	}

	@action.bound async getWeather() {
		const apiKey = "dadf9ef23d74cd4daca644d3e981cb33";
		const city = "New York City";
		const url = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=imperial`;
		const weather = await fetch(url);
		const data = await weather.json();

		this.weather = {
			temp: Math.round(data.main.temp),
			conditions: data.weather[0].main
		}
	}


	@action.bound activateHomeIntroStep3(skipAnimation) {
		this.home__introSequenceS3 = true;

		setTimeout(this.activateHomeIntroStep4, skipAnimation ? 0 : 800)
	}

	@action.bound activateHomeIntroStep4(skipAnimation) {
		this.home__introSequenceS4 = true;
		setTimeout(this.activateHomeIntroStep5, skipAnimation ? 0 : 90)
	}

	@action.bound activateHomeIntroStep5() {
		this.home__introSequenceS5 = true;
	}

	@action.bound clearRouteVariables() {
		this.home__introSequenceS3workView__activeSection = null;

		this.workView__activeSlide = 0;
		this.workView__activeSlide_delay = 0;
		this.workView__activeSlideOffset = 0;
		this.workView__carouselTransition = false;
		this.workView__carouselTransitionDir = -1;

		this.audioPlaying = false;


	}

	@action.bound setupWorkListTags() {
		const workList = this.rootStore.contentfulStore.data.workList;

		workList.forEach(item => {
			if (item.fields.tags) {
				item.fields.tags.forEach(tag => {
					if (!this.workList__activeTags[tag.fields.name]) {
						this.workList__activeTags[tag.fields.name] = true
					} 
				})
			}
		});
		// console.log()
	}

	@action.bound toggleWorkListTag(tag) {
		this.workList__activeTags[tag] = !this.workList__activeTags[tag]
	};

	@action.bound handleMouseMoveWorkList() {
		if (this.mouseMoveTimout) {
			clearTimeout(this.mouseMoveTimout)
		}

		this.workList__mouseMoved = true;

		this.mouseMoveTimout = setTimeout(this.stopMouseMoveWorkList, 800)
	}

	@action.bound stopMouseMoveWorkList() {
		this.workList__mouseMoved = false;
	}

	scrollToTop() {
		window.scrollTo(0, 0);
	}


	scrollToID(id) {
		document.getElementById(id).scrollIntoView({ 
		  behavior: 'smooth'
		});
	}


}

export default UIStore