import React from 'react'
import {observer} from 'mobx-react'

import {
	useStore
} from '../../hooks/useStore'


import {
	characterImageUrls
} from '../../utils/character-image-urls'

// <img src={`/assets/images/character-pngs/b${b}_e${e}_ha${ha}_he${he}.png`} alt=""/>

const AvatarPic = observer((props) => {
	const {uiStore: {styles: {colors: {avatar}}}} = useStore()
	if (!props.params) return null

	const {params: {b,e,ha,he, color}} = props;
	const imageSrc = props.src || characterImageUrls[`b${b}_e${e}_ha${ha}_he${he}`]
	return (
		<div className="avatar-section flex--center">
			<img src={`${imageSrc}?w=${props.size * 2}&fm=webp&q=${95}`} alt=""/>
			

			<style jsx>{`
				.avatar-section {
					overflow: hidden;
					height: ${props.size}px;
					width: ${props.size}px;
					border-radius: 50%;
					margin: 0 ${props.margin ? props.margin : 0}px;
					background: ${avatar[color]};
					
				}

				img {
					width: ${props.size*1.18}px;
					height: ${props.size*1.18}px;
					max-width: none;
					max-height: none;
					position: relative;
					top: 8%;
				
					// height: 120%;
					// object-fit: cover;
				}
			`}</style>	
		</div>
	)
})

export default AvatarPic