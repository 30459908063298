import React from 'react'

class Button extends React.PureComponent {
	state = {
		clicked: false
	}


	_handleMouseDown = (event) => {
		const surface = this.surface;

		// this.setState({
		// 	clicked: true;
		// })

		const {left, top} = surface.getBoundingClientRect();

		let ink = this.ink;
		ink.classList.remove('in')
		ink.classList.remove('out')
		
		// surface.clientHeight
		let d = Math.max(surface.clientHeight, surface.clientWidth)
		// console.log(height, width,  surface.clientHeight, surface.clientWidth)
		ink.style.height = d + 'px'
		ink.style.width = d + 'px'
		
		let x = event.clientX - left - (d / 2)
		let y = event.clientY - top - (d / 2)
		 // x = event.clientX - left; //x position within the element.
		 // y = event.clientY - top;  //y position within the element.
		// console.log("Left? : " + x + " ; Top? : " + y + ".");
		
		// x = 10 - (d / 2);
		// y = 20 - (d / 2);
		ink.style.top = y + 'px'
		ink.style.left = x + 'px'
		ink.classList.add('in')
		// if (!surface.querySelector('.material-ink')) {
			// let ink = document.createElement('div')
			// ink.classList.add('material-ink')
			// surface.insertBefore(ink, surface.firstChild)
		// }
	}


	_handleMouseUp = () => {
	 	this.ink.classList.add('out')	
	}


	_handleClick = () => {
		if (this.props.onClick) {
			this.props.onClick()
		}
	}

	render() {
		return (
			<div 
				ref={el => this.surface = el}
				className="btn text-s" 
				onClick={this._handleClick} 
				onMouseDown={this._handleMouseDown}
				onMouseUp={this._handleMouseUp}
				onMouseOut={this._handleMouseUp}>
				

				<div ref={el => this.ink = el} className="ripple out"></div>
				 
				 <div className="inner flex--center">
				 <span>{this.props.children}</span>

				 </div>
				
				

				<style jsx>{`
					span {
						position: relative;
						z-index: 10;
						line-height: 0;
						color: ${this.props.color || "#303030"};
					}

					.inner {
						width: 100%;
						height: 100%;
					}
					
					.btn {
						display: inline-block;

						width: ${this.props.width || "200px"};
						height: 40px;
						// background: #FFE800;
						background: ${this.props.background || "rgba(235,235,235, .7)"};
						border-radius: 20px;
						color: white;
						// box-shadow: inset 0 0 5px rgba(0,0,0,.8);
						// border: 0px solid ${this.props.backgroundDark || "rgba(170,170,170, .7)"};
						transition: all .15s ease;
					}

					.btn:hover {
						background: ${this.props.background || "rgba(210,210,210, .5)"};
					}


					.btn {
						position: relative;
						overflow: hidden;
						cursor: pointer;
						user-select: none;
					}

					.ripple {
						transition: opacity 0.45s ease;
						position: absolute;
						background: ${this.props.backgroundDark || "rgba(170,170,170, .7)"};
						border-radius: 50%;
						opacity: 1;
						pointer-events: none;
					}

					.ripple.in {
						animation: in 0.55s forwards;
					}

					.ripple.out {
					  animation: in 0.65s forwards, out 0.65s forwards;
					}

					@keyframes in {
						0% {
						  opacity: 0.25;
					    transform: scale(0);
						}
					  
					  100% {
					    transform: scale(2.5);
					  }
					}

					@keyframes out {
					  0% {
					    opacity: 0.25;
					  }
					  
					  100% {
					    opacity: 0;
					  }
					}


				`}</style>
			</div>
		)	
	}

}


// const element = document.querySelector('.material-ripple')

// element.addEventListener('mousedown', event => {

//   }
  
//   let ink = surface.querySelector('.material-ink')
//   ink.classList.remove('in')
//   ink.classList.remove('out')
  
//   let d = Math.max(surface.clientHeight, surface.clientWidth)
//   ink.style.height = d + 'px'
//   ink.style.width = d + 'px'
  
//   let x = event.pageX - surface.offsetLeft - (d / 2)
//   let y = event.pageY - surface.offsetTop - (d / 2)
  
//   ink.style.top = y + 'px'
//   ink.style.left = x + 'px'
//   ink.classList.add('in')
// })

// element.addEventListener('mouseup', event => {
// 	const surface = element
//   let ink = surface.querySelector('.material-ink')
//   ink.classList.add('out')
// })




export default Button