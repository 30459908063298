import React from 'react'
import {observer} from 'mobx-react'

import {
	useStore
} from '../../../../hooks/useStore'

import {
	WorkListItem
} from '../../worklist'

const OtherProjects = observer((props) => {
	const {contentfulStore: {pageData}} = useStore();

	const otherProjects = pageData.fields.otherProjects;
	if (!otherProjects) return null;

	return (
		<div className="other-projects">
			<div className="heading">
				<h4 className="text-m helvetica">Similar Projects:</h4>
			</div>

			{otherProjects.map((item, idx) => {
				return <WorkListItem key={`w-l-${idx}`} data={item}/>
			})}
			<style jsx>{`
				.other-projects {
					background: #fff;
					padding: 100px 0;
				}

				.heading {
					// text-align: center;
					padding: 0px 73px;
				}

				p {
					margin: 8px 0;
				}
			`}</style>
		</div>	
	)
})

export default OtherProjects