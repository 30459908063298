import React from 'react';
import ReactDOM from 'react-dom';

// import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import {toJS} from 'mobx';
import 'mobx-react-lite/batchingForReactDom'
import rootStore from './stores/RootStore'

window.toJS = toJS;
rootStore.routerStore.router.start();

// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update')
//     whyDidYouUpdate(React)
// }

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }



// const ToRender = () => {
// 	rootStore.routerStore.router.start();
// 	return (
// 		<Provider store={rootStore}>
// 			<App />
// 		</Provider>
// 	)
// }


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
