import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react'

import {
	useStore
} from '../../../../hooks/useStore'

import {
	Tag
} from '../../../tag'

import {
	ViewProjectLink
} from '../../../nav/subcomponents'


import {
	getImageURL
} from '../../../../utils'


import get from 'lodash/get'
import { Flipper, Flipped } from 'react-flip-toolkit'
import { Image } from '../../../general/assets'



// credit: http://www.javascriptkit.com/javatutors/touchevents2.shtml
function swipedetect(el, callback){
  
    var touchsurface = el,
    swipedir,
    startX,
    startY,
    distX,
    distY,
    threshold = 60, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 175, // maximum time allowed to travel that distance
    elapsedTime,
    startTime,
    handleswipe = callback || function(swipedir){}
  
    touchsurface.addEventListener('touchstart', function(e){
        var touchobj = e.changedTouches[0]
        swipedir = 'none'
        distX = 0;
		distY = 0;
        startX = touchobj.pageX
        startY = touchobj.pageY
        startTime = new Date().getTime() // record time when finger first makes contact with surface
        // e.preventDefault()
    }, false)
  
    touchsurface.addEventListener('touchmove', function(e){
        // var touchobj = e.touches[0];
		// let x = touchobj.pageX
		// if (x > startX)  {
		// 	// e.preventDefault() // prevent scrolling when inside DIV
		// }

        // e.preventDefault() // prevent scrolling when inside DIV
    }, false)
  
    touchsurface.addEventListener('touchend', function(e){
        var touchobj = e.changedTouches[0]
        distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
        distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
        elapsedTime = new Date().getTime() - startTime // get time elapsed
        if (elapsedTime <= allowedTime){ // first condition for awipe met
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
                swipedir = (distX < 0)? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
            }
            else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
                swipedir = (distY < 0)? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
            }
        }
        handleswipe(swipedir)
        // e.preventDefault()
    }, false)
}
  
//USAGE:

const WorkViewCarousel = (props) => {	
	const {contentfulStore: {pageData}, uiStore: {incrementWorkViewSlide, decrementWorkViewSlide}} = useStore();

	useEffect(() => {

		var el = document.getElementById('swipezone');
		swipedetect(el, function(swipedir){
			// swipedir contains either "none", "left", "right", "top", or "down"
			if (swipedir === "left") {

				incrementWorkViewSlide(true)
				// alert("left")
			} else if (swipedir === "right") {
				decrementWorkViewSlide(true)
			}
		});
	});


	return (
		<div id="swipezone">
			<CarouselControls/>
			<CarouselSlide/>
			<CarouselSectionTags/>

			<style jsx>{`
				div {
					overflow: hidden;
					position: fixed;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					background: #f8f8f8;

				}
			`}</style>
		</div>
	)
}

const CarouselSectionTags = observer((props) => {
	const {contentfulStore: {pageData}, uiStore: {jumpToWorkViewSlide, workView__activeSlide}} = useStore();
	const content = get (pageData, "fields.content", []);

	return (
		<div className="work__tags flex--center-y">
			<ViewProjectLink/>

			<div className="work__tags__inner">
				{content.map((section, idx) => {
					const category = section.fields.category;
					return (
						<div className="tag-section flex--center-y">
							<Tag onClick={() => jumpToWorkViewSlide(idx)} active={workView__activeSlide === idx}>{category}</Tag>
						</div>
					)
				})}
			</div>

			<style jsx>{`

				.work__tags {
					position: fixed;
					bottom: 75px;
					left: 0;
					width: 100%;
					overflow: scroll;
				}

				.work__tags :global(.tag, .view-project-link) {
					backdrop-filter: blur(8px);
				}

				.work__tags__inner {
					white-space: nowrap;
					padding: 0 8px;
				}

				.tag-section {
					margin: 0 12px;
					display: inline-block;
				}

			`}</style>
		</div>
	)	
})




const CarouselTrackerAlt = observer((props) => {
	const {contentfulStore: {mobileCarouselSlides}, uiStore: {workView__activeSlide, styles, slideOffsets}} = useStore();

	const blocks = mobileCarouselSlides;
	let transform = slideOffsets[workView__activeSlide];

	const style = {transform: `translate3d(${transform}px, 0, 0)`};

	return (
		<div className="carousel-tracker">
			<Flipper 
				flipKey={workView__activeSlide}>

				<div style={style} className="carousel__inner flex--center-y">
					{blocks.map((block, idx) => {
						return (
							<div className="dot__wrapper flex--center">
								<DotTrackerAlt idx={idx} type={block.fields.type} active={idx === workView__activeSlide} end={idx === (workView__activeSlide + 2) || idx === (workView__activeSlide - 2)} farEnd={idx > (workView__activeSlide + 2) || idx < (workView__activeSlide - 2)}/>
							</div>
						)
					})}
				</div>

			</Flipper>
			<style jsx>{`
				.carousel-tracker {
					background: ${styles.colors.lightGray};
					padding: 9px 0px;
					border-radius: 20px;
					backdrop-filter: blur(10px);
					// width: 168px;
					width: 210px;
					overflow: hidden;
				}

				.dot__wrapper {
					width: 30px;
					// margin: 0 2px;

				}

				.carousel__inner {
					width: 600px;
					transition: transform .2s ease-out;
				}
			`}</style>
		</div>
	)
})

const DotTrackerAlt = observer((props) => {
	const {active, type} = props;
	// const {uiStore: {styles}} = useStore();
				// if (type === "Headline" || ) {

	// const offset = 
	return (
		<Flipped flipId={"sq" + props.idx}>
			<div className={`dot-tracker ${props.type === "Intro" || props.type === "Headline" || type === "Text-Image" || type === "Text-Video" ? "" : "long"} ${props.end ? "end" : ""} ${props.farEnd ? "far-end" : ""}`}>
			{active ? <ActiveMarker/> : null}

			<style jsx>{`
				.dot-tracker {
					height: 12px;
					width: 12px;
					background: rgb(220,220,220);
					border-radius: 50%;
					position: relative;
					overflow:hidden;
				}

				.long {
					width: 26px;
					border-radius: 6px;
				}


				.end {
					width: 6px;
					height: 6px;
					border-radius: 50%;
				}

				.far-end {
					width: 3px;
					height: 3px;
				}

			`}</style>
		</div>
		</Flipped>

	)
})


const ActiveMarker = observer((props) => {
	const {uiStore: {workView__activeSlideOffset}} = useStore();

	return (
		<div style={{transform: `translateX(${workView__activeSlideOffset * 14}px)`}}className="active-marker">
		<style jsx>{`
			.active-marker {
				position: absolute;
				top: 0;
				left: 0;
				height: 12px;
				width: 12px;
				// background: rgb(170,170,170);
				background: rgb(120, 120, 120);
				// background: red;
				border-radius: 50%;
			}
		`}</style>
		</div>
	)
})


const CarouselControls = observer((props) => {
	const {uiStore: {workViewSlideAtBeginning, workViewSlideAtEnd, incrementWorkViewSlide, decrementWorkViewSlide}} = useStore();

	return (
		<div className="controls flex--space-between">
			<div className={`${workViewSlideAtBeginning ? "inactive" : ""} btn`} onClick={decrementWorkViewSlide}>
				<img  src="/assets/images/arrow-l.svg"  alt=""/>
			</div>

			
			<CarouselTrackerAlt/>
			<div className={`${workViewSlideAtEnd ? "inactive" : ""} btn`}  onClick={incrementWorkViewSlide}>
				<img  src="/assets/images/arrow-r.svg"  alt=""/>
			</div>

			

			<style jsx>{`
				.controls {
					top: 0;
					left: 0;
					position: fixed;
					width: 100%;
					padding: 12px;
					z-index: 10;
				}

				.btn {
					display: inline-block;
					touch-action: manipulation;
					height: 30px;
					width: 30px;
					border-radius: 50%;
					transition: opacity .2s ease;
				}

				.inactive {
					opacity: 0;
					pointer-events: none;
				}
			`}</style>
		</div>
	)
});

const TextData = observer((props) => {
	const {contentfulStore: {pageData, mobileCarouselSlides}, uiStore: {workView__activeSlide_delay}} = useStore();
	const slide = mobileCarouselSlides[workView__activeSlide_delay];
	const {text, type} = slide.fields;

	if (!text || type === "Headline" || type === "Intro") return null

	const isRedacted = get(pageData, "fields.isRedacted")

	return (
		<p className="text-s">
			{isRedacted ? <mark>{text}</mark> : text}

			<style jsx>{`
				p {
					padding: 0 12px;
					margin-bottom: 160px;
				}
			`}</style>
		</p>
	)
})



const CarouselSlide = observer((props) => {
	const {uiStore: {workView__carouselTransition, workView__carouselTransitionDir}} = useStore();

	return (
		<div className={`slide ${workView__carouselTransitionDir === -1 ? "l" : "r"} ${workView__carouselTransition ? "tr" : ""}`}>
			<SlideContents/>
			<TextData/>
			<style jsx>{`
				.slide {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					overflow: scroll;
					-webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
				}

				.slide.r {
					opacity: 0;
					animation: slide-in .3s ease .3s;
					animation-fill-mode: forwards;
				}

				.slide.tr.r {
					animation: slide-out-left .23s ease-out 0s;
					animation-fill-mode: forwards;
				}

				.slide.l {
					opacity: 0;
					animation: slide-in .3s ease .3s;
					animation-fill-mode: forwards;
				}

				.slide.tr.l {
					animation: slide-out-right .23s ease-out 0s;
					animation-fill-mode: forwards;

				}


				@keyframes slide-out-left {
					from {
						opacity: 1
						transform: translateX(0);
					}
					to {
						opacity: 0;
						transform: translateX(-30px);
					}
				}

				@keyframes slide-in-left {
					from {
						opacity: 0;
						transform: translateX(20px);
					}

					to {
						opacity: 1;
						transform: translateX(0px);
					}
				}

				@keyframes slide-in {
					from {
						opacity: 0;
					} 

					to {
						opacity: 1;
					}
				}

				@keyframes slide-out-right {
					from {
						opacity: 1
						transform: translateX(0);
					}
					to {
						opacity: 0;
						transform: translateX(30px);
					}
				}

				@keyframes slide-in-right {
					from {
						opacity: 0;
						transform: translateX(-20px);
					}

					to {
						opacity: 1;
						transform: translateX(0px);
					}
				}
			`}</style>
		</div>

	)
})

const Tagline = (props) => {
	const tagline = get(props.data, "fields.tagline.content[0].content", []);	
	const text = [];

	tagline.forEach((node, idx) => {

		if (!node.marks[0]) {
			text.push(<span key={`${node.value}-${idx}`}>{node.value}</span>)
		} else {
			text.push(<span className="hl" key={`${node.value}-${idx}`}>{node.value}</span>)
		}
	})

	return (
		<p className="text-l helvetica">
			{text}

			<style jsx>{`
				p {
					margin: 8px 0 20px;
					text-align: center;
				}

			`}</style>
		</p>
	)
}


const SlideContents = observer((props) => {
	const {contentfulStore: {pageData, mobileCarouselSlides}, uiStore: {updateActiveSlideOffset, workView__activeSlide_delay}} = useStore();

	const data = mobileCarouselSlides[workView__activeSlide_delay];
	const prefix = get(pageData, "fields.prefix", "");
	const {media, text, type, posterImage} = data.fields;

	useEffect(() => {
		updateActiveSlideOffset(0)
	});

	let content;

	switch (type) {
		case "Intro":
			content = (	
				<SquareContainer key={`wv-sl-${workView__activeSlide_delay}`}>
					<Image inView={true} width={800} q={90} image={media}/>
					<Tagline data={data}/>
				</SquareContainer>
			);
			break;
		case "Headline":
			content = (
				<TextContainer key={`wv-sl-${workView__activeSlide_delay}`}>
					<h1 className="text-xl">{text}</h1>
				</TextContainer>
			)
			
			break;
		case "Text-Image":
			content = (
				<SquareContainer key={`wv-sl-${workView__activeSlide_delay}`}>
					<Image inView={true} width={800} q={90} image={media}/>
					<Caption prefix={prefix} media={media} idx={workView__activeSlide_delay} />
				</SquareContainer>
			)


			// content = <BlockTextImage text={props.data.fields.text} image={props.data.fields.media.fields.file.url} {...props}/>
			break;
		case "Text-Video":
			content = (
				<SquareContainer key={`wv-sl-${workView__activeSlide_delay}`}>
					<video src={media.fields.file.url} muted autoPlay playsInline loop/>
					<Caption prefix={prefix} media={media} idx={workView__activeSlide_delay} />
				</SquareContainer>
			)
			// content = <BlockTextVideo text={props.data.fields.text} video={props.data.fields.media.fields.file.url} {...props}/>
			break;
		case "Image":
			content = (
				<DraggableContainer key={`wv-sl-${workView__activeSlide_delay}`}  media={posterImage || media}>
					<DraggableImage className="draggable" inView={true} width={800} q={90} image={media}/>

					{/* <img className="draggable" src={getImageURL(media, 1800, "webp", 85)} alt="" /> */}
					<Caption prefix={prefix} media={media} idx={workView__activeSlide_delay} />
				</DraggableContainer>
			)
			break;
		case "Video":

			content = (
				<DraggableContainer key={`wv-sl-${workView__activeSlide_delay}`} media={posterImage || media}>
					<video className="draggable" src={media.fields.file.url} muted autoPlay playsInline loop/>
					<Caption prefix={prefix} media={media} idx={workView__activeSlide_delay} />
				</DraggableContainer>
			)

			// content = <BlockVideo video={props.data.fields.media.fields.file.url} {...props}/>
			break;
		case "Image-Full":
			// padding = 0;
			content = (
				<DraggableContainer key={`wv-sl-${workView__activeSlide_delay}`} media={posterImage || media}>
					<DraggableImage className="draggable" inView={true} width={800} q={90} image={media}/>

					{/* <img className="draggable" src={getImageURL(media, 1800, "webp", 85)} alt="" /> */}
					<Caption prefix={prefix} media={media} idx={workView__activeSlide_delay} />
				</DraggableContainer>
			)

			// content = <BlockImage full image={props.data.fields.media.fields.file.url}  {...props}/>
			break;
		case "Video-Full":
			content = (
				<DraggableContainer key={`wv-sl-${workView__activeSlide_delay}`} media={posterImage || media}>
					<video className="draggable" src={media.fields.file.url} muted autoPlay playsInline loop/>
					<Caption prefix={prefix} media={media} idx={workView__activeSlide_delay} />
				</DraggableContainer>
			)
			// padding = 0;
			// content = <BlockVideo full video={props.data.fields.media.fields.file.url} {...props}/>
			break;
		default:
			return <h1>Content Missing Type</h1>
	}

	return content
})


const DraggableImage = observer((props) => {
	const {contentfulStore: {pageData}, uiStore: {windowHeight}} = useStore();
    const [isLoaded, setLoad] = useState(false);

    const {width, height} = props.image.fields.file.details.image;
    const aspectRatio = width / height;

	const hasURl = !!get(pageData, "fields.url");
	const containerHeight = hasURl ? windowHeight - 182 : windowHeight - 145;
	const containerWidth = aspectRatio * containerHeight;
	
    return (
        <div> 
            {props.inView ?

                <img 
                    src={getImageURL(props.image, props.width, "webp", props.q)} 
                    className={`${props.className || ""} ${isLoaded ? "loaded" : ""}`} 
                    onLoad={() => setLoad(true)}
                    alt=""/>
                :
                null
                
            }

            <style jsx>{`

                div {
                    height: 0;
                    background: red;
					height: ${containerHeight}px;  // added 27 for caption
					width: ${containerWidth}px;  // added 27 for caption
					border-radius: 12px;
                    background: rgba(235,235,235, .7);
					margin: 0 12px;
					display: inline-block;
                }

                img {
                    opacity: 0;
                }

                .loaded {
                    animation: fade-in 0.5s ease-out;
                    animation-fill-mode: forwards;
                }

                @keyframes fade-in {
                    from {
                        opacity: 0;
                    }

                    to {
                        opacity: 1;
                    }
                }
            `}</style>



        </div>

    )
})



const DraggableContainer = observer((props) => {
	const {contentfulStore: {pageData}, uiStore: {windowHeight, updateActiveSlideOffset}} = useStore();

	const hasURl = !!get(pageData, "fields.url");

	// console.log("workview active", workView__activeSlideOffset)
	const {width, height} = props.media.fields.file.details.image;
    const aspectRatio = height / width;
	const assetHeight = hasURl ? windowHeight - 182 : windowHeight - 145;
	const assetWidth = assetHeight / aspectRatio;

	return (

			<div 
				className="outer"
				onScroll={(e) => {
					e.preventDefault();
					e.stopPropagation();
					const target = e.target;
					var scrollPercentage = target.scrollLeft / (target.scrollWidth - target.clientWidth); 
					updateActiveSlideOffset(scrollPercentage)
				}}>

				<div className="draggable-wrapper">
					{props.children}
				</div>




				<style jsx>{`

					button {
						position: absolute;
						z-index: 100;
						top: 60px;
						right: 20px;
					}

					.outer {
						height: ${windowHeight}px;
						overflow: scroll;
						padding-top: 53px;
						position: relative;
					}

				
					.outer :global(.caption) {
						position: fixed;
						bottom: ${hasURl ? 111 : 74}px; 
						width: 100%;
						padding: 0 14px;
						left: 0;
					}




					.outer :global(video) {
						margin: 0 12px;
					}

					.draggable-wrapper {
						width: ${assetWidth + 24}px;
					}

					.outer :global(.draggable) {
						width: auto;
						height: ${assetHeight}px;  
						max-width: none;
						border-radius: 12px;
						transform: translate3d(0,0,0);
					}


					.outer.fw :global(.draggable) {
						height: auto; 
						width: 100%;
						position: absolute;
						top: 50%;
						transform: translate3d(0,-50%,0) translateY(-80px);
						// transform-origin: 50% 50%;

						margin: 0;
					}

					.outer :global(.caption) {
						white-space: nowrap;
						position: static;
					}

		
				`}</style>
			</div>
	)
});

const Caption = observer((props) => {
	// if (!props.media) return null
	const {contentfulStore: {pageData}} = useStore();
	const isRedacted = get(pageData, "fields.isRedacted")

	const description = get(props.media, "fields.description");
	// if (!description) return null
	// console.log("ok...", props)
	
	return (
		<div className="caption flex--space-between">
			<span className="description" >{isRedacted ? <mark>{description}</mark> : description}</span>
			<span className="label">{props.prefix}-{props.idx < 10 ? "0" + props.idx : props.idx}</span>

			<style jsx>{`
				.caption {
					align-items: start;
				}

				.description {
					flex: 1;
				}	

				.label {
					margin-left: 12px;
				}

				span {
					font-family: "helvetica";
					font-size: 9px;
				}

				div {
					padding: 5px 2px;
				}
			`}</style>
		</div>
	)
})

const SquareContainer = observer((props) => {
	const {uiStore: {windowWidth}} = useStore();

	return (
		<div>
			{props.children}
			<style jsx>{`
				div {
					width: 100%;
					padding: 12px;
					// padding-top: 0;
					padding-top: 53px;
					line-height: 0;
					// height: ${windowWidth * 1.12}px;
					position: relative;
					// border-radius: 15px;
					// overflow: hidden;
				}

				div :global(img, video) {
					// object-fit: cover;
					width: 100%;
					// height: 100%;
					border-radius: 12px;
					max-height: none;
				}
			`}</style>
		</div>
	)
})


const TextContainer = (props) => {
	return (
		<div className="flex--center">
			{props.children}

			<style jsx>{`
				div {
					text-align: center;
					height: 100%;
					padding: 12px;
					// min-height: calc(100vh - 53px);
				}
			`}</style>
		</div>
	)
}
export default WorkViewCarousel