import React from 'react'
import {observer} from 'mobx-react'

import {
	useStore
} from '../../../hooks/useStore'

import {
	Tag
} from '../../tag'


import get from 'lodash/get'

const WorkViewSectionTags = observer((props) => {
	const {contentfulStore: {pageData}, uiStore: {scrollToID, workView__activeSection}} = useStore();
	const content = get (pageData, "fields.contentBlocks", []);
	const prefix = get(pageData, "fields.prefix", "")
	// const sample = content.slice().concat([{fields: {category: "Wrapup"}}, {fields: {category: "Delivery"}}])
	return (
		<div className="work__tags">
			{content.map((section, idx) => {
				if (!section.fields.highlight) return null

				const label = section.fields.highlightLabel;
				idx = idx < 10 ? "0" + idx : idx;
				const id = `${prefix}-${idx}`

				return (
					<div key={`wt-${prefix}-${id}`} className="tag-section flex--center-y">
						<Tag onClick={() => scrollToID(id)} active={workView__activeSection === id}>{label}</Tag>
						<h6 className="text-xs">{id}</h6>
					</div>
				)
			})}

			

			<style jsx>{`

				.work__tags {
					position: fixed;
					top: 24px;
					right: 24px;

				}

				.tag-section {
					margin-bottom: 6px;
					justify-content: flex-end;
				}

				.work__tags :global(.tag) {
					margin-right: 4px;
					cursor: pointer;
					backdrop-filter: blur(5px);
				}

				.work__tags :global(.tag:hover) {
					background: #ddd;
				}
			`}</style>
		</div>
	)	
})


export default WorkViewSectionTags