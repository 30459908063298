import ContentfulStore from './ContentfulStore';
import RouterStore from './RouterStore';
import UIStore from './UIStore';
import FirebaseStore from './FirebaseStore';
// import DataStore from './DataStore';



var MobileDetect = require('mobile-detect');

class RootStore {
  constructor() {
    var md = new MobileDetect(window.navigator.userAgent);
    this.isMobile = !!md.phone();

    this.contentfulStore = new ContentfulStore(this);
    this.routerStore = new RouterStore(this);
    this.uiStore = new UIStore(this);
    this.firebaseStore = new FirebaseStore(this);
    // this.dataStore = new DataStore(this);
  }

}

const rootStore = new RootStore();

export default rootStore;