import React from 'react'
import {observer} from 'mobx-react'

import {
	useStore
} from '../../../hooks/useStore'

const NavClock = observer((props) => {
	const {uiStore: {NYTime: {hours, minutes, postFix}}} = useStore();

	return (
		<React.Fragment>
			
			<h6 className="plex-mono text-xs">NYC / {hours}<span className="blink">:</span>{minutes}{postFix}</h6>
			<style jsx>{`
				.blink {
					animation: blink 1000ms infinite;
				}


				@keyframes blink {
				    from { opacity: 1.0; }
				    50% { opacity: 0.28; }
				    to { opacity: 1.0; }
				}                                                                                                                                                                                                                                  

			`}</style>
		</React.Fragment>
	)
})


export default NavClock