import React from 'react'
import {observer} from 'mobx-react'

import {
	NavModularMobile,
	NavModular
} from './'

import {
	useStore
} from '../../hooks/useStore'

const Nav = observer((props) => {
	const {isMobile} = useStore();

	return isMobile ? <NavModularMobile/> : <NavModular/>
})

export default Nav