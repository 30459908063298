import React, { useEffect } from 'react'
import {observer} from 'mobx-react'


import {
	useStore
} from '../hooks/useStore'

import get from 'lodash/get'
import { Link } from '../components/link'
import {Video } from '../components/general/assets'
import { getImageURL } from '../utils'
import { Tag } from '../components/tag'
import { assetImageUrls } from '../utils/asset-image-urls'


const ProjectFilter = observer(() => {
	const {isMobile, contentfulStore: {data: {workList}}, uiStore: {workList__activeTags, workList__mouseMoved}} = useStore();
	if (isMobile) return null

	const tags = {};
	workList.forEach(item => {
		if (item.fields.tags) {
			item.fields.tags.forEach(tag => {
				if (!tags[tag.fields.name]) {
					tags[tag.fields.name] = 1
				} else {
					tags[tag.fields.name]++
				}
			})
		}
	});

	return (
		<div className={workList__mouseMoved ? "active" : ""}>
			{Object.keys(tags).sort().map(tag => {
				return <ProjectFilterButton tag={tag} num={tags[tag]} active={!!workList__activeTags[tag]}/>
			})}
			
			<style jsx>{`
				 div {
					 position: fixed;
					 top: 24px;
					 right: 24px;
					 z-index: 100;
					 opacity: .2;
					 transition: opacity .3s ease;
				 }

				 div:hover {
					 opacity: 1
				 }

				.active {
					opacity: 1;	
				}
			`}</style>
		</div>
	)
})

const ProjectFilterButton = ({tag, num, active}) => {
	const {uiStore: {toggleWorkListTag}} = useStore();

	return (
		<div className="filter flex--center-y">
			<Tag onClick={() => toggleWorkListTag(tag)}>{tag} ({num})</Tag>

			<div className={active ? "dot active" : "dot"} onClick={() => toggleWorkListTag(tag)}>
				<div className="dot__inner"></div>
			</div>

			<style jsx>{`
				.filter {
					margin-bottom: 6px;
					justify-content: flex-end;
				}

				.filter :global(.tag) {
					cursor: pointer;
				}
				
				.dot {
					height: 14px;
					width: 14px;
					border: 1px solid #959595;
					border-radius: 50%;
					margin-left: 12px;
					position: relative;
					cursor: pointer;
				}

				.dot.active .dot__inner{
					opacity: 1;
				}

				.dot__inner {
					position: absolute; 
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					height: 8px;
					width: 8px;
					background: #959595;
					border-radius: 50%;
					opacity: 0;
					transition: opacity .4s ease;
				}

			`}</style>
		</div>
	)
}


const WorkList = observer((props) =>{
	const {contentfulStore: {data: {workList}}, uiStore: {handleMouseMoveWorkList, windowWidth, setupWorkListTags}} = useStore();
	useEffect(setupWorkListTags);

	// const workListCopy = workList.slice();
	// // workListCopy.pop();
	// workListCopy.shift();
	// workListCopy.shift();



	return (
		<div className="work-list" onMouseMove={handleMouseMoveWorkList}>

			<ProjectFilter/>
			<WorkListDescription/>
			
			<div className="grid">
				{workList.map((item, idx) => {
					return <WorkListItem data={item} idx={idx} type={workList.length % 4}/>
				})}
				<FillerImage numItems={workList.length}/>
			</div>




			<style jsx>{`
				.work-list {
					padding: 60px 0 0px;
				}

				video {
					width: 930px;
					margin-left: 20px;
					border-radius 30px;
				}

				.thats-school-wrap {
					padding: 0 20px;
					line-height: 0;
					height: 0;
					padding-bottom: 42%;
				}

				.thats-school {
					border-radius: 30px;
					width: 100%;
				}

				.grid {
					display: grid;
					grid-gap: 24px;
					grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
					padding: 24px;
					padding-top: 83px;
				}
				
				.grid :global(.grid-item, .filler) {
					height: ${windowWidth * .42}px;
				}

				@media (pointer:coarse) {
					.work-list {
						padding: 40px 0 30px;
					}

					.grid {
						grid-template-columns: 1fr;
						padding: 24px 12px;
						grid-gap: 50px;
					}

					.grid :global(.grid-item, .filler) {
						height: ${windowWidth * .7}px;
					}
				}
			`}</style>
		</div>
	)
})

const WorkListDescription = () => {
	return (
		<div className="intro">
			<h2 className="text-xl">
				This is a selection of <span className="highlight active windsor">our work*<span className="band"></span></span>.  Every project is facilitated by concepts developed through a process of <span className="highlight active windsor">research<span className="band"></span></span>, <span className="highlight active windsor">experimentation<span className="band"></span></span>, <span className="highlight active windsor">design<span className="band"></span></span> and <span className="highlight active windsor">production<span className="band"></span></span>.
			</h2>

			<h6 className="sub text-xs">*Some projects we can't show but are included to give a sense of our breadth.</h6>

			<style jsx>{`
				.intro {
					text-align: center;
					padding: 80px 40px;
					max-width: 1220px;
					margin: 0 auto;
				}

				.sub {
					margin-top: 30px;
				}

				.highlight {
					z-index: -1;
				}

				@media (pointer:coarse) {
					.intro {
						padding: 20px;
					}
				}
			`}</style>
		</div>
	)
}

const WorkListItem = observer(({data, idx, type}) => {
	const {uiStore: {workList__activeTags}} = useStore();
	let active = false;
	
	get(data, "fields.tags",[]).forEach(tag => {
		if (workList__activeTags[tag.fields.name]) {
			active = true;
		}
	});


	
	return (
		<div className={`grid-item type-${type} ${active ? "active" : ""}`}>
			<Label>{data.fields.isTitleRedacted ? <mark>{data.fields.name}</mark> : data.fields.name}</Label>
			<Media key={`w-l-${idx}`} data={data}/>
			<Tags tags={data.fields.tags}/>

			<style jsx>{`
				.grid-item {
					position: relative;
					overflow: hidden;
					opacity: .2;
					transition: opacity .4s ease;
					contain: strict;
				}

				.active {
					opacity: 1;
				}



				.grid-item:nth-of-type(4n + 1) {
					grid-column: 1 / span 3;
				}

				.grid-item:nth-of-type(4n + 2) {
					grid-column: 4 / span 2;
				}

				.grid-item:nth-of-type(4n + 3) {
					grid-column: 1 / span 2;
				}

				.grid-item:nth-of-type(4n + 4) {
					grid-column: 3 / span 3;
				}

				.type-0.grid-item:nth-of-type(4n + 1){
					grid-column: 1 / span 2;
				}

				.type-0.grid-item:nth-of-type(4n + 2){
					grid-column: 3 / span 3;
				}

				.type-0.grid-item:nth-of-type(4n + 3){
					grid-column: 1 / span 3;
				}

				.type-0.grid-item:nth-of-type(4n + 4){
					grid-column: 4 / span 2;
				}

				@media(pointer:coarse) {
					.grid-item {
						grid-column: 1 !important;
						// margin: 15px 0 15px;
					}
				}
			`}</style>
		</div>
	)
});

const Label = (props) => {
	return (
		<div>
			<h6 className="text-s plex-mono">
				{props.children}
			</h6>
			
			<style jsx>{`
				div {
					position: absolute;
					top: 12px;
					left: 12px;
					padding: 4px 12px;
					background: white;
					z-index: 10;
					border-radius: 20px;
				}
			`}</style>
		</div>
	)
}

const Tags = observer(({tags}) => {
	const {isMobile} = useStore();
	if (isMobile || !tags) return null
	// console.log(tags)
	// console.log(props.data.fields.tags)
	return (
		<div className="tags flex--center-y">
			{tags.map(tag => {
				return <div className="tag text-xs">{tag.fields.name}</div>
			})}
			
			<style jsx>{`
				.tags {
					position: absolute;
					bottom: 12px;
					left: 12px;
				}

				.tag {
					margin-right: 12px;
					padding: 4px 12px;
					background: #bbb;
					z-index: 10;
					border-radius: 20px;
				}
			`}</style>
		</div>
	)
})

const FillerImage = observer((props) => {
	const {isMobile} = useStore();

	const type = props.numItems % 4;

	let src;


	
	if (isMobile || type === 1) {
		src = `${assetImageUrls['thatsschool-1']}?w=900&fm=webp&q=90`
	}  else if (type === 0 || type === 2) {
		src = `${assetImageUrls['thatsschool-3']}?w=1600&fm=webp&q=90`
	} else if (type === 3) {
		src = `${assetImageUrls['thatsschool-2']}?w=1400&fm=webp&q=90`
	}

	return (
		<div className={`filler type-${type}`}>
			<img className="thats-school" src={src} alt=""/>

			<style jsx>{`

				div {
					position: relative;
					object-fit: cover;
					overflow: hidden;
					contain: content;
					border-radius: 20px;
				}


				.type-0 {
					grid-column: 1 / span 5;
				}

				.type-1 {
					grid-column: 4 / span 2;
				}


				.type-2 {
					grid-column: 1 / span 5;
				}

				.type-3 {
					grid-column: 3 / span 3;

				}

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;
					height: 100%;
					object-fit: cover;
					max-width: initial;
				}

				@media (pointer: coarse) {
					.filler {
						grid-column: 1 / span 1;
					}
				}
			`}</style>
		</div>
	)
})

const Media = observer((props) => {
	const src = get(props.data, "fields.listViewMedia.fields.file.url", "");
	const type = get(props.data, "fields.listViewMedia.fields.file.contentType", "");
	const orientation = get(props.data, "fields.listViewMediaOrientation", "");
	const slug = get(props.data, "fields.slug");
	const isPreview = get(props.data, "fields.isPreview");
	console.log("is preview: ", isPreview);
	return (
		<div className={orientation === "landscape" ? "landscape media" : "media"}>
			<Link to="work.view" params={{id: slug}} isPreview={isPreview}>
				{type === "video/mp4" ? 
					<Video inView={true} src={src}/>
					:
					<img src={getImageURL(src, 1400, "webp", 90)} alt=""/>
				}
			</Link>
			<style jsx>{`

				.media {
					width: 100%;
					height: 100%;
					position: relative;
					object-fit: cover;
					overflow: hidden;
					contain: content;
					border-radius: 20px;
					background: #f1f1f1;
				}

				.landscape {
					// max-width: 90%;
					// padding-bottom: 60%;
				}

				
				.media :global(video), .media :global(img) {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;
					height: 100%;
					object-fit: cover;
					max-width: initial;
				}

			

				@media (pointer: coarse) {
					.media {
						margin: 0 auto;
						max-width: 100%;
						border-radius: 15px;
						transform: rotateZ(0);
					}
				}
			`}</style>
		</div>
	)
})


export default WorkList