import React from 'react'
import {
	Link
} from '../../link'

const Logo = (props) => {
	return (
		<Link to="home">
			<div className="logo-container flex--center"> 
				<img className="logo" src="//images.ctfassets.net/nr300w6wpi1u/5TKGS2EFADY4OJIZ22ykKa/eea66a48deccc0091f92c92cc2489e6a/3d-eyes-02.png?q=100&w=200&fm=webp" alt=""/>
			</div>

			<style jsx>{`
				.logo-container {
					position: relative;
					width: 46px;
					height: 46px;

				}

				.logo {
					max-width: none;
					max-height: none;
					position: absolute;
					top: 12px;
					left: -11px;
					width: 55px;
				}
				.cls-1 {
					fill:rgba(0,0,0,.2);
					fill: #000;
				}
				.cls-2 {fill:#fff;}

				@media (pointer: coarse) {
					.logo-container {
						width: 28px;
					}
				}
			`}</style>
		</Link>	
	)
}


export default React.memo(Logo)