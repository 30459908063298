import React from 'react'
import {
	useStore
} from '../../hooks/useStore'

const Tag = (props) => {
	const {uiStore: {styles}} = useStore();
	

	return (
		<div className={props.active ? "active tag bg--t3" : "tag bg--t3"} onClick={props.onClick}>
			<span className="text-xs">{props.children}</span>

			<style jsx>{`
				.tag {
					display: inline-block;
					padding: 2px 12px;
					border-radius: 20px;
				}

				.active {
					background: ${styles.colors.darkGray};
				}

				span {
					position: relative;
					top: -3px;
				}

			`}</style>
		</div>
	)
}

export default Tag