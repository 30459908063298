import React from 'react'

import {
	useStore
} from '../../../hooks/useStore'


import {
	Link
} from '../../link'

const ExitLink = (props) => {
	const {routerStore: {previousRoute}} = useStore(); 

	let name, params;
	if (!previousRoute) {
		name = "work";
		params = {};
	} else {
		name = previousRoute.name;
		params = previousRoute.params;
	}


	return (
		<Link to={name} params={params}>
			<div className="flex--center">
				<svg width="34px" height="34px" viewBox="0 0 34 34">
				    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				        <g transform="translate(-1872.000000, -1032.000000)" stroke="#fff" strokeWidth="4">
				            <g id="Group-4" transform="translate(1.000000, 1015.000000)">
				                <g id="Group-3" transform="translate(1872.437500, 17.687500)">
				                    <line x1="0" y1="0.75" x2="31" y2="31.75" id="Stroke-6-Copy"></line>
				                    <line x1="31" y1="0.75" x2="0" y2="31.75" id="Stroke-7-Copy"></line>
				                </g>
				            </g>
				        </g>
				    </g>
				</svg>

				<style jsx>{`
					div {
						height: 26px;
						width: 26px;
						background: #F4625D;
						border-radius: 50%;
						position: relative;
						transition: background .2s ease;
					}

					div:hover {
						background: #C24E4A;
					}
					svg {
						width: 13px;
						fill: white;
						stroke: white;
					}

				`}</style>
			</div>
		</Link>
	)
}

export default React.memo(ExitLink)