import React, { useState } from 'react'
import {observer} from 'mobx-react-lite'
import { useInView } from 'react-intersection-observer'
import { useStore } from '../../../../hooks/useStore'
import get from 'lodash/get'

const BlockVideo = observer((props) => {
	const [ref, inView] = useInView({
	  threshold: .33,
	  triggerOnce: true
	});

	const [canPlay, setCanPlay] = useState(false);

	const {contentfulStore: {pageData}} = useStore();

	const description = props.video.fields.description;
	
	const poster = props.poster;
	if (!poster) return null;
	const {width, height} = poster.fields.file.details.image;
    const aspectRatio = height / width;

	const isRedacted = get(pageData, "fields.isRedacted");

	return (
		<div ref={ref} className={`block__image ss ${inView ? "in-view" : ""}`}>
			<div className="image">

				{inView ? <video src={props.video.fields.file.url} onCanPlay={() => setCanPlay(true)} autoPlay muted loop playsInline></video> : null} 
				{!canPlay ? <img src={poster.fields.file.url} alt=""/> : null}
				

				{description ? <span className="description text-xs">{isRedacted ? <mark>{description}</mark> : description}</span> : null}
				<span className="fig-num text-xs">{props.prefix}-{props.idx}</span>
			</div>

			<style jsx>{`
				.image {
					opacity: 0;
					transition: all 1.7s cubic-bezier(0.33, 1, 0.68, 1);
					transition-delay: .15s;
					transform: translate3d(0px, 20px, 0);
					text-align: left;
					line-height: 0;

					display: block;
					position: relative;
					height: 0;
                    padding-bottom: ${aspectRatio *  100}%;
				}
				
				.block__image {
					width: 100%;
				}

				img {
					position: absolute;
					width: 100%;
					top: 0;
					left: 0;
					border-radius: ${props.full ? "0" : "30px"};
				}

				video {
					width: 100%;
					line-height: 0;
					border-radius: ${props.full ? "0" : "30px"};
				}

				.description, .fig-num {
					position: absolute;
					bottom: 0;
					transform: translateY(100%) translateY(6px);
				}

				.description {
					left: 24px;
					max-width: 75%;
				}

				.fig-num {
					right: 24px;
				}

				.in-view .image {
					opacity: 1;
					transform: translate3d(0, 0px, 0);
				}

			`}</style>
		</div>
	)
})

export default React.memo(BlockVideo)
