import React, {useState, useEffect}  from 'react'

import {observer} from 'mobx-react'

import {
	useStore
} from '../hooks/useStore'

import {
	WidgetPlant,
	WidgetLastClickedButton,
	WidgetWeather,
	WidgetPoll,
	WidgetChess,
	WidgetDrawingPixel,
	WidgetColorPicker,
	WidgetCaptcha,
	// WidgetAudioPlayer,
	WidgetUserData,
	// WidgetAdminPassword
} from '../components/_pages/home'

import {
	GridItem,
	GridItemTopLabel,
	GridItemBottomLabel
} from '../components/_pages/home/griditem'


import {
	AvatarPic
} from '../components/avatar'

import {
	Button
} from '../components/button'
 
import {
	Link
} from '../components/link'

import {
	getImageURL
} from '../utils'



import get from 'lodash/get'


const initFirebase = async () => {
  const firebase = await import("firebase/app");
  await Promise.all([import("firebase/database")]);

  const firebaseConfig = {
    apiKey: "AIzaSyBYRccvWzRfTFtGBle89Ak-jov2SZO2MUI",
    authDomain: "school-portfolio-v2.firebaseapp.com",
    databaseURL: "https://school-portfolio-v2.firebaseio.com",
    projectId: "school-portfolio-v2",
    storageBucket: "school-portfolio-v2.appspot.com",
    messagingSenderId: "803986885772",
    appId: "1:803986885772:web:681299465ca217e368bfa9",
    measurementId: "G-4BC5CPES7F"
  };

  firebase.initializeApp(firebaseConfig);

  if (!firebase) {
    return Promise.reject(new Error("firebase loading error"));
  }

  return firebase

};


const HomeView = observer((props) => {
	const {isMobile, firebaseStore, uiStore} = useStore();
	useEffect(() => {
		uiStore.getWeather();

		if (firebaseStore.init) {
			firebaseStore.initializeListeners()
			return
		}

		firebaseStore.startInit();

		initFirebase()
		.then(firebase => {
			const database = firebase.database();
			firebaseStore.setDatabase(firebase, database);
			firebaseStore.initializeListeners()
		});

		// required for cleanup!!!
		return firebaseStore.unbindListeners

	}, []);

	if (isMobile) return <MobileLayout/>
	
	return (
		<div>
			<div className="f-v">
				<UserPresence/>
				<DesktopWidgetGrid/>
			{/*<WidgetAdminPassword/>*/}
			</div>

			<div className="b-v">
				<video 
					width={uiStore.windowWidth / 2} 
					height={uiStore.windowHeight / 2} 
					src="//videos.ctfassets.net/nr300w6wpi1u/3yP8b7NZQ8lWOmldffA2RI/fa3e25b662c66859fe36ef9be324323c/looney-tunes-06-hb.mp4" 
					autoPlay 
					playsInline
					muted 
					loop/>
			</div>


			<style jsx>{`
				.f-v {
					position: relative;
					z-index: 1;
					background: rgba(160,160,160,.38);
				}


				.b-v {
					position: fixed;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 0;

				}

				video {
					width: 100%;
					height: 100%;
					object-fit: cover;
					contain: strict;
				}
			`}</style>
		</div>
	)	
});


const MobileLayout = observer((props) => {
	const {uiStore: {styles}, firebaseStore: {userData, cycleUserAvatarColor}} = useStore();
	
	if (!userData) return null

	return (
		<div className="grid">
			<WidgetDrawingPixel/>

			<div className="pill">
				<UserPresence/>
			</div>

			{/* <User */}
			<h3 className="text-l helvetica">School is <span className="highlight active" onClick={cycleUserAvatarColor}>your<span className="band"></span></span>  creative studio</h3>


			<style jsx>{`
				.grid {
					padding: 20px;
					// position: fixed;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					padding-bottom: 72px;
				}

				h3 {
					text-align: center;
					margin: 20px 0;
				}

				.highlight .band {
					background: ${styles.colors.avatar[userData.avatar.color]};
				}

			`}</style>
		</div>
	)
})


const DesktopWidgetGrid = observer((props) => {
	const {contentfulStore: {pageData}, uiStore} = useStore() 
	if (!pageData) return null


	return (
		<div className="grid">

			<div className="col col-1">
				<SchoolTagline />
				<SchoolLinks/>
				<WidgetColorPicker sequence uiStore={uiStore}/>
				<WidgetCaptcha sequence/>
				
				<WidgetPlant sequence/>
				<MoodImage sequence/>
			</div>



			<div className="col col-2">
				<WidgetUserData/>

				<WidgetDrawingPixel sequence/>
				
				<FeaturedNews sequence/>
				<WidgetPoll sequence/>

			</div>



			<div className="col col-3">
				<div className="group">
					<WidgetWeather sequence/>
					<WidgetLastClickedButton sequence/>
				</div>

				<FeaturedProject sequence/>
				<ArtHistoryImage sequence/>
				<WatchlistItem sequence/>
				<WidgetChess sequence/>

			</div>

			<FooterImage/>

			<style jsx>{`
				.grid {
					display: grid;
					grid-template-columns: 330px 1fr 1fr;
					grid-auto-rows: minmax(min-content, max-content);
					gap: 24px;
					padding: 10px 24px;
					padding-top: 100px;
				}

				.group {
					display: grid;
					grid-template-columns: 170px 1fr;
					grid-auto-rows: minmax(min-content, max-content);
					gap: 20px;
				}

				.highlight {
				  margin: 0 6px;
				}

				@media (max-width: 1430px) {
					.grid {
						grid-template-columns: 330px 440px 1fr;
					}
				}
				@media (max-width: 1280px) {
					.group {
						grid-template-columns: 1fr;
						gap: 0px;
					}
				}
			`}</style>
		</div>
	)
})


const SchoolTagline = React.memo((props) => {
	return (
		<div className="seq--s1 tagline">
			<div className="user-section flex--center-y">
				<TaglineWelcome/>
			</div>
			
			<h6 className="intro">
				School is 
				<span className="highlight active helvetica">
					(y)our 
					<span className="band"></span>
				</span>
				creative studio.
			</h6>

			<style jsx>{`
				.highlight {
					margin: 0 8px;
				}
				.tagline {
					text-align: left;
				}

				.intro {
					font-size: 44px;
				}

				.user-section :global(> div:first-of-type) {
					margin-left: 12px;
				}

				@media(pointer:coarse) {
					.tagline {
						padding: 20px 5vw;
						text-align: left;
					}

					.tagline h6 {
						font-size: 12vw;
					}
				}

			`}</style>
		</div>
	)
})

const TaglineWelcome = observer((props) => {
	const {firebaseStore: {userData}} = useStore();
	if (!userData) return null
	const name = get(userData, "name", "");
	return (
		<React.Fragment>
			<h6 className="text-xl">Hi {name},</h6>
			<AvatarPic params={userData.avatar} size={40}/>
			<style jsx>{`
				h6 {
					max-width: 280px;
					white-space:nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 44px;
				}
			`}</style>
		</React.Fragment>
	)
})


const FooterImage = () => {
	const {contentfulStore: {pageData}} = useStore();

	return (
		<div className="seq--s2">
			<img src={`${pageData.fields.footerImage.fields.file.url}?w=1600&fm=webp&q=85`} alt=""/>
			
			<style jsx>{`
				div {
					grid-column: 1 / span 3;
				}
				img {
					border-radius: 10px;
				}
			`}</style>
		</div>
	)
}

const SchoolLinks = React.memo((props) => {
	return (
		<div className="seq--s1 links">

			<div className="btn-wrap">
				<a href="https://instagram.com/schoooooooooooool" target="_blank" rel="noreferrer">
					<Button width={"100%"}>
						
						<span className="text-xs">@schoooooooooooool</span>

					</Button>
				</a>
			</div>

			<div className="btn-wrap">
				<a href="mailto:info@schoooool.com" target="_blank">
					<Button width={"100%"}>

						<span className="text-xs">info@schoooool.com</span>

						
					</Button>
				</a>

			</div>


			<style jsx>{`
				.btn-wrap {
					width: 50%;
					padding: 0 5px;
					display: inline-block;
				}

				a {
					display: inline;
				}

				.links {
					width: 100%;
					padding: 20px 0;
				}

				.link {
					margin: 0 2px;
				}
			`}</style>
		</div>
	)	
})

// const ThatsAllImage = observer((props) => {
// 	const {contentfulStore: {pageData}} = useStore() 
// 	if (!pageData) return null

// 	return (
// 		<GridItem {...props} fullHeight>
// 			<img src={getImageURL(pageData.fields.thatsAllImage, 800, "webp", 90)} alt=""/>
// 			<style jsx>{`
// 				img {
// 					width: 100%;
// 					height: 100%;
// 					object-fit: cover;
// 				}
// 			`}</style>
// 		</GridItem>
// 	)
// })

const MoodImage = observer((props) => {
	const {contentfulStore: {pageData}} = useStore();
	if (!pageData) return null


	return (
		<GridItem {...props} fullHeight>
			<img src={getImageURL(pageData.fields.moodImage, 800, "webp", 90)} alt=""/>

			<style jsx>{`
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			`}</style>
		</GridItem>
	)
});


const ArtHistoryImage = observer((props) => {
	const {contentfulStore: {pageData}} = useStore();
	if (!pageData) return null


	return (
		<GridItem {...props} fullHeight>
			<div className="wrapper flex--column">
				<GridItemTopLabel>Art History 008.</GridItemTopLabel>
				<div className="img-container">
					<img src={getImageURL(pageData.fields.artHistoryImage, 800, "webp", 90)} alt=""/>

				</div>
				<GridItemBottomLabel>
					<h6 className="text-xs helvetica">Wheatfield - A Confrontation</h6>
					<h6 className="text-xs helvetica">Agnes Denes</h6>
					<h6 className="text-xs helvetica">1982</h6>
				</GridItemBottomLabel>
			</div>

			<style jsx>{`


				.img-container {
					position: relative;
					flex: 1;
					line-height: 0;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			`}</style>
		</GridItem>
	)
});

const FeaturedProject = observer((props) => {
	const {contentfulStore: {pageData}} = useStore();
	if (!pageData) return null

	return (
		<GridItem {...props}>
			<GridItemTopLabel>Featured Project</GridItemTopLabel>

			<div className="seq--s2">
					<Link to="work.view" params={{id: pageData.fields.featuredProject.fields.slug}}>
						<div className="featured-project">
							<div className="featured-tab flex--center text-s">{pageData.fields.featuredProject.fields.name}</div>
							<img src={getImageURL(pageData.fields.featuredProjectImage, 800, "webp", 90)} alt=""/>
							<div className="tags flex--center-y">
								{pageData.fields.featuredProject.fields.tags.map((tag, idx) => {
									return <div className="tag text-xs" key={`fp-tag-${idx}`}>{tag.fields.name}</div>
								})}
							</div>
						</div>
					</Link>

					<style jsx>{`
						.featured-project {
							position: relative;
							// border-radius: 10px;
							overflow: hidden;
							line-height:0;
							// margin-bottom: 24px;
							// box-shadow: 0 1px 4px rgba(0,0,0,0.18);
						}

						.tags {
							position: absolute;
							bottom: 12px;
							left: 12px;
						}

						.tag {
							margin-right: 12px;
							padding: 4px 12px;
							background: #bbb;
							z-index: 10;
							border-radius: 20px;
						}

						.featured-tab {
							position: absolute;
							top: 12px;
							left: 12px;
							padding: 4px 12px;
							background: #f9f9f9;
							z-index: 10;
							border-radius: 20px;
						}

						.featured-project-name {
							position: absolute;
							bottom: 12px; 
							left: 12px;
						}


						img {
							object-fit: cover;
							height: 100%;
							width: 100%;
						}
						.mobile-wrap {
							height: 100%;
							// max-height: 100%;
						}

						.mobile-wrap .featured-project {
							height: 100%;
						}

						// .mobile-wrap img  {
						// 	object-fit: cover;
						// 	height: 100%;
						// }


					`}</style>
			</div>
		</GridItem>

	)
})

const FeaturedNews = observer((props) => {
	const {isMobile, contentfulStore: {pageData}} = useStore();
	if (!pageData) return null


	return (
		<div className={isMobile ? "mobile-wrap" : "seq--s2"}>
			<a href={pageData.fields.featuredNews.fields.url} target="_blank" rel="noreferrer">
				<div className="featured-project">
					<div className="featured-tab flex--center text-s">Featured News</div>
					<img src={getImageURL(pageData.fields.featuredNewsImage, 800, "webp", 90)} alt=""/>
				</div>
			</a>
			<style jsx>{`
				.featured-project {
					position: relative;
					border-radius: 10px;
					overflow: hidden;
					line-height:0;
					margin-bottom: 24px;
					box-shadow: 0 1px 4px rgba(0,0,0,0.18);
				}

				.featured-tab {
					position: absolute;
					top: 12px;
					left: 12px;
					padding: 4px 12px;
					background: #f9f9f9;
					z-index: 10;
					border-radius: 20px;
				}

				.mobile-wrap {
					height: 100%;
				}

				.mobile-wrap .featured-project {
					height: 100%;
				}

				.mobile-wrap img  {
					object-fit: cover;
					height: 100%;
				}

			`}</style>

		</div>
	)
})

const WatchlistItem = observer((props) => {
	const {contentfulStore: {pageData}} = useStore();

	const [showIframe, toggleIframe] = useState(false);

	
	return (
		<GridItem {...props}>
			<GridItemTopLabel>(Y)our Watchlist.</GridItemTopLabel>

				<div className="featured-project">

					<div className="embed-container">
						{showIframe ? 
							<iframe src={`${pageData.fields.watchListURL}?autoplay=1&controls=0&modestbranding=1`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
							:
							null
						}
					</div>

					<img className={showIframe ? "hide" : ""} onClick={() => toggleIframe(true)} src={getImageURL(pageData.fields.watchlistImage, 800, "webp", 90)} alt=""/>


					<style jsx>{`
						.featured-project {
							position: relative;
						}

						.embed-container { 
							background: black;
							position: relative; 
							padding-bottom: 56.25%; 
							height: 0; 
						} 
						.embed-container iframe, img{ 
							position: absolute; 
							top: 0; 
							left: 0; 
							width: 100%; 
							height: 100%; 
							cursor: pointer;
						}

						img {
							object-fit: cover;
							z-index: 1;
							opacity: 1;
							transition: opacity .3s ease;
							cursor: pointer;
						}

						img.hide {
							opacity: 0;
							pointer-events: none;
							transition-delay: .3s;
						}

						.featured-tab {
							height: 42px;
							background: rgba(255,255,255,.9);
							position: absolute;
							top: 12px; 
							left: 12px;
							border-radius: 25px;
							padding: 12px 20px;
						}
					`}</style>
				</div>
				<GridItemBottomLabel><h6 className="text-xs helvetica">John Berger - Ways of Seeing</h6></GridItemBottomLabel>

		</GridItem>
	)
})


// const SchoolStreetImage = observer((props) => {
// 	const {contentfulStore: {pageData}} = useStore();
// 	if (!pageData) return null

// 	return (
// 		<GridItem {...props}>
// 			<GridItemTopLabel>(Y)our School.</GridItemTopLabel>
// 			<img src={getImageURL(pageData.fields.schoolStreetImage, 400, "webp", 90)} alt=""/>
// 			<GridItemBottomLabel>
// 				<h6 className="text-xs plex-mono">Submitted by Nicky</h6>
// 			</GridItemBottomLabel>
// 		</GridItem>
// 	)
// })

const UserPresence = observer((props) => {
	const {firebaseStore: {val__liveUsers}} = useStore()
	// if (!val__numUsers) return null
	// const liveUsers = val__liveUsers.slice(0,5);
	const liveUsers = val__liveUsers.map((user, idx) => {
		return <AvatarPic key={"us" + idx}  params={user.avatar} size={30} margin={2}/>
	});

	// console.log(val__liveUsers, liveUsers.length)

	// console.log('ya')

	return (	
		<div className="seq--s2 users flex--center-y">
			{liveUsers}

			<NumExtraUsers/>
			
			<style jsx>{`
				.users {
					position: fixed;
					top: 20px;
					right: 22px;
					z-index: 10;
					contain: content;
				}


				@media (pointer:coarse) {
					.users {
						position: absolute;
						top: 24px;
						right: 24px;
						padding: 6px;
						background: white;
						border-radius: 20px;
					}
				}
			`}</style>
		</div>
	)
})

const NumExtraUsers = observer((props) => {
	const {firebaseStore: {numExtraUsers}} = useStore()
	if (!numExtraUsers) return null
	return (
		<div className="count flex--center">
			<span className="text-s">+{numExtraUsers} other{numExtraUsers === 1 ? "" : "s"}</span>
			<style>{`
				.count {
					height: 30px;
					margin-left: 2px;
					border-radius: 15px;
					padding: 0 8px;
					background: white;
				}
			`}</style>
		</div>
	)
	 

})


// const UserSpan = (props) => {
// 	return (
// 		<AvatarPic params={props.params} size={30} margin={2}/>
// 	)
// }












	


// const Widget = (props) => {
// 	return null
// }



export default HomeView