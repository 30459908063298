import get from 'lodash/get'

export function promiseAllReflect(promises = []) {  
  const reflect = promise => promise.then(
    value => ({ value, status: 'fulfilled' }),
    error => ({ error, status: 'rejected' }),
  );

  return Promise.all(promises.map(reflect));
}

export function executeAtLeast(time, func, funcArgs = []) {  
  return promiseAllReflect([
    new Promise(resolve => setTimeout(resolve, time)), 
    func(...funcArgs)
  ]);
}

export async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    // headers: {
    //   'Content-Type': 'application/json'
    //   // 'Content-Type': 'application/x-www-form-urlencoded',
    // },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}


  
export function getImageURL(image, width, format, quality) {
	const imageSrc = typeof(image) === "string" ? image : get(image, "fields.file.url", "");

	return `${imageSrc}?w=${width}&fm=${format}&q=${quality}`
}


export function convertElapsedTime(inputSeconds) {
  var seconds = Math.floor(inputSeconds % 60)
  if (seconds < 10) {
    seconds = "0" + seconds
  }
  var minutes = Math.floor(inputSeconds / 60)
  return minutes + ":" + seconds
}

export function writeToLocalStorage(key,value) 
{
  if(typeof(Storage) == 'undefined')
   {
     alert("Your browser doesn't support HTML5 LocalStorage which this site make use of. Some features may not be available. Consider upgrading your browser to the latest version");
     return false;
   }

  value = JSON.stringify(value); //serializing non-string data types to string

   try
   {
       window.localStorage.setItem(key, value);
   }
   catch (e) 
   {
        const QUOTA_EXCEEDED_ERR = 22;

       if (e == QUOTA_EXCEEDED_ERR || e.code == QUOTA_EXCEEDED_ERR) {
          alert('Local storage Quota exceeded! .Clearing localStorage');
          localStorage.clear();
          window.localStorage.setItem(key, value); //Try saving the preference again
        }
   }
   
   return true;
}

 
export function readFromLocalStorage(key)
    {
    if(typeof(Storage) == 'undefined')
      {
      //Broswer doesnt support local storage
          return null;
      }

      const  value = JSON.parse(localStorage.getItem(key));
      return value;

}  

export function shuffleArr(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
  }
  return a;
}

export function sendAnalyticsPageView(pageState) {
  const gtag = window.gtag;
  if (!gtag) return

  gtag('event', 'page_view', {
      page_title: pageState.name,
      page_path: pageState.path
    });
}