import React from 'react'
// import {observer} from 'mobx-react'
import {useStore} from '../../../../hooks/useStore'



const GridItem = (props) => {
	const {isMobile} = useStore();

	return (
		<div className={`${isMobile ? "mobile" : ""} bg--t2 ${props.sequence ? "seq--s2" : ""} ${props.userData? "user-data" : ""}`}>
			{props.children}

			<style jsx>{`


				div {
					border-radius: 10px;
					overflow: hidden;
					margin-bottom: 24px;
					line-height: 0;
					contain: content;
					box-shadow: 0 1px 4px rgba(0,0,0,0.18);
					transition: all 0.3s cubic-bezier(.25,.8,.25,1);
				}


				.mobile {
					max-height: 100%;
					width: 100%;
					height: ${props.fullHeight ? "100%" : "auto"};
					margin: 0;
					display: block;
					flex-direction: column;
				}

				.mobile :global(img) {
					object-fit: container;
					flex: 1;
				}


				@media(pointer: coarse) {
					.mobile {
						margin-bottom: 20px;
					}

				}
			`}</style>
		</div>
	)
}


export default GridItem