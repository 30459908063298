import {action, observable, computed} from "mobx";
import {createRouter} from 'router5';
import browserPlugin from 'router5-plugin-browser';

import routes from '../router/routes.js'
import {mobxRouterPlugin} from '../router/mobxPlugin.js';
import get from 'lodash/get'

import {
	executeAtLeast
} from '../utils'

class RouterStore {
	constructor(rootStore) {
		
		this.rootStore = rootStore;

		const router = createRouter(routes, {
		  // trailingSlashMode: 'always',
		  trailingSlashMode: 'never',
		  queryParamsMode: 'loose',
		  strictTrailingSlash: false
  		});		


  		router.usePlugin(browserPlugin(), mobxRouterPlugin);
	
  		this.router = router;
	}

	@observable current;
	@observable previousRoute;
	@observable routeTransitioning = false;
	@observable showLoader = false;
	@observable fadeOutLoader = false;
	@observable movingTo = "";
	
	transitionMessage = ""
	transitionMessages = [
		"Just a sec...",
		"Be right back...",
		"Hang on...",
		"Be right with you...",
		"Sit tight...",
		"Give us a moment...",
		"It's happening...",
		// "Where'd we put that page...",
	]
	transitionIdx = 0;

	initialLoad = true;


	@action.bound navigate(e, to, params = {}, options = {}) {
		this.router.navigate(to, params, options);
	}

	@computed get headTitle() {
		const {viewingWork, viewingWorkList, viewingAbout,} = this;

		if (viewingWork) {
			const name = get(this.rootStore.contentfulStore.pageData, "fields.name", "")
			// console.log(this.rootStore.contentfulStore.pageData)
			return `${name} | School`
		}

		if (viewingWorkList) {
			return "Selected Work | School"
		}

		if (viewingAbout) {
			return "Info | School"
		}


		return `(Y)our creative studio | School`
	}

	@computed get headDescription() {
		const {viewingWork, viewingWorkList, viewingAbout} = this;
		const defaultText = "School is a small, full-service creative studio. We bring thoughtful perspectives to conceptual, practical and visual problems."

		if (viewingWork) {
			const tagline = get(this.rootStore.contentfulStore.pageData, "fields.tagline.content[0].content", []);
			const str = tagline.map(item => item.value).join("")

			return str
		}

		return defaultText
	}

	@computed get headImage() {
		const {viewingWork} = this;

		let image = "//images.ctfassets.net/nr300w6wpi1u/7aDFpzMtT8NV1KKYXfuRD8/a3f36290062904746f3fb739636a28af/home-seo-01.png?w=1200&fm=png"

		if (viewingWork) {
			const workImage = get(this.rootStore.contentfulStore.pageData, "fields.seoImage.fields.file.url");

			if (workImage) {
				image = `${workImage}?w=1200&fm=png`
			}
		}

		// console.log("image: ", "https:" + image)
		return image
	}

	@computed get routeName() {
		return get(this.current, "name", "404")
	}

	@computed get viewingWork() {
		return this.routeName === "work.view"
	}

	@computed get viewingAbout() {
		return this.routeName === "about"
	}

	@computed get viewingWorkList() {
		return this.routeName === "work"
	}


	@action setCurrent(state) { 
		this.previousRoute = this.current;
		// this.current = state;
		// this.rootStore.updatePageControls(state);

		this.rootStore.uiStore.resetUIColor();

		this.startTransition(state).then((e) => {
			this.rootStore.uiStore.clearRouteVariables() 
			this.endTransition();
			// console.log("pre-set")
			this.current = state;
			// console.log("setting")
			this.rootStore.uiStore.scrollToTop();
			// this.rootStore.scrollToTop();
		})

	}

	@action.bound startTransition(toState) {
		const TIME = this.initialLoad ? 10 : 700;
		// const TIME = 0;
		// console.log("starting transition")
		this.startLoader()
		this.routeTransitioning = true;

		return executeAtLeast(TIME, this.fetchRouteData, [toState])

	}

	@action.bound async fetchRouteData(toState) {

		// if (this.initialLoad) {
		// 	this.initialLoad = false;
		// 	return 
		// }
		let func = null;
		switch(toState.name) {
			case "home":
				func = this.rootStore.contentfulStore.fetchHomeView();
				this.rootStore.contentfulStore.fetchCaptchaImages();
				// this.rootStore.contentfulStore.fetchWorkList();

				break;
				

			case "work":
				func = this.rootStore.contentfulStore.fetchWorkList();
				// this.rootStore.contentfulStore.fetchNewsData();
				break;
					
			case "work.view":
				func = this.rootStore.contentfulStore.fetchWorkView(toState.params.id);
				break;

			case "about":
				func = this.rootStore.contentfulStore.fetchAboutView();
				break;

			default:
				func = null;

		}

		return func
	}



	@action.bound endTransition() {

		this.routeTransitioning = false;
		this.fadeOutLoader = true;
		setTimeout(this.endLoader, 900)

	}

	@action.bound startLoader() {

		this.transitionMessage = this.transitionMessages[this.transitionIdx % this.transitionMessages.length]
		this.fadeOutLoader = false;
		this.showLoader = true;
		this.transitionIdx++
	}

	@action.bound endLoader() {
		this.showLoader = false;
		if (this.initialLoad) {
			this.initialLoad = false;
		}
	}

}


export default RouterStore