import React from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../../../../hooks/useStore'

import {
	GridItem,
	GridItemTopLabel,
	GridItemBottomLabel
} from '../griditem'

import {
	AvatarPic
} from '../../../avatar'

import {
	assetImageUrls
} from '../../../../utils/asset-image-urls.js'

const WidgetPlant = (props) => {

	return (
		<GridItem  {...props}>
			<GridItemTopLabel>(Y)our virtual houseplant.</GridItemTopLabel>

			<div className="relative">
				<PlantImage/>
				<WaterButton/>
			</div>


			<GridItemBottomLabel>
				<LastWateredBy/>
			</GridItemBottomLabel>
		</GridItem>
	)
}

const LastWateredBy = observer((props) => {
	const {firebaseStore: {val__plantState}} = useStore();
	if (!val__plantState) return null

	return (
		<div className="flex--center-y">
			<AvatarPic params={val__plantState.updatedBy.avatar} size={30}/>
			<h6>{val__plantState.updatedBy.name} watered it last.</h6> 

			<style jsx>{`
				h6 {
					margin-left: 6px;
				}
			`}</style>
		</div>
	)
})

const WaterButton = observer((props) => {
	const {firebaseStore: {val__plantState, setLastWatered}} = useStore();
	if (!val__plantState) return null

	const plantState = val__plantState.val;
	if (plantState === 1) return null

	return (

		<div className="btn flex--center" onClick={setLastWatered}>
				<span className="text-l">💧</span>

			<style jsx>{`
				.btn {
					position: absolute;
					cursor: pointer;
					bottom: 20px;
					right: 20px;
					width: 60px;
					height: 60px;
					border-radius: 50%;
					background: rgb(0,100,226);
					box-shadow: 0 1px 4px rgb(0 0 0 / 18%);
				}
			`}</style>
		</div>
	)
})

const PlantImage = observer((props) => {
	const {firebaseStore: {val__plantState}} = useStore();
	if (!val__plantState) return null

	const plantState = val__plantState.val;

	
	return (
		<div className="img-section"> 
			<img className={plantState === 1 ? "active" : ""} src={assetImageUrls["plant-01"] + "?w=500&fm=webp&q=90"} alt=""/>
			<img className={plantState === 2? "active" : ""} src={assetImageUrls["plant-02"] + "?w=500&fm=webp&q=90"} alt=""/>
			<img className={plantState === 3? "active" : ""} src={assetImageUrls["plant-03"] + "?w=500&fm=webp&q=90"} alt=""/>
			<img className={plantState === 4? "active" : ""} src={assetImageUrls["plant-04"] + "?w=500&fm=webp&q=90"} alt=""/>
			<img className={plantState === 5? "active" : ""} src={assetImageUrls["plant-05"] + "?w=500&fm=webp&q=90"} alt=""/>

			<style jsx>{`
				.img-section {
					line-height: 0;
					position: relative;
					padding-bottom: 128%;
				}

				.disable {
					pointer-events: none;
					opacity: .3;
				}


				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					opacity: 0;
					transition: opacity 2s ease;
				}

				.active {
					opacity: 1;
				}
			`}</style>
		</div>

	)
})


export default WidgetPlant