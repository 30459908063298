import React from 'react'

const FourOhFour = () => {
    return (
        <div className="flex--center">
            <h1 className="text-xxl">Page Not Found</h1>

            <style jsx>{`
                div {
                    height: 100vh;
                }
            `}</style>
        </div>
    )
}

export default FourOhFour