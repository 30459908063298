import React, {useState} from 'react'
import { getImageURL } from '../../../utils';

const Image = React.memo((props) => {
    const [isLoaded, setLoad] = useState(false);

    const {width, height} = props.image.fields.file.details.image;
    const aspectRatio = height / width;
    
    return (
        <div> 
            {props.inView ?

                <img 
                    src={getImageURL(props.image, props.width, "webp", props.q)} 
                    className={`${props.className || ""} ${isLoaded ? "loaded" : ""}`} 
                    onLoad={() => setLoad(true)}
                    alt=""/>
                :
                null
                
            }

            <style jsx>{`

                div {
                    height: 0;
                    background: red;
                    padding-bottom: ${aspectRatio *  100}%;
                    border-radius: 30px;
                    background: rgba(235,235,235, .7);
                }

                img {
                    opacity: 0;
                    border-radius: 30px;
                }

                .loaded {
                    animation: fade-in 0.5s ease-out;
                    animation-fill-mode: forwards;
                }

                @keyframes fade-in {
                    from {
                        opacity: 0;
                    }

                    to {
                        opacity: 1;
                    }
                }
            `}</style>



        </div>

    )
})


export default Image
