import React, {useState} from 'react'

const Video = React.memo((props) => {
    const [isLoaded, setLoad] = useState(false)

    // console.log(props.video);
    return (
        <React.Fragment>
            {props.inView ?

                <video 
                    src={props.src} 
                    className={isLoaded ? "loaded" : ""} 
                    onLoadedData={() => setLoad(true)}
                    playsInline
                    autoPlay
                    muted
                    loop/>
                :
                null
                
            }

            <style jsx>{`

                video {
                    opacity: 0;
                }

                .loaded {
                    animation: fade-in 0.5s ease-out;
                    animation-fill-mode: forwards;
                }

                @keyframes fade-in {
                    from {
                        opacity: 0;
                    }

                    to {
                        opacity: 1;
                    }
                }
            `}</style>



        </React.Fragment>

    )
})


export default Video
