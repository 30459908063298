import { observer } from 'mobx-react-lite'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import { useStore } from '../../../../hooks/useStore'

import { Image } from '../../../general/assets'
import get from 'lodash/get'


const BlockTextImage = observer((props) => {
	const [ref, inView] = useInView({
	  threshold: .33,
	  triggerOnce: true
	});

	const {uiStore: {workViewFlexWidth}, contentfulStore: {pageData}} = useStore();
	
	const description = props.image.fields.description;
	const isRedacted = get(pageData, "fields.isRedacted");


	return (
		<div ref={ref} className={`block__text-image ${inView ? "in-view" : ""}`}>
			<div className="text">
				<p className="text-xs helvetica">
					{isRedacted ? <mark>{props.text}</mark> : props.text }
				</p>
			</div>

			<div className="image">
				<Image inView={inView} width={1400} q={85} image={props.image}/>
				{description ?  <span className="description text-xs">{isRedacted ? <mark>{description}</mark> : description }</span> : null}
				<span className="fig-num text-xs">{props.prefix}-{props.idx}</span>
			</div>


			<style jsx>{`
				.block__text-image {
					display: flex;
					align-items: center;
				}
				
				.text {
					width: 400px;
					text-align: left;
					padding: 0 73px;
					transition: all 1.2s cubic-bezier(0.33, 1, 0.68, 1);
					transition-delay: .45s;
					opacity: 0;
					transform: translate3d(0, 20px, 0);
				}

				.image {
					flex: 1;
					width: ${workViewFlexWidth}px;
					height: 100%;
					opacity: 0;
					transition: all 1.7s cubic-bezier(0.33, 1, 0.68, 1);
					transition-delay: .15s;
					transform: translate3d(0px, 20px, 0);
					text-align: right;
					position: relative;
					line-height: 0;
				}

				.description, .fig-num {
					position: absolute;
					bottom: 0;
					transform: translateY(100%) translateY(6px);
				}

				.description {
					left: 24px;
					max-width: 60%;
					text-align: left;
				}

				.fig-num {
					right: 24px;
				}

				.block__text-image :global(img) {
					width: 100%;
				}

				.in-view .image, .in-view .text {
					opacity: 1;
					transform: translate3d(0, 0px, 0);
				}

		

				@media (pointer: coarse) {
					.block__text-image {
						width: 100%;
						display: block;
					}

					.text {
						padding: 0 20px;
						margin-bottom: 20px;
						transform: none;
						opacity: 1;
					}

					.image {
						opacity: 1;
						transition: none;
						transform: none;
					}

					img {
						width: 100%;
						height: auto;
						object-fit: initial;					
					}
				}
			`}</style>
		</div>
	)
})

export default React.memo(BlockTextImage)
