import React from 'react'
import {observer} from 'mobx-react'
import {
	useStore
} from '../../hooks/useStore'

import { Flipper, Flipped } from 'react-flip-toolkit'


import {
	NavInfoButton,
	NavWorkButton,
	// WorkSectionTags,
	// Clock,
	ExitLink,
	LogoSection,
	ProjectName
} from './subcomponents'


import get from 'lodash/get'

const NavModular = observer((props) => {
	const {routerStore: {routeTransitioning, current, viewingWork}} = useStore();
	const path = get(current, "path", "404");

	// console.log("updating nav", path)

	return (
		<Flipper 
			flipKey={path}
			staggerConfig={{
			      default: {
			        reverse: !viewingWork ? false : true,
			        // default is .1, 0 < n < 1
			        speed: .5
			      },
			  }}
			>
				<div className="nav flex--center-y">
						<div className="sq--logo sq flex--center-y border-l bg--t3">
							<div className={routeTransitioning ? "tr ee" : "ee"}>
								<LogoSection/>
							</div>
						</div>

						<Flipped flipId="el-alt" stagger>
							<div className="sq sq--center flex--center-y  bg--t3">
								<div className={routeTransitioning ? "tr ee" : "ee"}>
									{/* <div>{path}</div> */}
									{viewingWork ? <ProjectName key={path}/> : <NavWorkButton/>}
									{/* {viewingWork ? <ProjectName/> : <NavWorkButton/>} */}
								</div>
							</div>
						</Flipped>



						<Flipped flipId="el-3" stagger>
							<div className={"sq flex--center-y el-3 border-r bg--t3"}>
								<div className={routeTransitioning ? "tr ee" : "ee"}>
									{viewingWork ? <ExitLink/> : <NavInfoButton/> }
								</div>
							</div>
						</Flipped>



					<style jsx>{`
						.nav {
							position: fixed;
							top: 20px;
							left: 24px;
							border-radius: 25px;
							z-index: 100;
						}

						.border-l{
							border-radius: 20px 0px 0px 20px; 
						}

						.border-r {
							border-radius: 0px 20px 20px 0px; 
						}

						.ee {
							transition: opacity .2s ease;
							transition-delay: .5s;
						}

						.tr {
							opacity: 0;
							transition-delay: .2s;
						}

						.sq {
							margin: 0 1px;
							backdrop-filter: blur(9px);
							height: 42px;
							transform: translate3d(0,0,0);
						}

						.sq--logo {
							width: 46px;
						}

						.sq--center > div {
							min-width: 140px;
						}

						.sq-3 {
							border-radius: 0 25px 25px 0;
						}


						.sq :global(> div ) {
							padding: 12px 8px;
						}

						.el-1 {
							margin-left: 2px;
						}

						.el-3 {
							margin-right: 0px;
						}

						.el-3 :global(> div) {
							// padding-right: 0;
						}
					`}</style>
				</div>
		</Flipper>
	)
});

// <Flipped flipId="el-2" stagger>
// 	<div className="sq flex--center-y bg--t3">
// 		<div className={routeTransitioning ? "tr ee" : "ee"}>
// 			{viewingWork ?  
// 				<WorkSectionTags/>
// 				: 
				
// 			}
// 		</div>
// 	</div>
// </Flipped>


export default NavModular