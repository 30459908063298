import React from 'react'
import {observer} from 'mobx-react-lite'
import { Button } from '../button';
import { useStore } from '../../hooks/useStore';

const Footer = observer((props) => {
    const {isMobile, routerStore: {routeName}} = useStore();

    if (isMobile && routeName === "work.view") {
        return null
    }

    var d = new Date();
    var n = d.getFullYear();

    return (
        <div className="footer flex--space-between">
            <div>
                <h6 className="text-xs copyright plex-mono">© School {n}</h6>
            </div>

            <div className="flex--center-y links">
                <div className="btn-wrap">
                    <a href="https://instagram.com/schoooooooooooool" target="_blank" rel="noreferrer">
                        <Button width={"100%"}>
                            
                            <span className="text-xs plex-mono">@schoooooooooooool</span>

                        </Button>
                    </a>
                </div>

                <div className="btn-wrap">
                    <a href="mailto:info@schoooool.com" target="_blank">
                        <Button width={"100%"}>

                            <span className="text-xs plex-mono">info@schoooool.com</span>

                            
                        </Button>
                    </a>

                </div>
            </div>
            

            <style jsx>{`
                .footer {
                    padding: 15px 24px;
                    position: relative;
                    z-index: 9;
                    background: #f8f8f8;
                }

                .links {
                    width: 400px;
                }

                .btn-wrap {
					width: 50%;
					padding: 0 5px;
					display: inline-block;
                    line-height: 0;
				}
                a {
                    line-height: 0;
                }

                .btn-wrap:last-of-type {
                    padding-left: 0;
                }

                .btn-wrap:last-of-type {
                    padding-right: 0;
                }

                @media(pointer:coarse) {

                    .footer {
                        display: block;
                        padding-bottom: 84px;
                    }

                    .copyright {
                        text-align: center;
                    }

                    .links {
                        margin-top: 20px;
                        width: auto;
                    }
                }



            `}</style>
        </div>
    )
})

export default Footer