import React from 'react'

import {
	Link
} from '../../link'

import {
	useStore
} from '../../../hooks/useStore'

const NavInfoButton = (props) => {
	const {uiStore: {styles}} = useStore();

	return (
		<Link to="about">
			<div className="info-button flex--center">
				<div className="circle"></div>
				<span className="text-xs">i</span>

				<style jsx>{`
					.info-button {
						position: relative;
						width: 26px;
						height: 26px;
						color: ${styles.colors.darkGray};
					}
					
					span {
						position: relative;
						top: -1px;
						transition: color .5s cubic-bezier(0.33, 1, 0.68, 1);
					}

					.circle {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						border: 1px solid ${styles.colors.darkGray};
						background: transparent;
						width: 100%;
						height: 100%;
						border-radius: 50%;
						transition: background .5s cubic-bezier(0.33, 1, 0.68, 1);
					}

					.info-button:hover .circle {
						background: ${styles.colors.lightGray};
					}

					.info-button:hover span {
						color: ${styles.colors.darkTextGray};
					}
				`}</style>
			</div>
		</Link>
	)
}

export default React.memo(NavInfoButton)