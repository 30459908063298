import React from 'react'
import {observer} from 'mobx-react'

import {
	GridItem,
	GridItemTopLabel
} from '../griditem'

import {
	AvatarPic
} from '../../../avatar'

import {
	characterImageUrls
} from '../../../../utils/character-image-urls.js'

import {useStore} from '../../../../hooks/useStore'

const WidgetUserData = (props) => {
	return (
		<GridItem {...props} userData>
			<GridItemTopLabel>(Y)our profile.</GridItemTopLabel>
			<AvatarSection/>
		</GridItem>

	)	
}

const UserNameSection = observer((props) => {
	const {firebaseStore: {userData, updateUserName}, uiStore: {styles}} = useStore();
	if (!userData) return null

	return (
		<div>
			<input className="helvetica text-l" type="text" value={userData.name} onChange={updateUserName} maxLength={20}/>

			<style jsx>{`
				input {
					border: none;
					outline: none; 
					background: transparent;
					text-align: center;
					height: 50px;
					background: #ededed;
					color: ${styles.colors.black};
					border-radius: 25px;
					transition: background .2s ease;
				}

				input:focus {
					background: #ddd;
				}
			`}</style>
		</div>

	)	
})

const AvatarSection = observer((props) => {
	const {firebaseStore: {userData}} = useStore();
	if (!userData) return null;

	return (
		<div className="avatar-section">
			<div className="pic-container flex--center">
				<AvatarPic params={userData.avatar} size={200}/>
			</div>

			<div className="name-container">
				<UserNameSection/>
				<h6 className="instructions helvetica text-s">Input your own name.</h6>
			</div>



			<div className="seq--s2 inputs-wrap">
				<div className="carousel-wrap flex--center-y">
					<AvatarPartsCarousel type="head" prefix="he" srcs={["he1", "he2", "he3", "he4"]}/>
					<AvatarPartsCarousel type="hair" prefix="ha" srcs={["ha1", "ha2", "ha3", "ha4"]}/>
					<AvatarPartsCarousel type="eyes" prefix="e" srcs={["e1", "e2", "e3"]}/>
					<AvatarPartsCarousel type="body" prefix="b" srcs={["b1", "b2"]}/>
				</div>

				<AvatarColorPicker/>
			</div>



			<style jsx>{`
				.pic-container, .name-container {
					margin-bottom: 24px;
				}

				.name-container {
					text-align: center;
				}

				.avatar-section {
					padding: 18px;
				}
				.inputs-wrap {
					padding: 12px;
					background: #ededed;
					border-radius: 25px;
				}
				.carousel-wrap {
					justify-content: space-between;
					margin-bottom: 12px;
				}

				.instructions {
					color: #aaa;
					margin-top: 6px;
				}
			`}</style>
		</div>
	)	
});

const AvatarColorPicker = observer((props) => {
	const {uiStore: {styles}, firebaseStore: {updateUserAvatar, userData: {avatar: {color: userColor}}}} = useStore();

	return (
		<div className="color-picker flex--center-y">

			<div onClick={() => updateUserAvatar("color", 0)} className={`color c0 ${userColor === 0 ? "active" : ""}`}></div>
			<div onClick={() => updateUserAvatar("color", 1)} className={`color c1 ${userColor === 1 ? "active" : ""}`}></div>
			<div onClick={() => updateUserAvatar("color", 2)} className={`color c2 ${userColor === 2 ? "active" : ""}`}></div>
			<div onClick={() => updateUserAvatar("color", 3)} className={`color c3 ${userColor === 3 ? "active" : ""}`}></div>
			<div onClick={() => updateUserAvatar("color", 4)} className={`color c4 ${userColor === 4 ? "active" : ""}`}></div>
			<div onClick={() => updateUserAvatar("color", 5)} className={`color c5 ${userColor === 5 ? "active" : ""}`}></div>
			<div onClick={() => updateUserAvatar("color", 6)} className={`color c6 ${userColor === 6 ? "active" : ""}`}></div>
			<div onClick={() => updateUserAvatar("color", 7)} className={`color c7 ${userColor === 7 ? "active" : ""}`}></div>

			<style jsx>{`
				.color-picker {
					justify-content: space-between;
					// margin: 12px;
					padding: 12px;
					height: 50px;
					border-radius: 25px;
					background: #F9F9F9;
				}

				.color {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					background: gray;
					cursor: pointer;
					border: 1px solid transparent;
					transition: all .2s ease;
					box-shadow: none;
				}

				.active {
					border: 1px solid white;
					box-shadow: 0 1px 4px rgba(0,0,0,0.33);
				}

				.c0 {
					background: ${styles.colors.avatar[0]}
				}

				.c1 {
					background: ${styles.colors.avatar[1]}
				}

				.c2 {
					background: ${styles.colors.avatar[2]}
				}

				.c3 {
					background: ${styles.colors.avatar[3]}
				}

				.c4 {
					background: ${styles.colors.avatar[4]}
				}

				.c5 {
					background: ${styles.colors.avatar[5]}
				}

				.c6 {
					background: ${styles.colors.avatar[6]}
				}

				.c7 {
					background: ${styles.colors.avatar[7]}
				}


			`}</style>
		</div>
	)
})

const AvatarPartsCarousel = observer((props) => {
	const {firebaseStore: {updateUserAvatar, userData}} = useStore()
	const position = userData.avatar[props.prefix] - 1;
	const atLeftLimit = position === 0;
	const atRightLimit = position === props.srcs.length - 1;

	const transform = {
		transform: `translate3d(${-1 * position * 75}px, 0, 0)`
	}

	const updateLeft = () => {
		if (atLeftLimit) return
		const newPos = position - 1;;
		updateUserAvatar(props.prefix, newPos + 1)
	}

	const updateRight = () => {
		if (atRightLimit) return
		const newPos = position + 1;
		// updatePosition(newPos);
		updateUserAvatar(props.prefix, newPos + 1)
	}

	return (
		<div>
			<div className="images">
				<div style={transform} className="images_inner">
					{props.srcs.map((src) => {
						return <img key={`ud-${src}`} className="char-img" src={`${characterImageUrls[src]}?w=150&fm=webp&q=90`} alt=""/>
					})}
				</div>
			</div>
			<div className="controls flex--center-y">
				<ArrowLeft atLeftLimit={atLeftLimit} updateLeft={updateLeft}/>
				<h6>{props.type}</h6>
				<ArrowRight atRightLimit={atRightLimit} updateRight={updateRight}/>
			</div>

			<style jsx>{`	
				.images {
					width: 75px;
					height: 75px;
					border-radius: 50%;
					overflow: hidden;
					background: #FDCEB1;
				}

				.images_inner {
					width: 300px;
					transition: transform .24s ease;
					background: #f9f9f9;
					transition-delay: .06s;
				}

				.controls :global(.arrow) {
					width: 16px;
					position: relative;
					top: -50px;
					cursor: pointer;
				}
				.controls :global(.arrow circle) {
					transition: fill .2s ease;
				}

				.controls :global(.arrow:hover circle) {
					fill: #ddd !important;
				}

				.controls :global(.arrow.disabled) {
					pointer-events: none;
					opacity: 0;
				}

				.controls :global(.arrow-l) {
					left: -7px;
				}

				.controls :global(.arrow-r) {
					right: -7px;
				}

				.char-img {
					width: 75px;
					height: 75px;
				}

				.controls {
					justify-content: space-between;
				}

			`}</style>
		</div>
	)
})

const ArrowLeft = observer((props) => {
	const {atLeftLimit, updateLeft} = props;

	return (
		<svg className={atLeftLimit ? "arrow arrow-l disabled" : "arrow arrow-l"} onClick={updateLeft} width="28px" height="28px" viewBox="0 0 28 28">
		    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <g id="Create-a-proposal---preview-Copy-15" transform="translate(-878.000000, -1353.000000)">
		            <g id="Group-2" transform="translate(878.000000, 1353.000000)">
		                <circle id="Oval-Copy-159" fill="#bbb" cx="14" cy="14" r="14"></circle>
		                <polyline id="Path-73-Copy-38" stroke="#FFFFFF" strokeWidth="2.89731" strokeLinecap="round" strokeLinejoin="round" transform="translate(12.500000, 14.000000) scale(-1, 1) translate(-12.500000, -14.000000) " points="9 8 16 14 9 20"></polyline>
		            </g>
		        </g>
		    </g>
		</svg>
	)

})

const ArrowRight = observer((props) => {
	const {atRightLimit, updateRight} = props;

	return (
		<svg className={atRightLimit ? "arrow arrow-r disabled" : "arrow arrow-r"} onClick={updateRight} width="28px" height="28px" viewBox="0 0 28 28">
		    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <g id="Create-a-proposal---preview-Copy-15" transform="translate(-623.000000, -1353.000000)">
		            <g id="Group-42-Copy-7" transform="translate(623.000000, 1353.000000)">
		                <circle id="Oval-Copy-143" fill="#bbb" cx="14" cy="14" r="14"></circle>
		                <polyline id="Path-73-Copy-32" stroke="#FFFFFF" strokeWidth="2.89731" strokeLinecap="round" strokeLinejoin="round" points="11 8 18 14 11 20"></polyline>
		            </g>
		        </g>
		    </g>
		</svg>
	)
})


export default React.memo(WidgetUserData)