import React from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../../../../hooks/useStore'

import {
	GridItem,
	GridItemTopLabel,
	GridItemBottomLabel
} from '../griditem'

import {
	AvatarPic
} from '../../../avatar'


// const COLUMNS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
const COLS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h']

const ColMap = {
	a: 1,
	b: 2,
	c: 3,
	d: 4,
	e: 5,
	f: 6,
	g: 7,
	h: 8
};

const ROWS = ['1', '2', '3', '4', '5', '6', '7', '8'];

// const COLORS = {
//     BLACK: 'black',
//     WHITE: 'white'
// }


const WidgetChess = observer((props) => {
	const {firebaseStore: { chessBoard, chessboard__updatedBy, chessboard__moveTracker: {from: moveFrom}, chessboard__possibleMoves}} = useStore();

	if (!chessBoard) return null;
	const fields = [];

	// console.log(chess)

	ROWS.forEach((row, i) => {
		COLS.forEach((col, j) => {
			const piece = chessBoard[i][j];
			const location = `${COLS[j]}${ROWS[7 - i]}`
			// console.log(location, piece)
			fields.push(<Field
				location={location}
				piece={piece ? `${piece.color}-${piece.type}` : ''}
				moveFrom={moveFrom === location ? 'moveFrom' : ''}
				moveTo={chessboard__possibleMoves[moveFrom] && chessboard__possibleMoves[moveFrom][location] ? 'moveTo' : ''}
				key={`field-${location}`}
			/>)
		})
	})


	return (
		<GridItem {...props}>
			<GridItemTopLabel>(Y)our chess match.</GridItemTopLabel>
			<div className="board-wrapper">
				<div className="board">
					{fields}
				</div>
				<MessagingPill/>
				<ResetButton/>
				<LastMove/>
			</div>
			{
				chessboard__updatedBy ? 
					<GridItemBottomLabel>
						<ChessMessaging/>
					</GridItemBottomLabel>
				:
					null
			}


			<style jsx>{`
				.board-wrapper {
					width: 100%;
					height: 0;
					padding-bottom: 100%;
					position: relative;
				}

				.board {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					transform: translate3d(0,0,0);
					contain: strict;
				}

				.board-wrapper:hover :global(.messaging) {
					opacity: .2;
				}

				.board-wrapper:hover :global(.messaging.finished) {
					opacity: .8;
				}
			`}</style>
		</GridItem>

	)
})

const LastMove = observer((props) => {
	const {firebaseStore: {chessboard__lastMove}, uiStore: {windowWidth}} = useStore();	

	if (!chessboard__lastMove) return null

	const {from, to} = chessboard__lastMove;
	const size =  windowWidth > 1430 ? (windowWidth - (24 * 4) - (330)) / 2 : (windowWidth - (24 * 4) - (330 + 440)) / 1;
	const inc = size / 8;
	const halfInc = size / 16

	const p1X = (ColMap[from[0]] * inc) - halfInc;
	const p1Y = (size - parseInt(from[1]) * inc) + halfInc;

	const p2X = (ColMap[to[0]] * inc) - halfInc;
	const p2Y = (size - parseInt(to[1]) * inc) + halfInc;

	// const s = (p2Y - p1Y) / (p2X - p1X);
	const d = 25;
	const D = Math.sqrt(Math.pow(p2X - p1X, 2) + Math.pow(p2Y - p1Y, 2));
	// 𝑥3=𝑥1+𝑑𝐷(𝑥2−𝑥1)
	// 𝑦3=𝑦1+𝑑𝐷(𝑦2−𝑦1).

	const p3X = p2X - ((d / D)* (p2X - p1X));
	const p3Y = p2Y - ((d / D)* (p2Y - p1Y));
	// console.log(p2X, p3X)
	// console.log(s)

	// const openFill = ColMap[to[0]] % 2 === 0 ? "#E2CEB8" : "#6A728E";
	// const openFill = val__chessboard.turn==="black" ? "white" : "black";
	// const openFill = "transparent"

	return (
		<svg id="movement" width={size} height={size}>

		    <defs>
		        <marker id="dot" viewBox="0 0 6 6" refX="3" refY="3"
		            markerWidth="3" markerHeight="3">
		          <circle cx="3" cy="3" r="3" fill="#F4625D" />
		        </marker>

		    </defs>


		    <path d={`M${p1X},${p1Y} L${p3X},${p3Y}`}
		    
		    />

		    <style jsx>{`
		    	path {
		    		stroke:#F4625D; stroke-width: 2px; stroke-linecap: round; fill: none;
		    				          		 marker-end: url(#dot);
		    	}
		    `}</style>
		    
		</svg>
	)
});


const ResetButton = observer(() => {
	const {firebaseStore: {chessboard__isFinished, resetChess}} = useStore();	
	if (!chessboard__isFinished) return null
	return (
		<div className="flex--center" onClick={resetChess}>
			<img src="/assets/images/reset.svg" alt=""/>
			<style jsx>{`
				div {
					position: absolute;
					z-index: 100;
					cursor: pointer;
					bottom: 20px;
					right: 20px;
					width: 60px;
					height: 60px;
					border-radius: 50%;
					background: #f9f9f9;
					box-shadow: 0 1px 4px rgb(0 0 0 / 18%);
				}

				img {
					width: 18px;
				}

			`}</style>
		</div>
	)
})

const MessagingPill= observer((props) => {


	const {firebaseStore: {chessboard__isCheckmate, chessboard__isFinished,chessboard__turn}, uiStore: {styles}} = useStore();	

	const content = chessboard__isFinished ? 
					<div className="flex--center-y">
						<h6 className="text-xs">{chessboard__isCheckmate ? "Checkmate": "Draw"}. {chessboard__turn === "b" ? "White wins" : "Black wins"}</h6>
					</div>
					: 
					<h6 className="text-xs">{chessboard__turn === "b" ? "Black is thinking..." : "White to move"}</h6>;

	return (
		<div className={chessboard__isFinished ? "messaging finished flex--center-y" : "messaging flex--center-y"}>
			{content}
			<style jsx>{`
				.messaging {
					position: absolute; 
					top: 12px;
					left: 12px;
					height: 40px;
					padding: 12px 8px;
					border-radius: 25px;
					z-index: 100;
					// background: ${styles.colors.lightGray};
					// backdrop-filter: blur(8px);
					background: white;
					transition: opacity .2s;
					// pointer-events: none;
				}

			`}</style>
		</div>
	)	
})

const ChessMessaging = observer((props) => {
	const {firebaseStore: {chessboard__updatedBy}} = useStore()
	// let msg = null;

	if (!chessboard__updatedBy) return <h6>&nbsp;</h6> 

	return(
		<div>
			<div className="flex--center-y">
				<AvatarPic params={chessboard__updatedBy.avatar} size={30}/>
				<h6>{chessboard__updatedBy.name} made the last move.</h6> 
				<style jsx>{`
					h6 {
						margin-left: 6px;
					}
				`}</style>
			</div>
		</div>
	)
})

const Field = observer((props) => {
	const {
		firebaseStore: {
			handleFieldClick
		}
	} = useStore();

	const {
	    // onClick,
	    location,
	    piece,
	    moveFrom,
	    moveTo
	} = props

	const onClick = () => {
		handleFieldClick(location) 
	}


	return (
	    <div className={`field piece-${piece} ${moveFrom} ${moveTo}`} onClick={onClick}>
	    </div>
	)
})


export default WidgetChess
