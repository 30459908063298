import React from 'react'

import {
	Link
} from '../../link'

import {
	useStore
} from '../../../hooks/useStore'

const NavWorkButton = (props) => {
	const {uiStore: {styles}} = useStore();

	return (
		<Link to="work">
			<div className="view-work flex--center">
				<WorkIcon/>
				<h6 className="plex-mono text-xs">Selected Work</h6>
				<style jsx>{`
					h6 {
						transition: color .5s cubic-bezier(0.33, 1, 0.68, 1);
						transition-delay: .1s;
					}

					.view-work:hover h6{
						color: ${styles.colors.darkTextGray};
					}
					.view-work:hover :global(.work-icon > div){
						background: ${styles.colors.lightGray};
					}
				`}</style>
			</div>
		</Link>
	)
}

const WorkIcon = React.memo((props) => {
	const {uiStore: {styles}} = useStore();

	return (
		<div className="work-icon">
			<div className="m-b"></div>
			<div className="m-b"></div>

			<div></div>
			<div></div>

			<style jsx>{`
				.work-icon {
					margin-right: 4px;
					width: 16px;
					line-height: 0;
				}

				.row {
					margin-bottom: 2px;
				}

				.work-icon > div {
					display: inline-block;
					margin-right: 2px;
					width: 6px;
					height: 6px;
					background: ${styles.colors.darkGray};
					transition: background .5s cubic-bezier(0.33, 1, 0.68, 1);
				}

				.work-icon > div:nth-of-type(1) {

				}

				.work-icon > div:nth-of-type(2) {
					transition-delay: .05s;
				}

				.work-icon > div:nth-of-type(3) {
					transition-delay: .1s;
				}
				.work-icon > div:nth-of-type(4) {
					transition-delay: .15s;
				}


				.m-b {
					margin-bottom: 2px;
				}

			`}</style>
		</div>
	)
})

export default React.memo(NavWorkButton)