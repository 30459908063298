import React from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../../../../hooks/useStore'

import {
	GridItem,
	GridItemTopLabel,
	GridItemBottomLabel
} from '../griditem'

import {
	AvatarPic
} from '../../../avatar'

import {
	Button
} from '../../../button'

const WidgetLastClickedButton = observer((props) => {
	const {firebaseStore: {ready, setLastClicked}} = useStore();

	if (!ready) return null

	return (
		<GridItem {...props}>
			<GridItemTopLabel>(Y)our button</GridItemTopLabel>
			<div>
				<LastClickedVal/>

				<div className="btn-wrap flex--center">
					<Button onClick={setLastClicked}>
						Click
					</Button>
				</div>


				<style jsx>{`
					.btn-wrap {
						padding: 6px 0;
					}
				`}</style>
			</div>	
			<GridItemBottomLabel>
				<LastClickedUser/>
			</GridItemBottomLabel>

		</GridItem>
	)
})

const LastClickedUser = observer((props) => {
	const {firebaseStore: {val__lastClicked}} = useStore();
	if (!val__lastClicked) return null

	return (
		<div className="flex--center-y">
			<AvatarPic size={30} params={val__lastClicked.updatedBy.avatar}/>
			<h6>{val__lastClicked.updatedBy.name} clicked it last.</h6>
			<style jsx>{`
				h6 {
					margin-left: 6px;
				}
			`}</style>
		</div>
	)
})


const LastClickedVal = observer((props) => {
	const {firebaseStore: {lastClickedTime}} = useStore();
	if (!lastClickedTime) return null

	return (
		<React.Fragment>
			<h6 className="text-xl">
				 {lastClickedTime} 
			</h6>

			<style jsx>{`
				h6 {
					contain: content;
					text-align: center;
					font-size: 42px;
					font-weight: 300;
					color: gray;
				}
			`}</style>
		</React.Fragment>

	)
})


export default WidgetLastClickedButton